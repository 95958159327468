<template>
  <div>
    <b-card
      header-tag="header"
      no-body
      class="budget-expense-card mt-2"
    >
      <div class="expense-items vendor__info">
        <b-row
          class="content-header"
        >

          <!-- Content Left -->
          <b-col
            class="content-header-left mb-2"
            cols="12"
            md="6"
          >
            <b-row
              class="content-header"
            >
              <b-col
                class="content-header-left mb-2"
                cols="12"
                md="12"
              >
                <h3 class="text-black">
                  Vendor Information
                </h3>
              </b-col>
              <b-table-simple
                borderless
                responsive
              >
                <b-tbody>
                  <b-tr>
                    <b-th width="230px">
                      Vendor Name
                    </b-th>
                    <b-td>{{ pOrder.vendorName ? pOrder.vendorName : '—' }}</b-td>
                  </b-tr>
                  <b-tr>
                    <b-th width="230px">
                      Address
                    </b-th>
                    <!-- eslint-disable-next-line vue/singleline-html-element-content-newline -->
                    <b-td class="text-pre">{{ pOrder.vendorAddress ? pOrder.vendorAddress : '—' }}</b-td>
                  </b-tr>
                  <b-tr>
                    <b-th width="230px">
                      Payment Terms
                    </b-th>
                    <b-td>{{ pOrder.paymentTerms ? resolvePaymentTerms(pOrder.paymentTerms) : '—' }}</b-td>
                  </b-tr>
                  <b-tr>
                    <b-th width="230px">
                      Delivery Lead Time
                    </b-th>
                    <b-td>{{ pOrder.deliveryLeadTime ? pOrder.deliveryLeadTime : '—' }}</b-td>
                  </b-tr>
                  <b-tr>
                    <b-th width="230px">
                      Vendor Contact Person
                    </b-th>
                    <b-td>{{ pOrder.salesPersonName ? pOrder.salesPersonName : '—' }}</b-td>
                  </b-tr>
                  <b-tr>
                    <b-th width="230px">
                      Vendor Tel
                    </b-th>
                    <b-td>{{ pOrder.companyContact ? pOrder.companyContact : '—' }}</b-td>
                  </b-tr>
                  <b-tr>
                    <b-th width="230px">
                      Vendor H/P
                    </b-th>
                    <b-td>{{ pOrder.companyHP ? pOrder.companyHP : '—' }}</b-td>
                  </b-tr>
                  <b-tr>
                    <b-th width="230px">
                      Vendor Fax
                    </b-th>
                    <b-td>{{ pOrder.companyFax ? pOrder.companyFax : '—' }}</b-td>
                  </b-tr>
                  <b-tr>
                    <b-th width="230px">
                      Vendor Email
                    </b-th>
                    <b-td>{{ pOrder.companyEmail ? pOrder.companyEmail : '—' }}</b-td>
                  </b-tr>
                </b-tbody>
              </b-table-simple>
            </b-row>
          </b-col>
          <b-col
            class="content-header-left mb-2"
            cols="12"
            md="6"
          >
            <b-row
              class="content-header"
            >
              <b-col
                class="content-header-left mb-2"
                cols="12"
                md="8"
              >
                <h3 class="text-black">
                  Delivery Information
                </h3>
              </b-col>
              <b-col
                class="content-header-right text-md-right d-md-block d-none mb-1"
                md="4"
                cols="12"
              >
                <b-button
                  variant="flat-primary"
                  :disabled="purchaseRequest.status == 'cancelled' || purchaseRequest.stage != 'in-order' || !pOrder.status || pOrder.status == 'approved' || !canViewThisAction('update', 'PurchaseOrder')"
                  @click="warningBeforeEdit()"
                >
                  <feather-icon
                    icon="EditIcon"
                    class="mr-50"
                    size="16"
                  />
                  <span class="align-middle">Edit</span>
                </b-button>
              </b-col>

              <b-table-simple
                borderless
                responsive
              >
                <b-tbody>
                  <b-tr>
                    <b-th width="230px">
                      Deliver To
                    </b-th>
                    <b-td>{{ pOrder.deliverTo ? pOrder.deliverTo.name : '—' }}</b-td>
                  </b-tr>
                  <b-tr>
                    <b-th width="230px">
                      Address
                    </b-th>
                    <!-- eslint-disable-next-line vue/singleline-html-element-content-newline -->
                    <b-td class="text-pre">{{ pOrder.address ? pOrder.address : '—' }}</b-td>
                  </b-tr>
                  <b-tr>
                    <b-th width="230px">
                      Delivery Info
                    </b-th>
                    <b-td class="text-pre">{{ pOrder.deliveryInfo ? pOrder.deliveryInfo : '—' }}
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-th width="230px">
                      Main Contact Person
                    </b-th>
                    <b-td>{{ pOrder.mainContactPerson ? pOrder.mainContactPerson : '—' }}</b-td>
                  </b-tr>
                  <b-tr>
                    <b-th width="230px">
                      Main Contact H/P
                    </b-th>
                    <b-td>{{ pOrder.mainContactPersonContact ? pOrder.mainContactPersonContact : '—' }}</b-td>
                  </b-tr>
                  <b-tr>
                    <b-th width="230px">
                      Contact Person #2
                    </b-th>
                    <b-td>{{ pOrder.secondContactPerson ? pOrder.secondContactPerson : '—' }}</b-td>
                  </b-tr>
                  <b-tr>
                    <b-th width="230px">
                      Contact Person #2 H/P
                    </b-th>
                    <b-td>{{ pOrder.backupContact ? pOrder.backupContact : '—' }}</b-td>
                  </b-tr>
                  <b-tr>
                    <b-th width="230px">
                      Fax
                    </b-th>
                    <b-td>{{ pOrder.contactFax ? pOrder.contactFax : '—' }}</b-td>
                  </b-tr>
                  <b-tr>
                    <b-th width="230px">
                      Email
                    </b-th>
                    <b-td>{{ pOrder.contactEmail ? pOrder.contactEmail : '—' }}</b-td>
                  </b-tr>
                </b-tbody>
              </b-table-simple>
            </b-row>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BButton, BCol, VBTooltip, BTableSimple, BTbody, BTr, BTh, BTd,
} from 'bootstrap-vue'
import { useUtils as useAclUtils } from '@/libs/acl/custom'
import Ripple from 'vue-ripple-directive'
import store from '@/store/index'

const { canViewThisAction } = useAclUtils()

export default {
  components: {
    BCard,
    BRow,
    BButton,
    BCol,
    BTableSimple,
    BTbody,
    BTr,
    BTh,
    BTd,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props: {
    purchaseRequest: {
      type: Object,
      required: true,
    },
    pOrder: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      canViewThisAction,
      user: store.state.auth.userData,
      // eslint-disable-next-line global-require
      defaultImage: require('@/assets/images/frontend/logo-nirvana-memorial-garden.86fe2ff5.svg'),
    }
  },
  methods: {
    warningBeforeEdit() {
      this.$swal({
        title: 'Are You Sure?',
        html: 'Editing the form now will <strong>reset the approval process</strong> and require all signatories to confirm and sign once again.',
        // eslint-disable-next-line global-require
        imageUrl: require('@/assets/images/icons/warning.png'),
        reverseButtons: true,
        showCancelButton: true,
        cancelButtonText: 'No, Cancel',
        confirmButtonText: 'Yes, Edit this PO',
        customClass: {
          confirmButton: 'btn btn-primary ml-1',
          cancelButton: 'btn btn-outline-primary',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            this.$router.push({ name: 'purchasing-purchase-order-edit', params: { parent: this.purchaseRequest._id, id: this.pOrder._id } })
          }
        })
    },
    resolvePaymentTerms(val) {
      if (val) {
        let text = ''
        if (val === 'Others') {
          text = this.pOrder.paymentTermsDescription
        } else {
          text = `${val}`
        }

        return text
      }

      return ''
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
.vendor__info {
    .table-responsive:not(.has_padding) .table.b-table th {
        padding: 0 0 0 14px;
    }
    .table-responsive .table.b-table td {
        padding: 0 21px 5px !important;
    }
}
.text-black{
    color: #000;
}
.table th, .table td {
  vertical-align: top;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
