<template>

  <div class="service_form_details no-change">
    <b-row
      class="content-header"
    >

      <!-- Content Left -->
      <b-col
        class="content-header-left mb-2"
        cols="12"
        md="4"
      >
        <b-row class="breadcrumbs-top">
          <b-col cols="12">
            <h1
              v-if="tabIndex == 3"
              class="custom-header-title"
            >
              {{ purchaseRequest.caseID }}
            </h1>
            <h1
              v-else
              class="custom-header-title"
            >
              PR{{ purchaseRequest.stringID }}
            </h1>
            <p class="log_info">
              Created by {{ purchaseRequest.createdBy ? purchaseRequest.createdBy.name : '' }} on {{ dateFormatWithTime(purchaseRequest.createdAt) }}<br>Last updated on {{ dateFormatWithTime(purchaseRequest.updatedAt) }}
            </p>
          </b-col>
        </b-row>
      </b-col>

      <!-- Content Right -->
      <b-col
        class="content-header-right text-md-right d-md-block d-none mb-1"
        md="8"
        cols="12"
      >
        <div class="user_block pr_status_type">
          <div class="user_avatar" />
          <div class="user_info">
            <h6>Status</h6>
            <h5 class="text-capitalize">
              <span v-if="purchaseRequest.status != 'cancelled'">{{ purchaseRequest.status ? purchaseRequest.status.replace("-", " ") : '' }}</span>
              <span
                v-else
                class="text-danger"
              >
                {{ purchaseRequest.status ? purchaseRequest.status.replace("-", " ") : '' }}
              </span>
            </h5>
          </div>
        </div>
        <div class="user_block pr_stage_type">
          <div class="user_avatar" />
          <div class="user_info">
            <h6>Stage</h6>
            <h5 class="text-capitalize">
              {{ resolveStageName(purchaseRequest.stage) }}
            </h5>
          </div>
        </div>
        <div class="user_block">
          <div class="user_avatar">
            <img :src="purchaseRequest.assignedTo ? (purchaseRequest.assignedTo.image ? purchaseRequest.assignedTo.image : defaultImage) : defaultImage">
          </div>
          <div class="user_info">
            <h6>CP Assigned</h6>
            <h5>
              {{ purchaseRequest.assignedTo ? purchaseRequest.assignedTo.name : '-' }}
            </h5>
          </div>
          <div class="action_button">
            <b-button
              v-if="purchaseRequest.status != 'cancelled' && canViewThisAction('assign-cp', 'PurchaseRequest')"
              v-b-modal.modal-update-cp-user
              variant="flat-primary"
            >
              <feather-icon
                icon="EditIcon"
                class="mr-50"
                size="16"
              />
            </b-button>
          </div>
        </div>
      </b-col>
    </b-row>
    <!-- Table Container Card -->

    <b-card
      class="mb-3 p-2 calllog-details purchaseRequest_details"
    >
      <b-row
        class="content-header"
      >

        <!-- Content Left -->
        <b-col
          class="content-header-left mb-2"
          cols="12"
          md="9"
        >
          <h3>Requester Details</h3>
        </b-col>
        <b-col
          class="content-header-right text-md-right d-md-block d-none mb-1"
          md="3"
          cols="12"
        >
          <b-button
            v-if="purchaseRequest.status != 'cancelled' && purchaseRequest.stage == 'in-request' && (canViewThisAction('update', 'PurchaseRequest') || (canViewThisAction('my-request-update', 'MyPurchaseRequest') && purchaseRequest.createdBy._id == user._id) || (canViewThisAction('my-department-request-edit', 'MyPurchaseRequest') && departmentPermission))"
            variant="flat-primary"
            @click="callPREdit()"
          >
            <feather-icon
              icon="EditIcon"
              class="mr-50"
              size="16"
            />
            <span class="align-middle">Edit</span>
          </b-button>

          <b-button
            v-if="purchaseRequest.status != 'cancelled' && purchaseRequest.stage == 'in-quotation' && sQuotation.status && canViewThisAction('update', 'Quotation')"
            variant="flat-primary"
            @click="callQCFEdit()"
          >
            <feather-icon
              icon="EditIcon"
              class="mr-50"
              size="16"
            />
            <span class="align-middle">Edit</span>
          </b-button>

          <b-button
            v-if="purchaseRequest.status != 'cancelled' && purchaseRequest.stage == 'in-order' && sPurchaseOrder.status && sPurchaseOrder.status != 'approved' && canViewThisAction('update', 'PurchaseOrder')"
            variant="flat-primary"
            @click="callPOEdit()"
          >
            <feather-icon
              icon="EditIcon"
              class="mr-50"
              size="16"
            />
            <span class="align-middle">Edit</span>
          </b-button>
        </b-col>
      </b-row>
      <b-table-simple
        borderless
        responsive
      >
        <b-tbody class="top-align">
          <b-tr>
            <b-th width="230px">
              Entity / Company
            </b-th>
            <b-td>{{ purchaseRequest.company ? purchaseRequest.company.name : '' }}</b-td>
            <b-th width="230px">
              Required Delivery Date
            </b-th>
            <b-td>{{ dateFormat(purchaseRequest.deliveryDate) }}</b-td>
          </b-tr>
          <b-tr>
            <b-th width="230px">
              Requester Name
            </b-th>
            <b-td>{{ purchaseRequest.createdBy ? purchaseRequest.createdBy.name : '' }}</b-td>
            <b-th width="230px">
              Deliver To
            </b-th>
            <b-td>{{ purchaseRequest.deliverTo ? purchaseRequest.deliverTo.name : '' }}</b-td>
          </b-tr>
          <b-tr>
            <b-th width="230px">
              Requester Department
            </b-th>
            <b-td>{{ purchaseRequest.department ? purchaseRequest.department.name : '' }}</b-td>
            <b-th width="230px">
              Address
            </b-th>
            <b-td class="text-pre">{{ purchaseRequest.address }}
            </b-td>
          </b-tr>
          <b-tr>
            <b-th width="230px">
              Justification / Purpose
            </b-th>
            <b-td>{{ purchaseRequest.purpose }}</b-td>
            <b-th width="230px">
              Additional Remarks
            </b-th>
            <b-td class="text-pre">{{ purchaseRequest.remarks }}
            </b-td>
          </b-tr>
          <b-tr>
            <b-th width="230px">
              Type of Request
            </b-th>
            <b-td>{{ purchaseRequest.requestType }}</b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </b-card>
    <b-card
      class="mb-3 p-2 without__bg extra__bg"
      no-body
    >
      <b-row
        class="content-header"
      >

        <!-- Content Left -->
        <b-col
          v-if="purchaseRequest.stage"
          class="content-header-left booking_content_wrapper mb-2"
          cols="12"
          md="12"
        >
          <b-dropdown
            v-if="isMobile()"
            :text="currentTabTitle"
            variant="outline-primary"
          >
            <b-dropdown-item
              @click="changeTab(0)"
            >
              Purchase Request
            </b-dropdown-item>
            <b-dropdown-item
              :disabled="purchaseRequest.stage == 'in-request'"
              @click="changeTab(1)"
            >
              Quotation
            </b-dropdown-item>
            <b-dropdown-item
              :disabled="purchaseRequest.stage == 'in-request' || purchaseRequest.stage == 'in-quotation'"
              @click="changeTab(2)"
            >
              Purchase Order
            </b-dropdown-item>
            <b-dropdown-item
              :disabled="purchaseRequest.stage == 'in-request' || purchaseRequest.stage == 'in-quotation' || purchaseRequest.stage == 'in-order'"
              @click="changeTab(3)"
            >
              Invoices
            </b-dropdown-item>
            <b-dropdown-item
              :disabled="purchaseRequest.stage == 'in-request' || purchaseRequest.stage == 'in-quotation' || purchaseRequest.stage == 'in-order'"
              @click="changeTab(4)"
            >
              Delivery
            </b-dropdown-item>
          </b-dropdown>
          <b-tabs
            v-model="tabIndex"
            pills
            class="booking__content"
            :nav-class="[isMobile() ? 'd-none' : '']"
          >
            <b-tab
              :active="purchaseRequest.stage == 'in-request'"
              lazy
            >
              <template #title>
                <span class="d-none d-sm-inline">Purchase Request</span>
              </template>
              <purchase-request-detail
                ref="prfComponent"
                class="mt-2 pt-75"
                :purchase-request.sync="purchaseRequest"
                :purchase-request-form.sync="purchaseRequestForm"
                :pr-activity-logs.sync="prActivityLogs"
              />
            </b-tab>
            <b-tab
              :active="purchaseRequest.stage == 'in-quotation'"
              :disabled="purchaseRequest.stage == 'in-request'"
              lazy
            >
              <template #title>
                <span class="d-none d-sm-inline">Quotation</span>
              </template>
              <quotation-detail
                ref="qcfComponent"
                class="mt-2 pt-75"
                :purchase-request.sync="purchaseRequest"
                :s-quotation.sync="sQuotation"
                :pr-activity-logs.sync="prActivityLogs"
              />
            </b-tab>
            <b-tab
              :active="purchaseRequest.stage == 'in-order'"
              :disabled="purchaseRequest.stage == 'in-request' || purchaseRequest.stage == 'in-quotation'"
              lazy
            >
              <template #title>
                <span class="d-none d-sm-inline">Purchase Order</span>
              </template>
              <purchase-order-detail
                ref="poComponent"
                class="mt-2 pt-75"
                :purchase-request.sync="purchaseRequest"
                :s-purchase-order.sync="sPurchaseOrder"
                :pr-activity-logs.sync="prActivityLogs"
              />
            </b-tab>
            <b-tab
              :active="purchaseRequest.stage == 'in-delivery' && $route.query.tab && $route.query.tab == 'invoice'"
              :disabled="purchaseRequest.stage == 'in-request' || purchaseRequest.stage == 'in-quotation' || purchaseRequest.stage == 'in-order'"
              lazy
            >
              <template #title>
                <span class="d-none d-sm-inline">Invoices</span>
              </template>
              <invoice-detail
                ref="invoiceComponent"
                class="mt-2 pt-75"
                :purchase-request.sync="purchaseRequest"
                :pr-activity-logs.sync="prActivityLogs"
              />
            </b-tab>
            <b-tab
              :active="purchaseRequest.stage == 'in-delivery' && !$route.query.tab"
              :disabled="purchaseRequest.stage == 'in-request' || purchaseRequest.stage == 'in-quotation' || purchaseRequest.stage == 'in-order'"
              lazy
            >
              <template #title>
                <span class="d-none d-sm-inline">Delivery</span>
              </template>
              <delivery-detail
                ref="deliveryComponent"
                class="mt-2 pt-75"
                :purchase-request.sync="purchaseRequest"
                :pr-activity-logs.sync="prActivityLogs"
              />
            </b-tab>
            <template #tabs-end>
              <b-dropdown
                v-if="purchaseRequest.status != 'cancelled' && (canViewThisAction('cancel', 'PurchaseRequest') || user._id == (purchaseRequest.createdBy ? purchaseRequest.createdBy._id : ''))"
                variant="outline-primary"
                no-caret
                class="text-right ml-auto vendor-detail-more-icon"
                :right="$store.state.appConfig.isRTL"
              >

                <template #button-content>
                  <feather-icon
                    icon="MoreHorizontalIcon"
                    size="16"
                    class="align-middle text-body"
                  />
                </template>

                <b-dropdown-item
                  v-if="purchaseRequest.stage == 'in-delivery' && !purchaseRequest.isCancellable"
                  v-b-tooltip.hover.top="purchaseRequest.notCancellableReason == 'delivered-product' ? 'Purchase Request cannot be cancelled. Please mark all items as pending delivery before proceeding.' : 'Purchase Request cannot be cancelled. Please void all related invoices before proceeding.'"
                  disabled
                >
                  <span class="align-middle">Cancel Purchase Request</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-else
                  @click="showCancelWarning"
                >
                  <span class="align-middle">Cancel Purchase Request</span>
                </b-dropdown-item>
              </b-dropdown>
            </template>
          </b-tabs>
        </b-col>
      </b-row>
    </b-card>

    <b-card
      class="mb-3 mt-3 p-2"
    >
      <b-row
        class="content-header"
      >
        <b-col
          class="content-header-left mb-2 p-2"
          cols="12"
          md="12"
        >
          <h4>
            <feather-icon
              icon="ListIcon"
              class="mr-50"
              size="16"
            />
            <span class="align-middle">Comments</span>
          </h4>
        </b-col>
      </b-row>
      <app-timeline>
        <app-timeline-item
          v-for="(opt, key) in prComments"
          :key="key"
          variant="success"
        >
          <div class="d-flex justify-content-between flex-sm-row flex-column mb-sm-0 mb-1">
            <h6 style="max-width: 100%;">
              {{ opt.createdBy.name }}
            </h6>
            <small class="timeline-item-time text-nowrap text-muted">{{ dateFormatWithTime(opt.createdAt) }}</small>
          </div>
          <p>{{ opt.comment }}</p>
        </app-timeline-item>
      </app-timeline>
      <b-row
        class="content-header"
      >
        <b-col
          class="content-header-left p-1"
          cols="12"
          md="12"
        >
          <div class="d-flex">
            <b-avatar
              v-if="user.image"
              :src="user.image"
              class="mr-1"
            />
            <b-avatar
              v-else
              :text="resolveAcronymName(user.name)"
              class="mr-1"
            />
            <!-- input -->
            <b-form-group
              class="mb-0 mr-1"
              style="width: calc(100% - 215px) !important;"
            >
              <b-form-input
                v-model="extraComment"
                placeholder="Type to leave a comment. Comments are visible to all users who have access to this Purchase Request."
                style="border-top: none; border-right: none; border-left: none;"
              />
            </b-form-group>

            <!-- button -->
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="primary"
              :disabled="extraComment == ''"
              @click="addComment()"
            >
              Add Comment
            </b-button>
          </div>
        </b-col>
      </b-row>
    </b-card>
    <b-card
      class="mb-3 mt-3 p-2"
    >
      <b-row
        class="content-header"
      >
        <!-- Content Left -->
        <b-col
          class="content-header-left mb-2 p-2"
          cols="12"
          md="12"
        >
          <h4>
            <feather-icon
              icon="ListIcon"
              class="mr-50"
              size="16"
            />
            <span class="align-middle">Activity Logs</span>
          </h4>
        </b-col>
      </b-row>
      <app-timeline>
        <!-- FINANCIAL REPORT -->
        <app-timeline-item
          v-for="(opt, key) in prActivityLogs"
          :key="key"
          class="multiple-color"
          :variant="getVariant(key)"
        >

          <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
            <h6>{{ opt.title }}</h6>
            <small class="text-muted">{{ dateFormatWithTime(opt.time) }}</small>
          </div>
          <b-button
            v-if="opt.activityDetails && opt.activityDetails.length"
            v-b-toggle="'report-list-with-icon-' + key"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            size="sm"
            variant="outline-primary"
          >
            Details
            <feather-icon
              icon="ChevronUpIcon"
              class="ml-50 when-open"
              size="16"
            />
            <feather-icon
              icon="ChevronDownIcon"
              class="ml-50 when-closed"
              size="16"
            />
          </b-button>
          <b-collapse
            v-if="opt.activityDetails && opt.activityDetails.length"
            :id="'report-list-with-icon-' + key"
          >
            <b-table-simple
              borderless
              responsive
            >
              <b-tbody>
                <b-tr
                  v-for="(activityDetail, activityKey) in opt.activityDetails"
                  :key="activityKey"
                >
                  <b-th v-if="activityDetail.title">
                    {{ activityDetail.title }}
                  </b-th>
                  <b-td>
                    <div v-if="activityDetail.items">
                      <b-table-lite
                        responsive
                        :items="activityDetail.oldValue"
                        :fields="['no', { key: 'itemsMaster', label: 'description' }, { key: 'quantity', label: 'QTY' },
                                  { key: 'unit', label: 'Unit' }, { key: 'totalCost', label: 'Est. total cost (S$)' }]"
                      >
                        <template #cell(no)="data">
                          {{ data.index + 1 }}
                        </template>
                        <template #cell(itemsMaster)="data">
                          <b-card-text class="font-weight-bold mb-25">
                            {{ data.item.itemsMaster }}
                          </b-card-text>
                          <b-card-text class="text-nowrap">
                            {{ data.item.description }}
                          </b-card-text>
                        </template>
                        <template #cell(totalCost)="data">
                          {{ numberFormat(data.item.totalCost) }}
                        </template>
                      </b-table-lite>
                    </div>
                    <div v-else-if="activityDetail.signatories">
                      <b-table-lite
                        responsive
                        :items="activityDetail.oldValue"
                        :fields="['no', { key: 'type', label: 'Signatory Type' }, { key: 'name', label: 'User' }]"
                      >
                        <template #cell(no)="data">
                          {{ data.index + 1 }}
                        </template>
                      </b-table-lite>
                    </div>
                    <div v-else-if="activityDetail.vendor">
                      <b-table-lite
                        responsive
                        :items="activityDetail.oldValue"
                        :fields="['no', { key: 'name', label: 'Vendor' }, { key: 'code', label: 'GST' }, { key: 'gstPercentage', label: 'Percentage' }, { key: 'paymentTerms', label: 'Payment Terms' }]"
                      >
                        <template #cell(no)="data">
                          {{ data.index + 1 }}
                        </template>
                      </b-table-lite>
                    </div>
                    <div v-else-if="activityDetail.quotationItems">
                      <b-table-lite
                        responsive
                        :items="activityDetail.oldValue"
                        :fields="['no', { key: 'itemName', label: 'Item' }, { key: 'itemDescription', label: 'Description' }
                                  , { key: 'unit', label: 'Unit' }, { key: 'quantity', label: 'Quantity' }
                                  , { key: 'unitCost', label: 'Unit Cost' }, { key: 'gst', label: 'GST' }]"
                      >
                        <template #cell(no)="data">
                          {{ data.index + 1 }}
                        </template>
                        <template #cell(unitCost)="data">
                          <span
                            v-for="(cost, costKey) in data.item.unitCost"
                            :key="costKey"
                          >
                            {{ numberFormat(cost) }}
                          </span>
                        </template>
                        <template #cell(gst)="data">
                          <span
                            v-for="(item, itemKey) in data.item.gst"
                            :key="itemKey"
                            class="text-capitalize"
                          >
                            {{ item }}
                          </span>
                        </template>
                      </b-table-lite>
                    </div>
                    <div v-else-if="activityDetail.budgetItems">
                      <div v-if="activityDetail.new">
                        <div
                          v-for="(item, costKey) in activityDetail.newValue"
                          :key="costKey"
                        >
                          <div>
                            <span
                              class="text-bold-black"
                              style="display: inline-block; width: 230px;"
                            >
                              Budgetary Period
                            </span>
                            <span>{{ item.budgetPeriod }}</span>
                          </div>
                          <div>
                            <span
                              class="text-bold-black"
                              style="display: inline-block; width: 230px;"
                            >
                              Expense Type
                            </span>
                            <span>{{ item.expenseType }}</span>
                          </div>
                          <div>
                            <span
                              class="text-bold-black"
                              style="display: inline-block; width: 230px;"
                            >
                              Cost Centre
                            </span>
                            <span>{{ item.costCenter }}</span>
                          </div>
                          <div
                            v-if="canViewThisAction('see-balance', 'BudgetOverview')"
                            class="d-flex justify-content"
                          >
                            <span
                              class="text-bold-black"
                              style="display: inline-block; width: 230px;"
                            >
                              <span>
                                Sub-budget
                              </span>
                              <br>
                              <span>(Expense Category)</span>
                            </span>
                            <span>{{ item.subBudget }}</span>
                          </div>
                          <div>
                            <span
                              class="text-bold-black"
                              style="display: inline-block; width: 230px;"
                            >
                              Allocated Amount</span>
                            <span>S$ {{ numberFormat(item.allocatedAmount) }}</span>
                          </div>
                          <hr v-if="costKey + 1 != activityDetail.newValue.length">
                        </div>
                        <!-- <b-table-simple
                          borderless
                          responsive
                        >
                          <b-tbody
                            v-for="(item, costKey) in activityDetail.newValue"
                            :key="costKey"
                          >
                            <b-tr>
                              <b-th width="230px">
                                Budgetary Period
                              </b-th>
                              <b-td>{{ item.budgetPeriod }}</b-td>
                            </b-tr>
                            <b-tr>
                              <b-th width="230px">
                                Expense Type
                              </b-th>
                              <b-td>{{ item.expenseType }}</b-td>
                            </b-tr>
                            <b-tr>
                              <b-th width="230px">
                                Cost Centre
                              </b-th>
                              <b-td>{{ item.costCenter }}</b-td>
                            </b-tr>
                            <b-tr>
                              <b-th width="230px">
                                <span>Sub-budget</span>
                                <br>
                                <span>(Expense Category)</span>
                              </b-th>
                              <b-td>{{ item.subBudget }}</b-td>
                            </b-tr>
                            <b-tr>
                              <b-th width="230px">
                                Allocated Amount
                              </b-th>
                              <b-td>S$ {{ numberFormat(item.allocatedAmount) }}</b-td>
                            </b-tr>
                            <b-tr v-if="costKey + 1 != activityDetail.newValue.length">
                              <b-td>
                                <hr>
                              </b-td>
                              <b-td>
                                <hr>
                              </b-td>
                            </b-tr>
                          </b-tbody>
                        </b-table-simple> -->
                      </div>
                      <div v-else>
                        <div
                          v-for="(item, costKey) in activityDetail.newValue"
                          :key="costKey"
                        >
                          <div v-if="item.state == 'new'">
                            <div>
                              <span
                                class="text-bold-black"
                                style="display: inline-block; width: 230px;"
                              >
                                Budgetary Period
                              </span>
                              <span>{{ item.budgetPeriod }}</span>
                            </div>
                            <div>
                              <span
                                class="text-bold-black"
                                style="display: inline-block; width: 230px;"
                              >
                                Expense Type
                              </span>
                              <span>{{ item.expenseType }}</span>
                            </div>
                            <div>
                              <span
                                class="text-bold-black"
                                style="display: inline-block; width: 230px;"
                              >
                                Cost Centre
                              </span>
                              <span>{{ item.costCenter }}</span>
                            </div>
                            <div
                              v-if="canViewThisAction('see-balance', 'BudgetOverview')"
                              class="d-flex justify-content"
                            >
                              <span
                                class="text-bold-black"
                                style="display: inline-block; width: 230px;"
                              >
                                <span>
                                  Sub-budget
                                </span>
                                <br>
                                <span>(Expense Category)</span>
                              </span>
                              <span>{{ item.subBudget }}</span>
                            </div>
                            <div>
                              <span
                                class="text-bold-black"
                                style="display: inline-block; width: 230px;"
                              >
                                Allocated Amount</span>
                              <span>S$ {{ numberFormat(item.allocatedAmount) }}</span>
                            </div>
                          </div>
                          <div v-if="item.state == 'deleted'">
                            <div>
                              <span
                                class="text-bold-black"
                                style="display: inline-block; width: 230px;"
                              >
                                Budgetary Period
                              </span>
                              <span style="text-decoration: line-through;">{{ item.oldBudgetPeriod }}</span>
                            </div>
                            <div>
                              <span
                                class="text-bold-black"
                                style="display: inline-block; width: 230px;"
                              >
                                Expense Type
                              </span>
                              <span style="text-decoration: line-through;">{{ item.oldExpenseType }}</span>
                            </div>
                            <div>
                              <span
                                class="text-bold-black"
                                style="display: inline-block; width: 230px;"
                              >
                                Cost Centre
                              </span>
                              <span style="text-decoration: line-through;">{{ item.oldCostCenter }}</span>
                            </div>
                            <div
                              v-if="canViewThisAction('see-balance', 'BudgetOverview')"
                              class="d-flex justify-content"
                            >
                              <span
                                class="text-bold-black"
                                style="display: inline-block; width: 230px;"
                              >
                                <span>
                                  Sub-budget
                                </span>
                                <br>
                                <span>(Expense Category)</span>
                              </span>
                              <span style="text-decoration: line-through;">{{ item.oldSubBudget }}</span>
                            </div>
                            <div>
                              <span
                                class="text-bold-black"
                                style="display: inline-block; width: 230px;"
                              >
                                Allocated Amount</span>
                              <span style="text-decoration: line-through;">S$ {{ numberFormat(item.oldAllocatedAmount) }}</span>
                            </div>
                          </div>
                          <div v-if="item.state == 'edited'">
                            <div class="d-flex justify-content">
                              <div style="width: 230px;">
                                <p
                                  class="mb-0 text-bold-black"
                                >
                                  Budgetary Period
                                </p>
                                <p
                                  class="mb-0 text-bold-black"
                                >
                                  Expense Type
                                </p>
                                <p
                                  class="mb-0 text-bold-black"
                                >
                                  Cost Centre
                                </p>
                                <p
                                  v-if="canViewThisAction('see-balance', 'BudgetOverview')"
                                  class="mb-0 text-bold-black"
                                >
                                  Sub-budget
                                </p>
                                <p
                                  v-if="canViewThisAction('see-balance', 'BudgetOverview')"
                                  class="mb-0 text-bold-black"
                                >
                                  (Expense Category)
                                </p>
                                <p
                                  class="mb-0 text-bold-black"
                                >
                                  Allocated Amount
                                </p>
                              </div>
                              <div style="width: 400px;">
                                <p
                                  v-if="item.oldBudgetPeriod"
                                  style="text-decoration: line-through;"
                                  class="mb-0"
                                >
                                  {{ item.oldBudgetPeriod }}
                                </p>
                                <p
                                  v-else
                                  class="mb-0"
                                >
                                  {{ item.budgetPeriod }}
                                </p>
                                <p
                                  v-if="item.oldExpenseType"
                                  style="text-decoration: line-through;"
                                  class="mb-0"
                                >
                                  {{ item.oldExpenseType }}
                                </p>
                                <p
                                  v-else
                                  class="mb-0"
                                >
                                  {{ item.expenseType }}
                                </p>
                                <p
                                  v-if="item.oldCostCenter"
                                  style="text-decoration: line-through;"
                                  class="mb-0"
                                >
                                  {{ item.oldCostCenter }}
                                </p>
                                <p
                                  v-else
                                  class="mb-0"
                                >
                                  {{ item.costCenter }}
                                </p>
                                <div v-if="canViewThisAction('see-balance', 'BudgetOverview')">
                                  <p
                                    v-if="item.oldSubBudget"
                                    style="text-decoration: line-through;"
                                    class="mb-0"
                                  >
                                    {{ item.oldSubBudget }}
                                  </p>
                                  <p
                                    v-else
                                    class="mb-0"
                                  >
                                    {{ item.subBudget }}
                                  </p>
                                  <br>
                                </div>
                                <p
                                  v-if="item.oldAllocatedAmount"
                                  style="text-decoration: line-through;"
                                  class="mb-0"
                                >
                                  S$ {{ numberFormat(item.oldAllocatedAmount) }}
                                </p>
                                <p
                                  v-else
                                  class="mb-0"
                                >
                                  S$ {{ numberFormat(item.allocatedAmount) }}
                                </p>
                              </div>
                              <div class="pr-3">
                                <feather-icon
                                  icon="ArrowRightIcon"
                                  class=""
                                  size="16"
                                />
                              </div>
                              <div>
                                <p class="mb-0">
                                  {{ item.budgetPeriod }}
                                </p>
                                <p class="mb-0">
                                  {{ item.expenseType }}
                                </p>
                                <p class="mb-0">
                                  {{ item.costCenter }}
                                </p>
                                <p
                                  v-if="canViewThisAction('see-balance', 'BudgetOverview')"
                                  class="mb-0"
                                >
                                  {{ item.subBudget }}
                                </p>
                                <br v-if="canViewThisAction('see-balance', 'BudgetOverview')">
                                <p class="mb-0">
                                  S$ {{ numberFormat(item.allocatedAmount) }}
                                </p>
                              </div>
                            </div>
                          </div>
                          <hr v-if="costKey + 1 != activityDetail.newValue.length">
                        </div>
                      </div>
                    </div>
                    <div v-else>
                      {{ activityDetail.oldValue }}
                    </div>
                  </b-td>
                  <b-td v-if="!activityDetail.budgetItems">
                    <div v-if="activityDetail.items">
                      <b-table-lite
                        responsive
                        :items="activityDetail.newValue"
                        :fields="['no', { key: 'itemsMaster', label: 'description' }, { key: 'quantity', label: 'QTY' },
                                  { key: 'unit', label: 'Unit' }, { key: 'totalCost', label: 'Est. total cost (S$)' }]"
                      >
                        <template #cell(no)="data">
                          {{ data.index + 1 }}
                        </template>
                        <template #cell(itemsMaster)="data">
                          <b-card-text class="font-weight-bold mb-25">
                            {{ data.item.itemsMaster }}
                          </b-card-text>
                          <b-card-text>
                            {{ data.item.description }}
                          </b-card-text>
                        </template>
                        <template #cell(totalCost)="data">
                          {{ numberFormat(data.item.totalCost) }}
                        </template>
                      </b-table-lite>
                    </div>
                    <div v-else-if="activityDetail.signatories">
                      <b-table-lite
                        responsive
                        :items="activityDetail.newValue"
                        :fields="['no', { key: 'type', label: 'Signatory Type' }, { key: 'name', label: 'User' }]"
                      >
                        <template #cell(no)="data">
                          {{ data.index + 1 }}
                        </template>
                      </b-table-lite>
                    </div>
                    <div v-else-if="activityDetail.vendor">
                      <b-table-lite
                        responsive
                        :items="activityDetail.newValue"
                        :fields="['no', { key: 'name', label: 'Vendor' }, { key: 'code', label: 'GST' }, { key: 'gstPercentage', label: 'Percentage' }, { key: 'paymentTerms', label: 'Payment Terms' }]"
                      >
                        <template #cell(no)="data">
                          {{ data.index + 1 }}
                        </template>
                      </b-table-lite>
                    </div>
                    <div v-else-if="activityDetail.quotationItems">
                      <b-table-lite
                        responsive
                        :items="activityDetail.newValue"
                        :fields="['no', { key: 'itemName', label: 'Item' }, { key: 'itemDescription', label: 'Description' }
                                  , { key: 'unit', label: 'Unit' }, { key: 'quantity', label: 'Quantity' }
                                  , { key: 'unitCost', label: 'Unit Cost' }, { key: 'gst', label: 'GST' }]"
                      >
                        <template #cell(no)="data">
                          {{ data.index + 1 }}
                        </template>
                        <template #cell(unitCost)="data">
                          <span
                            v-for="(cost, costKey) in data.item.unitCost"
                            :key="costKey"
                          >
                            {{ numberFormat(cost) }}
                          </span>
                        </template>
                        <template #cell(gst)="data">
                          <span
                            v-for="(item, itemKey) in data.item.gst"
                            :key="itemKey"
                            class="text-capitalize"
                          >
                            {{ item }}
                          </span>
                        </template>
                      </b-table-lite>
                    </div>
                    <div v-else>
                      {{ activityDetail.newValue }}
                    </div>
                  </b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
          </b-collapse>
        </app-timeline-item>
      </app-timeline>
    </b-card>
    <b-modal
      id="modal-update-cp-user"
      ref="update-cp-user-modal"
      cancel-variant="flat-primary"
      modal-class="status-update-modal"
      ok-variant="success"
      ok-title="Update"
      cancel-title="Cancel"
      centered
      no-close-on-backdrop
      title="Update CP User"
      @ok="cpUserSubmitForm"
    >
      <b-form @submit.prevent="cpUserSubmitForm">
        <validation-observer
          ref="cpUserEditForm"
        >
          <b-form-group
            label="User*"
            label-for="h-purchaseRequest-cpUser"
          >
            <validation-provider
              #default="{ errors }"
              name="User"
              vid="status"
              rules="required"
            >
              <v-select
                id="h-purchaseRequest-cpUser"
                v-model="cpUser"
                label="name"
                :options="cpUserOptions"
                :reduce="name => name._id"
                :clearable="false"
                placeholder="Select an option"
                @input="cpUserValidation == true ? cpUserValidation = false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
              <small
                v-if="cpUserValidation"
                class="text-danger"
              >
                {{ cpUserError }}
              </small>
            </validation-provider>
          </b-form-group>
        </validation-observer>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard, BRow, BButton, BCol, BTableSimple, BTbody, BTr, BTh, BTd, BTab, BTabs, BForm, BFormGroup, BDropdown, BDropdownItem, VBToggle,
  VBTooltip, BCollapse, BTableLite, BCardText, BFormInput, BAvatar,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { required } from '@validations'
import Ripple from 'vue-ripple-directive'
import { useUtils as useAclUtils } from '@/libs/acl/custom'
import store from '@/store/index'

import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import PurchaseRequestDetail from './purchase-request/PurchaseRequestDetail.vue'
import QuotationDetail from '../quotations/QuotationDetail.vue'
import PurchaseOrderDetail from '../purchase-order/PurchaseOrderDetail.vue'
import DeliveryDetail from '../delivery/DeliveryDetail.vue'
import InvoiceDetail from '../vendor-payment/InvoiceDetail.vue'
import purchaseRequestStoreModule from './purchaseRequestStoreModule'

const { canViewThisAction } = useAclUtils()

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BRow,
    BButton,
    BCol,
    BTableSimple,
    BTbody,
    BTr,
    BTh,
    BTd,
    BForm,
    BFormGroup,
    BDropdown,
    BDropdownItem,
    BCollapse,
    BTableLite,
    BCardText,
    BFormInput,
    BAvatar,

    vSelect,
    ValidationProvider,
    ValidationObserver,

    PurchaseRequestDetail,
    QuotationDetail,
    PurchaseOrderDetail,
    DeliveryDetail,
    InvoiceDetail,

    AppTimeline,
    AppTimelineItem,
  },
  directives: { 'b-toggle': VBToggle, 'b-tooltip': VBTooltip, Ripple },
  data() {
    return {
      canViewThisAction,
      currentTabTitle: 'Purchase Request',
      pageTabs: [
        {
          title: 'Purchase Request',
        },
        {
          title: 'Quotation',
        },
        {
          title: 'Purchase Order',
        },
        {
          title: 'Invoices',
        },
        {
          title: 'Delivery',
        },
      ],
      tabIndex: 0,
      purchaseRequest: {},
      purchaseRequestForm: {},
      sQuotation: {},
      sPurchaseOrder: {},
      user: store.state.auth.userData,
      tabType: 'purchaserequest',
      purchaseRequestSignatories: [],
      purchaseRequestItems: [],
      budgetExpense: {},
      expenseItems: [],
      purchaseRequestID: '',
      stringID: '',
      cpUser: '',
      cpUserValidation: false,
      cpUserError: '',
      status: 'confirmed',
      statusValidation: false,
      statusError: 'Valid status is required',
      statusDataOptions: [
        { title: 'Draft', code: 'draft' },
        { title: 'Pending Edit', code: 'pending edit' },
        { title: 'Pending Approval', code: 'pending approval' },
        { title: 'Verifying', code: 'verifying' },
        { title: 'Verified', code: 'verified' },
        { title: 'Cancelled', code: 'cancelled' },
      ],
      prActivityLogs: [],
      prComments: [],
      extraComment: '',
      description: '',
      attachmentKey: '',
      departmentPermission: false,
      required,
      cpUserOptions: [],
      lqas: [
        '1. Prepared by',
        '2. Requested by',
        '3. Verified by',
        '4. Recommended by',
        '5. Agreed by',
        '6. Certified by',
        '7. Confirmed by',
        '8. Supported by',
        '9. Validated by',
        '10. Approved by',
      ],
      // eslint-disable-next-line global-require
      defaultImage: require('@/assets/images/frontend/logo-nirvana-memorial-garden.86fe2ff5.svg'),
    }
  },
  async beforeRouteEnter(to, from, next) {
    if (to.query.from && to.query.from === 'my-pr') {
      // eslint-disable-next-line no-param-reassign
      to.meta.navActiveLink = 'purchasing-my-purchase-requests-index'
      await store.commit('breadcrumb/REMOVE_BREADCRUMB')
      await store.commit('breadcrumb/REMOVE_BREADCRUMB')
      const breadcrumb = [
        {
          text: 'My Purchase Requests',
          to: '/purchasing/my-purchase-requests/all-requests',
        },
        {
          text: 'Purchase Request Detail',
          active: true,
        },
      ]
      store.commit('breadcrumb/ADD_BREADCRUMB', breadcrumb)
    } else {
      // eslint-disable-next-line no-param-reassign
      to.meta.navActiveLink = 'purchasing-purchase-requests-index'
      await store.commit('breadcrumb/REMOVE_BREADCRUMB')
      await store.commit('breadcrumb/REMOVE_BREADCRUMB')
      const breadcrumb = [
        {
          text: 'Purchase Requests',
          to: '/purchasing/purchase-requests/all-requests',
        },
        {
          text: 'Purchase Request Detail',
          active: true,
        },
      ]
      store.commit('breadcrumb/ADD_BREADCRUMB', breadcrumb)
    }
    next()
  },
  mounted() {
    if (!store.hasModule('pr-detail-store')) store.registerModule('pr-detail-store', purchaseRequestStoreModule)
    this.$http.get(`purchase/purchase-request/${this.$route.params.id}/show`)
      .then(response => {
        this.purchaseRequest = response.data.data || {}
        if (this.purchaseRequest.stage === 'in-request') {
          this.currentTabTitle = 'Purchase Request'
        } else if (this.purchaseRequest.stage === 'in-quotation') {
          this.currentTabTitle = 'Quotation'
        } else if (this.purchaseRequest.stage === 'in-order') {
          this.currentTabTitle = 'Purchase Order'
        } else if (this.purchaseRequest.stage === 'in-delivery') {
          if (this.$route.query.tab && this.$route.query.tab === 'invoice') {
            this.tabIndex = 3
            this.currentTabTitle = 'Invoices'
          } else {
            this.currentTabTitle = 'Delivery'
          }
        }

        const userDepartmentArray = this.user.department.map(({ _id }) => _id)
        const purchaserDepartmentArray = this.purchaseRequest.createdBy.department.filter(d => userDepartmentArray.includes(d))
        if (purchaserDepartmentArray.length) {
          this.departmentPermission = true
        }
        // if (!this.canViewThisAction('show', 'PurchaseRequest') || (this.canViewThisAction('my-request-show', 'MyPurchaseRequest') && this.purchaseRequest.createdBy._id !== this.user._id) || (this.canViewThisAction('my-department-request-view', 'MyPurchaseRequest') && this.purchaseRequest.company._id !== (this.user.department._id ? this.user.department._id : 0))) {
        //   this.$router.push({ name: 'not-authorized' })
        // }
        // console.log(canViewThisAction('show', 'PurchaseRequest'))
        this.purchaseRequestForm = response.data.prf || {}
        this.purchaseRequestForm.items.forEach(item => {
          // eslint-disable-next-line no-param-reassign
          item.stocks = response.data.stocks.filter(i => i.item === item.inventoryID)
        })
        this.cpUserOptions = response.data.cpUserOptions || []

        this.cpUser = this.purchaseRequest.assignedTo ? this.purchaseRequest.assignedTo._id : ''
        // this.purchaseRequestSignatories = response.data.purchaseRequestSignatories || []
        // this.purchaseRequestItems = response.data.purchaseRequestItems || []

        // this.setData()

        // let arr = []
        // // eslint-disable-next-line no-plusplus
        // for (let i = 0; i < 6; i++) {
        //   arr = arr.concat(response.data.prf.items)
        // }
        // this.purchaseRequestForm.items = arr

        this.prActivityLogs = this.purchaseRequest.activityLog.sort((a, b) => new Date(b.time) - new Date(a.time))
        this.prComments = response.data.comments
        const { budgetExpense, expenseItems } = response.data
        store.commit('pr-detail-store/set_expense_data', { budgetExpense, expenseItems })
        // this.budgetExpense = response.data.budgetExpense
        // this.expenseItems = response.data.expenseItems
      })
      .catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  },
  unmounted() {
    if (store.hasModule('pr-detail-store')) store.unregisterModule('pr-detail-store')
  },
  methods: {
    changeRoute(param) {
      this.tabType = param
    },
    changeTab(index) {
      this.tabIndex = index
      const currentTab = this.pageTabs[index]
      if (currentTab) {
        this.currentTabTitle = currentTab.title
      }
    },
    resolveStageName(stage = null) {
      if (stage && stage === 'in-request') return 'Purchase Request'
      if (stage && stage === 'in-quotation') return 'Quotation'
      if (stage && stage === 'in-order') return 'Purchase Order'
      if (stage && stage === 'in-delivery') return 'Delivery'
      return ''
    },
    callPREdit() {
      if (this.$refs.prfComponent) {
        this.$refs.prfComponent.warningBeforeEdit()
      }
    },
    callQCFEdit() {
      if (this.$refs.qcfComponent) {
        this.$refs.qcfComponent.warningBeforeEdit()
      }
    },
    callPOEdit() {
      if (this.$refs.poComponent) {
        this.$refs.poComponent.warningBeforeEdit()
      }
    },
    handleStatusChange($event) {
      console.log($event)
      // this.purchaseRequest.status = $event.status
    },
    resolveAcronymName(name) {
      return name.split(' ').map(item => item.charAt(0)).join('').toUpperCase()
    },
    addComment() {
      this.$http
        .post('purchase/purchase-request/save-comment', { comment: this.extraComment, stage: this.purchaseRequest.stage, purchaseRequest: this.$route.params.id })
        .then(response => {
          this.extraComment = ''
          this.prComments = response.data.comments
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.data.message || '',
              icon: 'AlertTriangleIcon',
              variant: 'success',
            },
          })
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.data.message || '',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    getVariant(key) {
      if (key % 4 === 0) return 'info'
      if (key % 4 === 1) return 'warning'
      if (key % 4 === 2) return 'danger'
      if (key % 4 === 3) return 'purple'
      return 'primary'
    },
    setData() {
      this.purchaseRequestID = this.purchaseRequest._id
      this.status = this.purchaseRequest.status
      this.stringID = this.purchaseRequest.stringID

      this.$http.get(`directory/users/${this.purchaseRequest.createdBy._id}/show`)
        .then(response => {
          this.user = response.data ?? {}
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    showCancelWarning() {
      this.$swal({
        title: 'Are You Sure?',
        html: 'Cancelling this Purchase Request will freeze this Purchase Request. You will no longer be able to edit this Purchase Request.<br><br><span class="text-bold-black">This action cannot be undone.</span>',
        // eslint-disable-next-line global-require
        imageUrl: require('@/assets/images/icons/warning.png'),
        reverseButtons: true,
        showCancelButton: true,
        cancelButtonText: 'No, go back',
        confirmButtonText: 'Yes, cancel this PR',
        customClass: {
          confirmButton: 'btn btn-primary ml-1',
          cancelButton: 'btn btn-outline-primary',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            this.$http.post(`purchase/purchase-request/${this.$route.params.id}/cancel`, {})
              .then(() => {
                this.purchaseRequest.status = 'cancelled'
                this.purchaseRequest.budgetStatus = null
                const budgetExpense = null
                const expenseItems = []
                store.commit('pr-detail-store/set_expense_data', { budgetExpense, expenseItems })
                this.$swal({
                  title: 'Purchase Request Cancelled!',
                  html: `Purchase Request <strong>${this.purchaseRequest.caseID}</strong> has been cancelled`,
                  // eslint-disable-next-line global-require
                  imageUrl: require('@/assets/images/icons/success.png'),
                  imageWidth: 80,
                  imageHeight: 80,
                  imageAlt: 'Custom Icon',
                  showCancelButton: false,
                  confirmButtonText: 'Okay',
                  customClass: {
                    confirmButton: 'btn btn-primary',
                  },
                  buttonsStyling: false,
                })
              })
              .catch(error => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              })
          }
        })
    },
    cpUserSubmitForm() {
      this.$refs.cpUserEditForm.validate().then(success => {
        if (success) {
          const formData = new FormData()
          formData.append('cpUser', this.cpUser)

          this.$http.patch(`purchase/purchase-request/${this.$route.params.id}/update/cp-user`, formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          })
            .then(response => {
              this.purchaseRequest.assignedTo = response.data.data.assignedTo || {}
              this.purchaseRequestForm.assignedTo = response.data.prf.assignedTo || {}
              this.$swal({
                title: 'CP User Updated!',
                html: `CP User for <strong>${this.purchaseRequest.caseID}</strong> has been updated`,
                // eslint-disable-next-line global-require
                imageUrl: require('@/assets/images/icons/save.png'),
                imageWidth: 80,
                imageHeight: 80,
                imageAlt: 'Custom Icon',
                showCancelButton: false,
                confirmButtonText: 'Okay',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            })
            .catch(error => {
              if (error.data.errors) {
                error.data.errors.forEach(validationError => {
                  if (validationError.param === 'cpUser') {
                    this.cpUserError = validationError.msg
                    this.cpUserValidation = true
                  }
                })
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              }
            })
        }
      })
    },
  },
}
</script>

<style>
  .timeline-variant-info .timeline-item-point {
    background-color: #00cfe8 !important;
  }
  .timeline-variant-purple .timeline-item-point {
    background-color: #7367F0 !important;
  }
</style>

<style lang="scss">
@import "~@core/scss/base/pages/app-email.scss";
</style>
