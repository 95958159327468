<template>
  <div>
    <div class="quote__page">
      <div>
        <!-- Table Container Card -->
        <b-card
          no-body
        >
          <b-row
            class="content-header"
          >
            <b-col
              class="content-header-left mb-2"
              cols="12"
              md="12"
            >
              <b-row class="create-edit-form breadcrumbs-top">
                <b-col cols="6">
                  <h1 class="custom-header-title">
                    QCF-{{ purchaseRequest.stringID }}
                  </h1>
                  <span class="log_info">
                    PRF created by {{ purchaseRequest.createdBy ? purchaseRequest.createdBy.name : '' }} on {{ dateFormatWithTime(purchaseRequest.createdAt) }}
                  </span>
                </b-col>

                <b-col
                  v-if="quotation.signatories"
                  class="text-right justify-content-end align-items-right ml-auto"
                  cols="6"
                >
                  <div class="demo-inline-spacing">
                    <div
                      v-for="(signatory, index) of quotation.signatories"
                      :key="index"
                    >
                      <b-button
                        v-if="signatory.signed === false"
                        v-b-tooltip.hover
                        :title="`Pending Sign by ${signatory.user ? signatory.user.name : ''}`"
                        variant="flat-primary"
                        class="btn-icon"
                      >
                        <feather-icon
                          icon="FeatherIcon"
                          size="18"
                        />
                      </b-button>
                      <b-button
                        v-else
                        v-b-tooltip.hover
                        :title="`Signed by ${signatory.user ? signatory.user.name : ''}`"
                        variant="success"
                        class="btn-icon"
                      >
                        <feather-icon
                          icon="CheckIcon"
                          size="18"
                        />
                      </b-button>
                    </div>
                  </div>
                </b-col>

                <b-col
                  class="text-right justify-content-end align-items-right ml-auto"
                  cols="6"
                >
                  <!-- have to add condition for cp user -->
                  <!-- <b-button
                    v-if="!quotation.createdBy && user._id == (purchaseRequest.assignedTo ? purchaseRequest.assignedTo._id : '')"
                    v-b-modal.vendor-selection
                    variant="success"
                  >
                    <feather-icon
                      icon="PlusIcon"
                      class="mr-50"
                      size="16"
                    />
                    <span class="align-middle">Add Quotation</span>
                  </b-button> -->
                  <b-button
                    v-if="purchaseRequest.status != 'cancelled' && !quotation.createdBy && user._id == (purchaseRequest.assignedTo ? purchaseRequest.assignedTo._id : '') && canViewThisAction('create', 'Quotation')"
                    v-b-modal.vendor-selection
                    variant="success"
                    class="mr-0"
                  >
                    <feather-icon
                      icon="PlusIcon"
                      class="mr-50"
                      size="16"
                    />
                    <span class="align-middle">Add Quotation</span>
                  </b-button>
                </b-col>
              </b-row>
            </b-col><!-- Content Left -->
          </b-row>
          <div class="pur_con_header">
            <b-row
              class="content-header"
            >
              <!-- Content Left -->
              <b-col
                class="content-header-left mb-2"
                cols="12"
                md="6"
              >
                <b-button
                  variant="primary"
                  class="mr-1 md-mb-1"
                  :disabled="purchaseRequest.status == 'cancelled'"
                  @click="$refs.hiddenFileInput.click()"
                >
                  Upload File
                </b-button>
                <b-button
                  v-if="attachments.length"
                  variant="success"
                  class="mr-1 md-mb-1"
                  @click="uploadFile"
                >
                  Save File
                </b-button>
                <input
                  ref="hiddenFileInput"
                  type="file"
                  multiple
                  capture
                  accept="image/jpeg, image/png, application/pdf"
                  class="d-none"
                  @change="setAttachment"
                >
                <!-- <b-button
                  variant="primary"
                  class="mr-1"
                  :disabled="purchaseRequest.stage != 'in-quotation' || quotation.status == 'approved' || user._id != (purchaseRequest.assignedTo ? purchaseRequest.assignedTo._id : '')"
                  @click="warningBeforeEdit()"
                > -->
                <b-button
                  variant="primary"
                  class="mr-1 md-mb-1"
                  :disabled="purchaseRequest.status == 'cancelled' || ['in-request', 'in-delivery'].includes(purchaseRequest.stage) || !quotation.status || !canViewThisAction('update', 'Quotation')"
                  @click="warningBeforeEdit()"
                >
                  Edit
                </b-button>
                <b-button
                  variant="primary"
                  class="md-mb-1"
                  :disabled="quotation.status != 'approved' || !canViewThisAction('download-qcf', 'Quotation')"
                  @click="downloadQCF"
                >
                  <feather-icon
                    icon="DownloadIcon"
                    size="14"
                  />
                </b-button>
                <!-- <b-dropdown
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  class=""
                  right
                  no-caret
                  size="sm"
                  variant="primary"
                >
                  <template #button-content>
                    <feather-icon
                      icon="DownloadIcon"
                      size="21"
                      class="align-middle"
                    />
                  </template>
                  <b-dropdown-item>View History</b-dropdown-item>
                  <b-dropdown-item
                    :disabled="quotation.status != 'approved' || !canViewThisAction('download-qcf', 'Quotation')"
                    @click="downloadQCF"
                  >
                    Print
                  </b-dropdown-item>
                </b-dropdown> -->
              </b-col>
              <b-col
                class="content-header-left qcf-detain-button mb-2"
                cols="12"
                md="6"
              >
                <b-button
                  v-if="purchaseRequest.status != 'cancelled' && user._id == (purchaseRequest.assignedTo ? purchaseRequest.assignedTo._id : '') && quotation.status == 'draft'"
                  variant="success"
                  :to="{ name: 'purchasing-purchase-requests-qcf-preview', params: { id: $route.params.id } }"
                >
                  <feather-icon
                    icon="CheckIcon"
                    class="mr-50"
                    size="18"
                  />
                  <span class="align-middle">
                    Submit For Approval
                  </span>
                </b-button>
                <div
                  v-for="(signatory, index) of quotation.signatories"
                  :key="index"
                >
                  <b-button
                    v-if="purchaseRequest.status != 'cancelled' && index != 0 && user._id == (signatory.user ? signatory.user._id : '') && quotation.signatories[index - 1].signed == true && signatory.signed == false"
                    variant="success"
                    :to="{ name: 'purchasing-purchase-requests-qcf-preview', params: { id: $route.params.id }, query: { type: index, user: signatory._id } }"
                  >
                    <feather-icon
                      icon="CheckIcon"
                      class="mr-50"
                      size="18"
                    />
                    <span class="align-middle">
                      Approve QCF
                    </span>
                  </b-button>
                </div>
                <!-- <b-button
                  v-if="quotation.status == 'approved' && purchaseRequest.stage == 'in-quotation' && user._id == (purchaseRequest.assignedTo ? purchaseRequest.assignedTo._id : '')"
                  variant="success"
                  @click="checkVendorApprovalStatus()"
                > -->
                <b-button
                  v-if="purchaseRequest.status != 'cancelled' && quotation.status == 'approved' && purchaseRequest.stage == 'in-quotation' && user._id == (purchaseRequest.assignedTo ? purchaseRequest.assignedTo._id : '') && canViewThisAction('create', 'PurchaseOrder')"
                  variant="success"
                  @click="checkVendorApprovalStatus()"
                >
                  <feather-icon
                    icon="CheckIcon"
                    class="mr-50"
                    size="18"
                  />
                  <span class="align-middle">
                    Prepare PO
                  </span>
                </b-button>
              </b-col>
            </b-row>
          </div>
          <div class="pur_con_body quote__table">
            <b-table-simple
              ref="teamsTable"
              class="position-relative has_padding quote__table"
              responsive
              caption-top
            >
              <b-thead head-variant="dark">
                <b-tr
                  v-if="quotation.comparedVendors"
                  class="group__tag"
                >
                  <b-th class="empty" />
                  <b-th class="empty" />
                  <b-th class="empty" />
                  <b-th class="empty" />
                  <b-th
                    v-for="(vndr, index) in quotation.comparedVendors"
                    :key="index"
                    class="group__tag_info"
                    colspan="3"
                  >
                    <b-badge
                      :variant="vndr.id.status == 'approved' ? 'light-success' : 'light-warning'"
                      class="text-capitalize"
                    >
                      {{ vndr.id.status == 'approved' ? 'Approved Vendor' : 'Not In Vendor List' }}
                    </b-badge>
                    <span class="vn__name">{{ vndr.name }}</span>
                    <span class="vn__gst">{{ vndr.code }}</span>
                  </b-th>
                </b-tr>

                <b-tr class="group__tag">
                  <b-th>
                    No
                  </b-th>
                  <b-th>
                    Item Description
                  </b-th>
                  <b-th>
                    Qty
                  </b-th>
                  <b-th>
                    Unit
                  </b-th>
                  <b-th
                    v-for="(vndr, index) in quotation.comparedVendors"
                    :key="index"
                    class="group__tag_info"
                    colspan="3"
                  >
                    <span class="mr-50 unit_price">u. price($)</span>
                    <span class="mr-50 total_price">TotaL($)</span>
                    <span v-if="vndr.code != 'No GST'">GST</span>
                  </b-th>
                </b-tr>
              </b-thead>
              <b-tbody
                v-if="quotation.comparedVendors"
                class="white-body-background"
              >
                <b-tr
                  v-for="(item, index) in quotationItems"
                  :key="index"
                >
                  <b-td>
                    {{ index + 1 }}
                  </b-td>
                  <b-td style="max-width: 100rem;">
                    <span class="text-bold-black">{{ item.itemName }}</span>
                    <br>
                    <span class="text-pre">{{ item.itemDescription }}</span>
                  </b-td>
                  <b-td>
                    {{ item.quantity }}
                  </b-td>
                  <td>
                    {{ item.unit }}
                  </td>
                  <b-td
                    v-for="(vndr, key) in quotation.comparedVendors"
                    :key="key"
                    class="group__tag_info "
                    colspan="3"
                  >
                    <span class="unit_price">{{ item.unitCost[key] }}</span>
                    <span class="ml-5 total_price">{{ item.unitCost[key] ? numberFormat((item.quantity * item.unitCost[key])) : 0.00 }}</span>
                    <b-badge
                      v-if="item.gst[key] == 'yes'"
                      variant="secondary"
                      style="background:rgba(186, 191, 199, 0.12); color:#000000;"
                    >
                      GST
                    </b-badge>
                  </b-td>
                </b-tr>
                <!-- <b-tr>
                  <b-td>
                    {{ quotationItems.length + 1 }}
                  </b-td>
                  <b-td>
                    Delivery
                  </b-td>
                  <b-td>
                    1
                  </b-td>
                  <b-td />
                  <b-td
                    v-for="(vndr, key) in quotation.comparedVendors"
                    :key="key"
                    class="group__tag_info"
                    colspan="3"
                  >
                    {{ quotation.deliveryCost[key] }}
                  </b-td>
                </b-tr>
                <b-tr>
                  <b-td>
                    {{ quotationItems.length + 2 }}
                  </b-td>
                  <b-td>
                    Discount
                  </b-td>
                  <b-td>
                    1
                  </b-td>
                  <b-td />
                  <b-td
                    v-for="(vndr, key) in quotation.comparedVendors"
                    :key="key"
                    class="group__tag_info"
                    colspan="3"
                  >
                    <b-form-group
                      :label-for="'discountList' + key"
                    >
                      {{ quotation.discountList[key] }}
                    </b-form-group>
                  </b-td>
                </b-tr> -->
                <tr class="cal_row subtotal_row">
                  <td />
                  <td colspan="3">
                    <span class="semi-bold">Subtotal</span>
                  </td>
                  <b-td
                    v-for="(vndr, key) in quotation.comparedVendors"
                    :key="key"
                    class="group__tag_info "
                    colspan="3"
                  >
                    {{ numberFormat(getSubTotal(key)) }}
                  </b-td>
                </tr>
                <tr class="cal_row">
                  <td />
                  <td colspan="3">
                    <span class="semi-bold">GST</span>
                  </td>
                  <b-td
                    v-for="(vndr, key) in quotation.comparedVendors"
                    :key="key"
                    class="group__tag_info"
                    colspan="3"
                  >
                    {{ getGST(key) == '-' ? '-' : (`${getGST(key)} (${quotation.gstList ? (quotation.gstList[key] ? quotation.gstList[key] : quotation.gstPercentage) : quotation.gstPercentage}%)`) }}
                  </b-td>
                </tr>
                <tr class="cal_row">
                  <td />
                  <td colspan="3">
                    <span class="semi-bold">Grand Total</span>
                  </td>
                  <b-td
                    v-for="(vndr, key) in quotation.comparedVendors"
                    :key="key"
                    class="group__tag_info"
                    colspan="3"
                  >
                    <span class="semi-bold">{{ numberFormat(getGrandTotal(key)) }}</span>
                  </b-td>
                </tr>
                <tr class="cal_row">
                  <td />
                  <td colspan="3">
                    <span class="semi-bold">Payment terms</span>
                  </td>
                  <b-td
                    v-for="(vndr, key) in quotation.comparedVendors"
                    :key="key"
                    class="group__tag_info"
                    colspan="3"
                  >
                    <span class="text-pre">{{ quotation.paymentTerms[key] }}</span><br>
                    <b-badge
                      v-if="vndr.id._id == quotation.recommendedProvider"
                      variant="secondary"
                    >
                      <feather-icon
                        icon="CheckIcon"
                        class="mr-25"
                      />
                      <span>Recommended</span>
                    </b-badge>
                  </b-td>
                </tr>
              </b-tbody>
              <tbody
                v-else
                class=""
              >
                <tr>
                  <td colspan="4">
                    <div class="empty_block">
                      <h4>No Quotation Yet</h4>
                      <b-button
                        v-if="purchaseRequest.status != 'cancelled' && canViewThisAction('create', 'Quotation')"
                        v-b-modal.vendor-selection
                        type="button"
                        variant="flat-primary"
                      >
                        <span class="align-middle">Add a Quotation</span>
                      </b-button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </b-table-simple>

            <hr
              v-if="purchaseRequest.attachments && purchaseRequest.attachments.length"
              class="dividerHR"
            >
            <div
              v-if="purchaseRequest.attachments && purchaseRequest.attachments.length"
              class="attach__block"
            >
              <b-row
                class="content-header"
              >

                <!-- Content Left -->
                <b-col
                  class="content-header-left mb-2"
                  cols="12"
                  md="9"
                >
                  <h3>Attachments</h3>
                </b-col>
              </b-row>
              <b-row>
                <!-- Content Left -->
                <b-col
                  v-for="(file, key) in purchaseRequest.attachments"
                  :key="key"
                  class="content-header-left mb-2"
                  cols="12"
                  md="4"
                >
                  <div
                    class="attachment-item"
                  >
                    <b-img
                      v-if="file.type.includes('image')"
                      left
                      height="40"
                      :src="require('@/assets/images/admin/doc_jpg.png')"
                      alt="Left image"
                    />
                    <b-img
                      v-if="file.type.includes('application')"
                      left
                      height="40"
                      :src="require('@/assets/images/admin/doc_file.png')"
                      alt="Left image"
                    />
                    <strong>
                      <b-link
                        :href="file ? file.data : ''"
                        target="_blank"
                      >
                        {{ file ? file.name : '' }}
                      </b-link>
                    </strong>
                    <span>{{ file ? `${file.size}mb` : '' }}</span>
                    <span class="remove__doc">
                      <b-button
                        v-if="purchaseRequest.status != 'cancelled'"
                        variant="flat-primary"
                        @click="removeAttachment(file.data)"
                      >
                        <feather-icon
                          icon="XIcon"
                          class="mr-50"
                          size="18"
                        />
                      </b-button>
                    </span>
                  </div>
                  <div class="doc__desc">
                    <span v-if="file.description">
                      {{ file.description }}
                    </span>
                    <span v-else>
                      <button
                        v-if="purchaseRequest.status != 'cancelled'"
                        @click="updateDescription(key)"
                      >
                        Add a description
                      </button>
                    </span>
                    <span
                      v-if="file.description"
                      class="edit__desc"
                    >
                      <b-button
                        v-if="purchaseRequest.status != 'cancelled'"
                        variant="flat-primary"
                        @click="updateDescription(key)"
                      >
                        <feather-icon
                          icon="EditIcon"
                          class="mr-50"
                          size="18"
                        />
                      </b-button>
                    </span>
                  </div>
                </b-col>
              </b-row>
            </div>
            <hr class="dividerHR">
            <div class="recomend__block">
              <b-row>
                <b-col cols="md-5">
                  <b-form-group
                    label="Recommended External Provider"
                    label-for="h-event-recommendedProvider"
                  >
                    {{ quotation.recommendedProviderName }}
                  </b-form-group>
                </b-col>
                <b-col cols="md-7">
                  <b-form-group
                    label="Remarks"
                    label-for="h-event-remarks"
                  >
                    <span class="text-pre">{{ quotation.remarks }}</span>
                  </b-form-group>
                </b-col>
              </b-row>
            </div>
          </div>
          <b-card
            header-tag="header"
            no-body
            class="budget-expense-card mt-2"
          >
            <template #header>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="button"
                variant="flat-primary"
                class="primary-button-with-background mr-3 header-icon"
              >
                <feather-icon
                  icon="DollarSignIcon"
                  class=""
                  size="20"
                />
              </b-button>
              <h3 class="align-middle mr-2">
                Budget
              </h3>
              <b-badge
                v-if="purchaseRequest.budgetStatus"
                :variant="`light-${purchaseRequest.budgetStatus == 'allocated' ? 'success' : 'danger'}`"
                class="text-capitalize-first"
              >
                {{ purchaseRequest.budgetStatus }}
              </b-badge>
              <b-button
                v-if="purchaseRequest.status != 'cancelled' && expenseItems.length && (canViewThisAction('update', 'BudgetAllocation') || canViewThisAction('edit-budget-all-stages', 'BudgetAllocation'))"
                variant="flat-primary"
                class="ml-auto"
                :to="{ name: 'purchasing-purchase-request-update-allocated-budget', params: { parent: $route.params.id, id: budgetExpense._id }, query: { type: purchaseRequest.requestType } }"
              >
                <feather-icon
                  icon="EditIcon"
                  class="mr-50"
                  size="16"
                />
                <span class="align-middle">Edit Budget</span>
              </b-button>
            </template>
            <div
              v-if="expenseItems.length"
            >
              <budget-expense />
            </div>
            <div
              v-else
              class="row"
            >
              <b-col>
                <div class="empty_block">
                  <h4>Budget not allocated yet.</h4>
                  <b-button
                    v-if="purchaseRequest.status != 'cancelled' && canViewThisAction('allocate-budget-all-stages', 'BudgetAllocation')"
                    type="button"
                    variant="flat-primary"
                    :disabled="!purchaseRequest.requestType"
                    :to="{ name: 'purchasing-purchase-request-allocate-budget', params: { id: $route.params.id }, query: { type: purchaseRequest.requestType, department: (purchaseRequest.createdBy ? (purchaseRequest.createdBy.department.length ? purchaseRequest.createdBy.department[0]._id : '') : '') } }"
                  >
                    <span class="align-middle">Allocate Budget</span>
                  </b-button>
                </div>
              </b-col>
            </div>
          </b-card>
          <div class="signatories_block mt-2">
            <b-row
              class="content-header"
            >

              <!-- Content Left -->
              <b-col
                v-for="(signatory, index) of quotation.signatories"
                :key="index"
                cols="3"
                md="3"
                class="mt-md-0 mt-3"
                order="2"
                order-md="1"
              >
                <b-card-text class="mb-0">
                  <span class="font-weight-bold">{{ index + 1 }}. {{ signatory.type }}</span>
                  <div class="sign-box">
                    <div v-if="signatory.user">
                      <b-avatar
                        v-if="signatory.user.image"
                        :src="signatory.user.image"
                      />
                      <b-avatar
                        v-else
                        :text="resolveAcronymName(signatory.user.name)"
                      />
                    </div>
                    <div v-if="signatory.signed == true">
                      <!-- <div v-if="signatory.user && signatory.user.signature">
                        <b-img
                          class="mb-1 mb-sm-0 user__avatar"
                          height="50"
                          :src="signatory.user.signature"
                        />
                      </div> -->
                      <div v-if="signatory.user && signatory.user.name">
                        <span>{{ signatory.user.name }}</span>
                      </div>
                    </div>
                    <div v-else>
                      <p>Pending Signature</p>
                    </div>
                  </div>
                  <p class="ml-75 semi-bold">
                    {{ signatory.user ? signatory.user.name : '' }}
                  </p>
                  <p class="ml-75">
                    {{ signatory.user ? (signatory.user.department.length ? signatory.user.department[0].name : '') : '' }}
                  </p>
                  <p class="ml-75">
                    {{ signatory.signed == true ? dateFormatWithTime(signatory.date) : '-' }}
                  </p>
                </b-card-text>
              </b-col>
            </b-row>
          </div>
        </b-card>

        <!-- <pdf-view
          ref="pdfGenerateComponentQCF"
          :purchase-request="purchaseRequest"
          :quotation="quotation"
          :quotation-items="quotationItems"
          :pdf-total-page="pdfTotalPage"
          :chunks-of-item-requests="chunksOfItemRequests"
          :chunk-item-requests="chunkItemRequests"
          :gap-height="gapHeight"
        /> -->

        <b-modal
          id="vendor-selection"
          ref="update-status-modal"
          cancel-variant="flat-primary"
          modal-class="status-update-modal"
          ok-variant="success"
          ok-title="Next"
          cancel-title="Cancel"
          centered
          no-close-on-backdrop
          title="Add a Quotation"
          @ok="submitForm"
        >
          <b-form @submit.prevent="submitForm">
            <validation-observer
              v-if="vendorType === 1"
              ref="existingVendorForm"
            >
              <b-form-group
                label="Select an Existing Vendor*"
                label-for="h-event-status"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Select an Existing Vendor"
                  vid="vendor"
                  rules="required"
                >
                  <v-select
                    id="h-event-status"
                    v-model="vendor"
                    label="companyName"
                    placeholder="Select from the list"
                    :options="vendorOptions"
                    :reduce="companyName => companyName._id"
                    :clearable="false"
                  >
                    <!-- eslint-disable -->
                    <template #option="{ status, companyName }">
                      <span>{{ companyName }}</span> <span class="text-capitalize">({{ status }})</span>
                    </template>

                    <template #selected-option="{ status, companyName }">
                      <span>{{ companyName }}</span> <span class="text-capitalize"> ({{ status }})</span>
                    </template>
                    <!-- eslint-enable -->
                  </v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-button
                variant="link"
                class="mt-0"
                @click="changeVendorType(2)"
              >
                Add a New Vendor Instead
              </b-button>
              <b-form-group
                label="Does This Vendor Charge GST?"
                label-for="h-booking-gst"
                class="applicable_days"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Does This Vendor Charge GST?"
                  vid="gst"
                  rules="required"
                >
                  <b-form-radio-group
                    id="h-booking-gst"
                    v-model="gst"
                    button-variant="outline-primary"
                    buttons
                    class="spaceBetweenRadio"
                    multiple
                  >
                    <b-form-radio
                      v-for="(option, index) in gstOptions"
                      :key="index"
                      :value="option.value"
                      class="mb-50 customCheckbox"
                    >
                      <feather-icon
                        v-if="gst === option.value"
                        icon="CheckIcon"
                        class="mr-50"
                        size="16"
                      />
                      <span class="align-middle">{{ option.text }}</span>
                    </b-form-radio>
                  </b-form-radio-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group
                v-if="['GST Exclusive', 'GST Inclusive'].includes(gst)"
                label="GST*"
                label-for="h-booking-gst-percentage"
              >
                <validation-provider
                  #default="{ errors }"
                  name="GST"
                  vid="gst-percentage"
                  rules="required"
                >
                  <b-form-input
                    id="h-booking-gst-percentage"
                    v-model="gstPercentage"
                    name="gstPercentage"
                    placeholder="7"
                    class="mb-0"
                    @keypress="validateNumberInput"
                    @paste="validateNumberPaste"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </validation-observer>
            <validation-observer
              v-else
              ref="newVendorForm"
            >
              <b-form-group
                label="Vendor's Name*"
                label-for="h-booking-vendorname"
              >
                <validation-provider
                  name="Vendor's Name"
                  vid="vendorName"
                  rules="required"
                >
                  <b-form-input
                    id="h-booking-vendorname"
                    v-model="vendorName"
                    name="vendorName"
                    placeholder="E.g. ABC Company"
                    class="mb-0"
                  />
                </validation-provider>
              </b-form-group>
              <b-button
                variant="link"
                class="mt-0"
                @click="changeVendorType(1)"
              >
                Select an Existing Vendor Instead
              </b-button>
              <b-form-group
                label="Does This Vendor Charge GST?"
                label-for="h-booking-gst"
                class="applicable_days"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Does This Vendor Charge GST?"
                  vid="gst"
                  rules="required"
                >
                  <b-form-radio-group
                    id="h-booking-gst"
                    v-model="gst"
                    button-variant="outline-primary"
                    buttons
                    class="spaceBetweenRadio"
                    multiple
                  >
                    <b-form-radio
                      v-for="(option, index) in gstOptions"
                      :key="index"
                      :value="option.value"
                      class="mb-50 customCheckbox"
                    >
                      <feather-icon
                        v-if="gst === option.value"
                        icon="CheckIcon"
                        class="mr-50"
                        size="16"
                      />
                      <span class="align-middle">{{ option.text }}</span>
                    </b-form-radio>
                  </b-form-radio-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group
                v-if="['GST Exclusive', 'GST Inclusive'].includes(gst)"
                label="GST*"
                label-for="h-booking-gst-percentage"
              >
                <validation-provider
                  #default="{ errors }"
                  name="GST"
                  vid="gst-percentage"
                  rules="required"
                >
                  <b-form-input
                    id="h-booking-gst-percentage"
                    v-model="gstPercentage"
                    name="gstPercentage"
                    placeholder="7"
                    class="mb-0"
                    @keypress="validateNumberInput"
                    @paste="validateNumberPaste"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </validation-observer>
          </b-form>
        </b-modal>
        <b-modal
          id="modal-attachment-description"
          size="lg"
          title="Attachment Description"
          modal-class="status-update-modal"
          centered
          no-stacking
          header-class="front-modal"
          @ok="saveDescription"
          @cancel="closeDescriptionModal"
        >
          <b-row>
            <b-col md="12">
              <b-form-textarea
                id="h-customer-phone-number"
                v-model="description"
                placeholder="E.g. Please remind to make payment"
              />
            </b-col>
          </b-row>
          <template #modal-footer="{ ok, cancel }">
            <b-button
              variant="flat-primary"
              @click="cancel()"
            >
              Cancel
            </b-button>
            <b-button
              variant="primary"
              :disabled="!description || attachmentKey === ''"
              @click="ok()"
            >
              <span class="align-middle">Save</span>
            </b-button>
          </template>
        </b-modal>
      </div>
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard, BRow, BButton, BCol, BForm, BFormGroup, BImg, BFormTextarea, BTableSimple, BThead, BTr, BTh, BTd, BTbody,
  BLink, BFormRadioGroup, BFormRadio, BFormInput, BCardText, BAvatar, VBTooltip, BBadge,
} from 'bootstrap-vue'
import { useUtils as useAclUtils } from '@/libs/acl/custom'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { required } from '@validations'
import store from '@/store/index'
import { jsPDF } from 'jspdf'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// import * as fontJs from '../../../assets/js/Montserrat-Regular-normal'
import '../../../assets/js/DroidSans-Regular-normal'
import '../../../assets/js/DroidSans-Bold-normal'
// import PdfView from './PDFView.vue'
import BudgetExpense from '../purchase-request/BudgetExpense.vue'

const { canViewThisAction } = useAclUtils()

export default {
  components: {
    BCard,
    BRow,
    BButton,
    BCol,
    BForm,
    BFormGroup,
    BImg,
    BFormTextarea,
    BFormInput,
    BLink,
    BFormRadioGroup,
    BFormRadio,
    BCardText,
    BAvatar,
    BTableSimple,
    BThead,
    BTr,
    BTh,
    BTd,
    BTbody,
    BBadge,
    // BDropdown,
    // BDropdownItem,

    vSelect,
    ValidationProvider,
    ValidationObserver,
    BudgetExpense,
    // PdfView,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  props: {
    purchaseRequest: {
      type: Object,
      required: true,
    },
    sQuotation: {
      type: Object,
      required: true,
    },
    prActivityLogs: {
      type: Array,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: [],
    },
  },
  data() {
    return {
      canViewThisAction,
      quotation: {},
      vendorOptions: [],
      user: store.state.auth.userData,
      vendorName: '',
      vendor: '',
      vendors: localStorage.getItem('vendors') ?? [],
      tabType: '',
      gst: 'GST Inclusive',
      gstPercentage: process.env.VUE_APP_GST_PERCENTAGE,
      // gstPercentage: 7,
      purchaseRequestSignatories: [],
      quotationItems: [],
      purchaseRequestID: '',
      stringID: '',
      status: 'confirmed',
      secondAgree: '',
      statusValidation: false,
      statusError: 'Valid status is required',
      statusDataOptions: [
        { title: 'Draft', code: 'draft' },
        { title: 'Pending Edit', code: 'pending edit' },
        { title: 'Pending Approval', code: 'pending approval' },
        { title: 'Verifying', code: 'verifying' },
        { title: 'Verified', code: 'verified' },
        { title: 'Cancelled', code: 'cancelled' },
      ],
      chunkItemRequests: [],
      chunksOfItemRequests: [],
      pdfTotalPage: 0,
      elHeight: 0,
      gapHeight: 0,
      previewPage: false,
      description: '',
      attachmentKey: '',
      vendorType: 1,
      files: [],
      attachments: [],
      required,
      gstOptions: [
        { text: 'No GST', value: 'No GST' },
        { text: 'GST Exclusive', value: 'GST Exclusive' },
        { text: 'GST Inclusive', value: 'GST Inclusive' },
      ],
      // eslint-disable-next-line global-require
      defaultImage: require('@/assets/images/frontend/logo-nirvana-memorial-garden.86fe2ff5.svg'),
    }
  },
  computed: {
    expenseItems() {
      return store.state['pr-detail-store'].expenseItems
    },
    budgetExpense() {
      return store.state['pr-detail-store'].budgetExpense
    },
  },
  mounted() {
    this.$http.get(`purchase/quotations/purchase-request/${this.$route.params.id}/detail`)
      .then(response => {
        this.vendorOptions = response.data.vendorOptions ?? []
        this.quotation = response.data.quotation || {}
        this.$emit('update:sQuotation', this.quotation)
        this.quotationItems = response.data.quotationItems || []
        // this.setData()
        // eslint-disable-next-line no-plusplus
        // for (let i = 0; i < 15; i++) {
        //   this.quotationItems = this.quotationItems.concat(response.data.quotationItems)
        // }
      })
      .catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  },

  methods: {
    validateNumberInput(event) {
      if (event.key && !/^-?\d*[.]?\d*$/.test(event.key)) {
        event.preventDefault()
      }
    },
    validateNumberPaste(event) {
      const text = (event.originalEvent || event).clipboardData.getData('text/plain')
      if (text && !/^-?\d*[.]?\d*$/.test(text)) {
        event.preventDefault()
      }
    },
    setAttachment(e) {
      if (e.target.files.length) {
        // const reader = new FileReader()
        // reader.readAsDataURL(this.file)
        // reader.onload = () => {
        //   e.target.filesrc = reader.result
        // }
        const acceptedTypes = ['image/jpeg', 'image/png', 'application/pdf']
        e.target.files.forEach(element => {
          if (acceptedTypes.includes(element.type)) {
            if (element.size > 20 * 1024 * 1024) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Can't add files more than 20mb",
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
            } else {
              const reader = new FileReader()
              reader.readAsDataURL(element)

              reader.onload = event => {
                const fileObj = {}
                fileObj.name = element.name
                fileObj.data = event.target.result
                fileObj.size = (element.size / (1024 * 1024)).toFixed(2)
                fileObj.type = element.type
                this.attachments.push(fileObj)
              }
            }
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Unsupported file type',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          }
        })
      }
    },
    uploadFile() {
      const formData = new FormData()
      formData.append('attachments', JSON.stringify(this.attachments))
      formData.append('purchaseRequest', this.$route.params.id)
      formData.append('caseID', this.purchaseRequest.caseID)
      this.$http.post('purchase/purchase-request/upload/new-file', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
        .then(response => {
          this.attachments = []
          this.$emit('update:purchaseRequest', response.data.data)
          this.$emit('update:prActivityLogs', response.data.data.activityLog.sort((a, b) => new Date(b.time) - new Date(a.time)))
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.data.message || '',
              icon: 'AlertTriangleIcon',
              variant: 'success',
            },
          })
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.data.message || '',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    changeVendorType(value) {
      this.vendorType = value
    },
    closeDescriptionModal() {
      this.$root.$emit('bv::hide::modal', 'modal-attachment-description', '')
    },
    resolveAcronymName(name) {
      return name.split(' ').map(item => item.charAt(0)).join('').toUpperCase()
    },
    getSubTotal(key) {
      const vendor = this.quotation.comparedVendors[key]
      let total = 0
      if (vendor.code === 'GST Inclusive') {
        this.quotationItems.forEach(item => {
          total += item.unitCost[key] ? item.quantity * item.unitCost[key] : 0
        })
        total -= this.getGST(key)
      } else {
        this.quotationItems.forEach(item => {
          total += item.unitCost[key] ? item.quantity * item.unitCost[key] : 0
        })
      }

      const discount = this.quotation.discountList[key] ? this.quotation.discountList[key] : 0
      const dCost = this.quotation.deliveryCost[key] ? this.quotation.deliveryCost[key] : 0

      total = total - discount + parseFloat(dCost)
      return Number.isNaN(total) ? '0.00' : total.toFixed(2)
    },
    getGST(key) {
      // eslint-disable-next-line no-nested-ternary
      const gstPercentageVal = this.quotation.gstList ? (this.quotation.gstList[key] ? this.quotation.gstList[key] : this.quotation.gstPercentage) : this.quotation.gstPercentage
      const vendor = this.quotation.comparedVendors[key]
      if (vendor.code === 'No GST') {
        return '-'
      }

      if (vendor.code === 'GST Inclusive') {
        let gstableAmount = 0
        this.quotationItems.forEach(item => {
          gstableAmount += (item.gst[key] && item.gst[key] === 'yes') ? item.quantity * (item.unitCost[key] ? item.unitCost[key] : 0) : 0
        })
        // eslint-disable-next-line no-mixed-operators
        const gstAmount = ((gstableAmount / (100 + parseFloat(gstPercentageVal))) * parseFloat(gstPercentageVal))
        return Number.isNaN(gstAmount) ? '0.00' : gstAmount.toFixed(2)
      }

      let total = 0

      this.quotationItems.forEach(item => {
        total += (item.gst[key] && item.gst[key] === 'yes') ? item.quantity * (item.unitCost[key] ? item.unitCost[key] : 0) : 0
      })

      // eslint-disable-next-line operator-assignment
      total = ((gstPercentageVal / 100) * total)

      return Number.isNaN(total) ? '0.00' : total.toFixed(2)
    },
    getGrandTotal(key) {
      return ((this.getGST(key) === '-' ? 0 : parseFloat(this.getGST(key))) + parseFloat(this.getSubTotal(key))).toFixed(2)
    },
    updateDescription(key) {
      this.attachmentKey = key
      this.description = this.purchaseRequest.attachments[key].description
      this.$root.$emit('bv::show::modal', 'modal-attachment-description', '')
    },
    removeAttachment(url) {
      this.$http
        .post('purchase/purchase-request/remove-attachment', { url, purchaseRequest: this.$route.params.id })
        .then(response => {
          this.$emit('update:purchaseRequest', response.data.data)
          this.$emit('update:prActivityLogs', response.data.data.activityLog.sort((a, b) => new Date(b.time) - new Date(a.time)))
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.data.message || '',
              icon: 'AlertTriangleIcon',
              variant: 'success',
            },
          })
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.data.message || '',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    warningBeforeEdit() {
      this.$swal({
        title: 'Are You Sure?',
        html: 'Editing the form now will <strong>reset the approval process</strong> and require all signatories to confirm and sign once again.',
        // eslint-disable-next-line global-require
        imageUrl: require('@/assets/images/icons/warning.png'),
        reverseButtons: true,
        showCancelButton: true,
        cancelButtonText: 'No, Cancel',
        confirmButtonText: 'Yes, Edit this QCF',
        customClass: {
          confirmButton: 'btn btn-primary ml-1',
          cancelButton: 'btn btn-outline-primary',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            this.$router.push({ name: 'purchasing-quotations-edit', params: { parent: this.$route.params.id, id: this.quotation._id } })
          }
        })
    },
    downloadQCF() {
      const formData = new FormData()
      formData.append('url', `${window.location.origin}/purchase-request/download/qcf/${this.$route.params.id}`)
      formData.append('landscape', true)
      this.$http.post('purchase/pdf/download', formData, {
        headers: { Accept: 'application/pdf' },
        responseType: 'arraybuffer',
      })
        .then(response => {
          const blob = new Blob([response.data], { type: 'application/pdf' })
          const link = document.createElement('a')
          link.href = window.URL.createObjectURL(blob)
          link.download = `QCF-${this.purchaseRequest.stringID}.pdf`
          link.click()
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    async oldDownloadQCF() {
      await this.calculateTotalPage()
      const vm = this
      const element = document.getElementById('pdfGenerateDiv')
      // element.style.fontFamily = 'Montserrat-Regular'
      element.style.letterSpacing = '0.5px'
      let height = 285.6
      if (this.pdfTotalPage === 1) {
        height = 297
      }
      if (this.pdfTotalPage === 2) {
        height = 284
      }
      // eslint-disable-next-line new-cap
      const pdf = new jsPDF('p', 'mm', [height, 210], true)
      // const pageWidth = pdf.internal.pageSize.width - (10 * 2)
      // pdf.setFont('Montserrat-Regular')
      pdf.setProperties({
        title: `QCF-${vm.purchaseRequest.stringID}`,
      })
      pdf.html(element, {
        margin: [5, 5, 5, 5],
        html2canvas: {
          scale: 0.25,
        },
        // width: pageWidth,
        // windowWidth: pageWidth,
        callback(pdfr) {
          pdfr.save(`QCF-${vm.purchaseRequest.stringID}.pdf`)
        },
        // x: 10,
        // y: 10,
        autoPaging: 'text',
        // fontFaces: [{
        //   family: 'Montserrat-Regular', weight: 400, stretch: 'normal', src: ['assets/fonts/Montserrat-Regular.ttf'],
        // }],
      })
    },
    async calculateTotalPage() {
      await this.$nextTick()
      this.chunksOfItemRequests = []
      this.chunkItemRequests = []
      this.pdfTotalPage = 0
      const logoHeight = document.getElementById('main-header').offsetHeight
      const tableFooterHeight = document.getElementById('tableFooter').offsetHeight
      const footerHeight = document.getElementById('footer-div').offsetHeight
      let contentHeight = 0
      let totalItems = this.quotationItems.length
      // let i = 0
      let sliceStart = 0
      let sliceEnd = 10
      if (totalItems > 10) {
        sliceEnd = 14
      }
      while (totalItems > 0) {
        contentHeight = 1080 - (logoHeight + tableFooterHeight + footerHeight)
        // console.log(contentHeight)
        this.chunkItemRequests = this.quotationItems.slice(sliceStart, sliceEnd)
        // this.$nextTick(() => {
        //   console.log(document.getElementById('content-div').offsetHeight)
        // })
        // eslint-disable-next-line no-await-in-loop
        await this.$nextTick()
        let tableHeight = document.getElementById('content-div').offsetHeight - tableFooterHeight
        // console.log(tableHeight)
        if (tableHeight > contentHeight) {
          contentHeight += footerHeight
          contentHeight += tableFooterHeight
          // contentHeight += tableFooterHeight
        }
        // // console.log(tableHeight)
        while (tableHeight > contentHeight) {
          sliceEnd -= 1
          // console.log(contentHeight, tableHeight)
          this.chunkItemRequests = this.quotationItems.slice(sliceStart, sliceEnd)
          // console.log(sliceStart, sliceEnd)
          // eslint-disable-next-line no-await-in-loop
          await this.$nextTick()
          tableHeight = document.getElementById('content-div').offsetHeight - tableFooterHeight
          // console.log(tableHeight, contentHeight)
        }
        // console.log(tableHeight)
        // console.log(sliceEnd - sliceStart)
        // console.log(this.chunkItemRequests.length)
        this.chunksOfItemRequests.push(this.chunkItemRequests)
        this.pdfTotalPage += 1
        // i += 1
        sliceStart = sliceEnd
        sliceEnd += 17
        totalItems -= this.chunkItemRequests.length
      }

      // if (this.pdfTotalPage === 1) {
      //   contentHeight = 940 - (logoHeight + tableFooterHeight + footerHeight)
      // } else {
      //   contentHeight = 940 - (logoHeight + footerHeight)
      // }
      // const finalHeight = document.getElementById('content-div').offsetHeight
      // if (finalHeight > contentHeight) {
      //   this.pdfTotalPage += 1
      // }
      let remainingHeight = 0
      const dHeight = 1080
      // if (this.isMobile()) {
      //   dHeight = 1120
      // }
      const finalDataHeight = document.getElementById('content-div').offsetHeight
      // if (this.pdfTotalPage === 1) {
      //   finalDataHeight = document.getElementById('content-div').offsetHeight
      // } else {
      //   finalDataHeight = document.getElementById('content-div').offsetHeight - tableFooterHeight
      // }
      this.gapHeight = 0
      remainingHeight = dHeight - (logoHeight + footerHeight + finalDataHeight)
      if (remainingHeight > 0) {
        this.gapHeight = remainingHeight
      }
      if (finalDataHeight >= (dHeight - (logoHeight + footerHeight))) {
        this.pdfTotalPage += 1
        this.gapHeight = dHeight - (logoHeight + footerHeight)
      }
    },
    saveDescription() {
      const url = this.purchaseRequest.attachments[this.attachmentKey].data
      this.$http
        .post('purchase/purchase-request/save-description', { url, description: this.description, purchaseRequest: this.$route.params.id })
        .then(response => {
          this.$emit('update:purchaseRequest', response.data.data)
          this.closeDescriptionModal()
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.data.message || '',
              icon: 'AlertTriangleIcon',
              variant: 'success',
            },
          })
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.data.message || '',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    signQCF() {

    },
    getBack() {
      this.previewPage = false
    },
    approveQCF(id, index) {
      let firstSwalMessage = ''
      let secondSwalMessage = ''
      let secondSwalTitle = 'QCF Signed'
      if ((this.quotation.signatories.length - 1) === index) {
        firstSwalMessage = 'You are proceeding to sign this QCF. Following this, Central Purchasing will start the PO process.'
        secondSwalTitle = 'QCF Approved'
        secondSwalMessage = `${this.purchaseRequest.caseID} is now ready for Purchase Order creation by Central Purchasing.`
      } else {
        firstSwalMessage = `You are proceeding to sign on this QCF. The QCF will then be sent to ${this.quotation.signatories[index + 1] ? this.quotation.signatories[index + 1].user.name : ''} for signing.`
        secondSwalMessage = `${this.purchaseRequest.caseID} will now be sent to the next assigned signatory (${this.quotation.signatories[index + 1] ? this.quotation.signatories[index + 1].user.name : ''}) for signing.`
      }
      // this.previewPage = true
      this.$swal({
        title: 'Sign this QCF?',
        html: firstSwalMessage,
        // eslint-disable-next-line global-require
        imageUrl: require('@/assets/images/icons/fly.png'),
        reverseButtons: true,
        showCancelButton: true,
        cancelButtonText: 'No, Cancel',
        confirmButtonText: 'Yes, Proceed!',
        customClass: {
          confirmButton: 'btn btn-primary ml-1',
          cancelButton: 'btn btn-outline-primary',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            const formData = new FormData()
            formData.append('id', id)
            formData.append('index', index)
            formData.append('signatoryRequired', this.quotation.signatoryRequired)

            this.$http.post(`purchase/purchase-request/${this.$route.params.id}/approve-qcf/${this.quotation._id}`, formData, {
              headers: { 'Content-Type': 'multipart/form-data' },
            })
              .then(response => {
                this.$emit('update:purchaseRequest', response.data.data)
                this.quotation = response.data.qcf
                this.$emit('update:sQuotation', this.quotation)
                this.$swal({
                  title: secondSwalTitle,
                  html: secondSwalMessage,
                  // eslint-disable-next-line global-require
                  imageUrl: require('@/assets/images/icons/success.png'),
                  imageWidth: 80,
                  imageHeight: 80,
                  imageAlt: 'Custom Icon',
                  showCancelButton: false,
                  confirmButtonText: 'Okay',
                  customClass: {
                    confirmButton: 'btn btn-primary',
                  },
                  buttonsStyling: false,
                })
              })
              .catch(error => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              })
          }
        })
    },
    submitForApproval() {
      this.$swal({
        title: 'Submit this QCF?',
        html: `You are proceeding to sign this QCF. The QCF will then be sent to ${this.quotation.signatories[1] ? this.quotation.signatories[1].user.name : ''} for signing.`,
        // eslint-disable-next-line global-require
        imageUrl: require('@/assets/images/icons/fly.png'),
        reverseButtons: true,
        showCancelButton: true,
        cancelButtonText: 'No, Cancel',
        confirmButtonText: 'Yes, Proceed!',
        customClass: {
          confirmButton: 'btn btn-primary ml-1',
          cancelButton: 'btn btn-outline-primary',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            this.$http.post(`purchase/purchase-request/${this.$route.params.id}/quotation/${this.quotation._id}/requester/submit`, {
              headers: { 'Content-Type': 'multipart/form-data' },
            })
              .then(response => {
                this.$emit('update:purchaseRequest', response.data.data)
                this.quotation = response.data.qcf
                this.$emit('update:sQuotation', this.quotation)
                this.$swal({
                  title: 'Submitted for Approval',
                  html: `${this.purchaseRequest.caseID} will now be sent to the next assigned signatory (${this.quotation.signatories[1] ? this.quotation.signatories[1].user.name : ''}) for signing.`,
                  // eslint-disable-next-line global-require
                  imageUrl: require('@/assets/images/icons/success.png'),
                  imageWidth: 80,
                  imageHeight: 80,
                  imageAlt: 'Custom Icon',
                  showCancelButton: false,
                  confirmButtonText: 'Okay',
                  customClass: {
                    confirmButton: 'btn btn-primary',
                  },
                  buttonsStyling: false,
                })
              })
              .catch(error => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              })
          }
        })
    },
    setData() {
      this.purchaseRequestID = this.purchaseRequest._id
      this.status = this.purchaseRequest.status
      this.stringID = this.purchaseRequest.caseID

      this.$http.get(`directory/users/${this.purchaseRequest.createdBy._id}/show`)
        .then(response => {
          this.user = response.data ?? {}
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },

    checkVendorApprovalStatus() {
      const vendor = this.quotation.comparedVendors.find(o => o.id._id === this.quotation.recommendedProvider)
      if (vendor.id.status !== 'approved') {
        this.$swal({
          title: 'Vendor Not In Vendor List',
          html: 'A PO can only be generated for Approved vendors. Complete the Vendor Approval process for this vendor before generating this PO.',
          // eslint-disable-next-line global-require
          imageUrl: require('@/assets/images/icons/warning.png'),
          reverseButtons: true,
          showCancelButton: true,
          width: '520px',
          cancelButtonText: 'Back to Summary',
          confirmButtonText: 'Proceed to Vendor Approval',
          customClass: {
            confirmButton: 'btn btn-primary ml-1',
            cancelButton: 'btn btn-outline-primary',
          },
          buttonsStyling: false,
        })
          .then(result => {
            if (result.value) {
              this.$router.push({ name: 'purchasing-vendors-show', params: { id: this.quotation.recommendedProvider } })
            }
          })
      } else {
        this.$router.push({ name: 'purchasing-purchase-order-create', params: { id: this.$route.params.id } })
      }
    },

    submitForm(bvModalEvt) {
      bvModalEvt.preventDefault()
      if (this.vendorType === 1) {
        this.$refs.existingVendorForm.validate().then(success => {
          if (success) {
            const selected = this.vendorOptions.find(o => o._id === this.vendor)
            const vendor = {
              id: this.vendor, name: selected.companyName, code: this.gst, gstPercentage: this.gstPercentage, type: 1, status: selected.status,
            }
            // this.vendors.push(purchase)
            localStorage.setItem('vendor', JSON.stringify(vendor))
            this.$router.push({ name: 'purchasing-quotations-create', params: { id: this.$route.params.id } })
          }
        })
      } else {
        this.$refs.newVendorForm.validate().then(success => {
          if (success) {
            const vendor = {
              id: null, name: this.vendorName, code: this.gst, gstPercentage: this.gstPercentage, type: 2,
            }
            // this.vendors.push(purchase)
            localStorage.setItem('vendor', JSON.stringify(vendor))
            this.$router.push({ name: 'purchasing-quotations-create', params: { id: this.$route.params.id } })
          }
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
