<template>

  <div class="po__details_page">
    <!-- Table Container Card -->
    <b-card
      no-body
    >
      <b-row
        class="content-header"
      >
        <b-col
          class="content-header-left mb-0"
          cols="12"
          md="12"
        >
          <b-row class="breadcrumbs-top">
            <b-col cols="6">
              <h1 class="custom-header-title">
                {{ purchaseRequest.caseID }}
              </h1>
              <p class="log_info">
                PRF created by {{ purchaseRequest.createdBy ? purchaseRequest.createdBy.name : '' }} on {{ dateFormatWithTime(purchaseRequest.createdAt) }}
              </p>
            </b-col>

            <b-col
              v-if="pOrder.signatories"
              class="text-right justify-content-end align-items-right ml-auto"
              cols="6"
            >
              <div class="demo-inline-spacing">
                <div
                  v-for="(signatory, index) of pOrder.signatories"
                  :key="index"
                >
                  <b-button
                    v-if="signatory.signed === false"
                    v-b-tooltip.hover
                    :title="`Pending Sign by ${signatory.user ? signatory.user.name : ''}`"
                    variant="flat-primary"
                    class="btn-icon"
                  >
                    <feather-icon
                      icon="FeatherIcon"
                      size="18"
                    />
                  </b-button>
                  <b-button
                    v-else
                    v-b-tooltip.hover
                    :title="`Signed by ${signatory.user ? signatory.user.name : ''}`"
                    variant="success"
                    class="btn-icon"
                  >
                    <feather-icon
                      icon="CheckIcon"
                      size="18"
                    />
                  </b-button>
                </div>
              </div>
            </b-col>
          </b-row>
        </b-col><!-- Content Left -->
      </b-row>
    </b-card>
    <vendor-delivery-information
      :purchase-request.sync="purchaseRequest"
      :p-order.sync="pOrder"
    />
    <b-card
      no-body
    >
      <div class="pur_con_header">
        <b-row
          class="content-header"
        >
          <!-- Content Left -->
          <b-col
            class="content-header-left mb-2"
            cols="12"
            md="6"
          >
            <b-button
              variant="primary"
              class="mr-1 md-mb-1"
              :disabled="purchaseRequest.status == 'cancelled'"
              @click="$refs.hiddenFileInput.click()"
            >
              Upload File
            </b-button>
            <b-button
              v-if="attachments.length"
              variant="success"
              class="mr-1 md-mb-1"
              @click="uploadFile"
            >
              Save File
            </b-button>
            <input
              ref="hiddenFileInput"
              type="file"
              multiple
              capture
              accept="image/jpeg, image/png, application/pdf"
              class="d-none"
              @change="setAttachment"
            >
            <b-button
              variant="primary"
              class="mr-1 md-mb-1"
              :disabled="purchaseRequest.status == 'cancelled' || purchaseRequest.stage != 'in-order' || !pOrder.status || pOrder.status == 'approved' || !canViewThisAction('update', 'PurchaseOrder')"
              @click="warningBeforeEdit()"
            >
              Edit
            </b-button>
            <b-button
              variant="primary"
              class="md-mb-1"
              :disabled="purchaseRequest.stage != 'in-delivery' || !canViewThisAction('download-po', 'PurchaseOrder')"
              @click="downloadPO"
            >
              <feather-icon
                icon="DownloadIcon"
                size="14"
              />
            </b-button>
            <!-- <b-dropdown
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              class=""
              right
              no-caret
              size="sm"
              variant="primary"
            >
              <template #button-content>
                <feather-icon
                  icon="DownloadIcon"
                  size="21"
                  class="align-middle"
                />
              </template>
              <b-dropdown-item>View History</b-dropdown-item>
              <b-dropdown-item
                :disabled="purchaseRequest.stage != 'in-delivery' || !canViewThisAction('download-po', 'PurchaseOrder')"
                @click="downloadPO"
              >
                Print
              </b-dropdown-item>
            </b-dropdown> -->
          </b-col>
          <b-col
            class="content-header-left mb-2"
            cols="12"
            md="6"
          >
            <b-button
              v-if="purchaseRequest.status != 'cancelled' && user._id == (purchaseRequest.assignedTo ? purchaseRequest.assignedTo._id : '') && pOrder.status == 'pending signatories' && pOrder.signatories.length && pOrder.signatories[0].signed == false"
              variant="success"
              disabled
            >
              <feather-icon
                icon="CheckIcon"
                class="mr-50"
                size="18"
              />
              <span class="align-middle">
                Submitted for Approval
              </span>
            </b-button>
            <b-button
              v-if="purchaseRequest.status != 'cancelled' && user._id == (purchaseRequest.assignedTo ? purchaseRequest.assignedTo._id : '') && pOrder.status == 'draft'"
              variant="success"
              :to="{ name: 'purchasing-purchase-requests-po-preview', params: { id: $route.params.id } }"
            >
              <feather-icon
                icon="CheckIcon"
                class="mr-50"
                size="18"
              />
              <span class="align-middle">
                Submit For Approval
              </span>
            </b-button>
            <div
              v-for="(signatory, index) of pOrder.signatories"
              :key="index"
            >
              <b-button
                v-if="purchaseRequest.status != 'cancelled' && index != 0 && user._id == (signatory.user ? signatory.user._id : '') && pOrder.signatories[index - 1].signed == true && signatory.signed == false"
                variant="success"
                :to="{ name: 'purchasing-purchase-requests-po-preview', params: { id: $route.params.id }, query: { type: index, user: signatory._id } }"
              >
                <feather-icon
                  icon="CheckIcon"
                  class="mr-50"
                  size="18"
                />
                <span class="align-middle">
                  Verify PO
                </span>
              </b-button>
            </div>
            <!-- <b-button
              v-if="purchaseRequest.stage == 'in-delivery' && user._id == (purchaseRequest.assignedTo ? purchaseRequest.assignedTo._id : '')"
              v-b-modal.email-to-vendor
              variant="success"
            > -->
            <b-button
              v-if="purchaseRequest.status != 'cancelled' && purchaseRequest.stage == 'in-delivery' && canViewThisAction('email-po', 'PurchaseOrder')"
              v-b-modal.email-to-vendor
              variant="success"
            >
              <feather-icon
                icon="MailIcon"
                class="mr-50"
                size="18"
              />
              <span class="align-middle">
                Email PO to Vendor
              </span>
            </b-button>
          </b-col>
        </b-row>
      </div>
      <div class="pur_con_body quote__table">
        <b-table-simple
          ref="teamsTable"
          class="position-relative has_padding quote__table"
          responsive
          caption-top
        >
          <b-thead head-variant="dark">
            <b-tr class="group__tag">
              <b-th>
                No
              </b-th>
              <b-th>
                Description
              </b-th>
              <b-th>
                Unit
              </b-th>
              <b-th>
                Qty Ordered
              </b-th>
              <b-th>
                Unit Cost (S$)
              </b-th>
              <b-th>
                Amount (S$)
              </b-th>
            </b-tr>
          </b-thead>
          <b-tbody
            class="white-body-background"
          >
            <b-tr
              v-for="(item, index) in poItems"
              :key="index"
            >
              <b-td>
                {{ index + 1 }}
              </b-td>
              <b-td style="max-width: 50rem;">
                <span class="text-bold-black">{{ item.itemName }}</span>
                <br>
                <span class="text-pre">{{ item.itemDescription }}</span>
              </b-td>
              <td>
                {{ item.unit }}
              </td>
              <b-td>
                {{ item.quantity }}
              </b-td>
              <td>
                {{ item.unitCost }}
              </td>
              <td>
                {{ numberFormat(item.amount) }}
              </td>
            </b-tr>
            <!-- <b-tr>
              <b-td>
                {{ poItems.length + 1 }}
              </b-td>
              <b-td>
                Delivery
              </b-td>
              <td />
              <b-td>
                1
              </b-td>
              <td>
                {{ pOrder.deliveryCost ? pOrder.deliveryCost : 0 }}
              </td>
              <td>
                {{ pOrder.deliveryCost ? pOrder.deliveryCost : 0 }}
              </td>
            </b-tr>
            <b-tr>
              <b-td>
                {{ poItems.length + 2 }}
              </b-td>
              <b-td>
                Discount
              </b-td>
              <td />
              <b-td>
                1
              </b-td>
              <td>
                {{ pOrder.discount ? pOrder.discount : 0 }}
              </td>
              <td>
                {{ pOrder.discount ? pOrder.discount : 0 }}
              </td>
            </b-tr> -->
            <tr class="cal_row subtotal_row">
              <td />
              <td colspan="4">
                <span class="semi-bold">Subtotal</span>
              </td>
              <td>
                {{ pOrder.subTotal ? numberFormat(pOrder.subTotal) : 0.00 }}
              </td>
            </tr>
            <tr class="cal_row">
              <td />
              <td colspan="4">
                <span class="semi-bold">GST {{ pOrder.gstPercentage }}%</span>
              </td>
              <td>
                {{ pOrder.gst == '-' ? '-' : (pOrder.gst ? numberFormat(pOrder.gst) : 0.00) }}
              </td>
            </tr>
            <tr class="cal_row last__row">
              <td />
              <td colspan="4">
                <span class="semi-bold">Grand Total</span>
              </td>
              <td>
                <span class="semi-bold">{{ pOrder.grandTotal ? numberFormat(pOrder.grandTotal) : 0.00 }}</span>
              </td>
            </tr>
          </b-tbody>
        </b-table-simple>

        <hr
          v-if="purchaseRequest.attachments && purchaseRequest.attachments.length"
          class="dividerHR"
        >
        <div
          v-if="purchaseRequest.attachments && purchaseRequest.attachments.length"
          class="attach__block"
        >
          <b-row
            class="content-header"
          >

            <!-- Content Left -->
            <b-col
              class="content-header-left mb-2"
              cols="12"
              md="9"
            >
              <h3>Attachments</h3>
            </b-col>
          </b-row>
          <b-row>
            <!-- Content Left -->
            <b-col
              v-for="(file, key) in purchaseRequest.attachments"
              :key="key"
              class="content-header-left mb-2"
              cols="12"
              md="4"
            >
              <div
                class="attachment-item"
              >
                <b-img
                  v-if="file.type.includes('image')"
                  left
                  height="40"
                  :src="require('@/assets/images/admin/doc_jpg.png')"
                  alt="Left image"
                />
                <b-img
                  v-if="file.type.includes('application')"
                  left
                  height="40"
                  :src="require('@/assets/images/admin/doc_file.png')"
                  alt="Left image"
                />
                <strong>
                  <b-link
                    :href="file ? file.data : ''"
                    target="_blank"
                  >
                    {{ file ? file.name : '' }}
                  </b-link>
                </strong>
                <span>{{ file ? `${file.size}mb` : '' }}</span>
                <span class="remove__doc">
                  <b-button
                    v-if="purchaseRequest.status != 'cancelled'"
                    variant="flat-primary"
                    @click="removeAttachment(file.data)"
                  >
                    <feather-icon
                      icon="XIcon"
                      class="mr-50"
                      size="18"
                    />
                  </b-button>
                </span>
              </div>
              <div class="doc__desc">
                <span v-if="file.description">
                  {{ file.description }}
                </span>
                <span v-else>
                  <button
                    v-if="purchaseRequest.status != 'cancelled'"
                    @click="updateDescription(key)"
                  >
                    Add a description
                  </button>
                </span>
                <span
                  v-if="file.description"
                  class="edit__desc"
                >
                  <b-button
                    v-if="purchaseRequest.status != 'cancelled'"
                    variant="flat-primary"
                    @click="updateDescription(key)"
                  >
                    <feather-icon
                      icon="EditIcon"
                      class="mr-50"
                      size="18"
                    />
                  </b-button>
                </span>
              </div>
            </b-col>
          </b-row>
        </div>
      </div>
      <b-card
        header-tag="header"
        no-body
        class="budget-expense-card mt-2"
      >
        <template #header>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="button"
            variant="flat-primary"
            class="primary-button-with-background mr-3 header-icon"
          >
            <feather-icon
              icon="DollarSignIcon"
              class=""
              size="20"
            />
          </b-button>
          <h3 class="align-middle mr-2">
            Budget
          </h3>
          <b-badge
            v-if="purchaseRequest.budgetStatus"
            :variant="`light-${purchaseRequest.budgetStatus == 'allocated' ? 'success' : 'danger'}`"
            class="text-capitalize-first"
          >
            {{ purchaseRequest.budgetStatus }}
          </b-badge>
          <b-button
            v-if="purchaseRequest.status != 'cancelled' && expenseItems.length && (canViewThisAction('update', 'BudgetAllocation') || canViewThisAction('edit-budget-all-stages', 'BudgetAllocation'))"
            variant="flat-primary"
            class="ml-auto"
            :to="{ name: 'purchasing-purchase-request-update-allocated-budget', params: { parent: $route.params.id, id: budgetExpense._id }, query: { type: purchaseRequest.requestType } }"
          >
            <feather-icon
              icon="EditIcon"
              class="mr-50"
              size="16"
            />
            <span class="align-middle">Edit Budget</span>
          </b-button>
        </template>
        <div
          v-if="expenseItems.length"
        >
          <budget-expense />
        </div>
        <div
          v-else
          class="row"
        >
          <b-col>
            <div class="empty_block">
              <h4>Budget not allocated yet.</h4>
              <b-button
                v-if="purchaseRequest.status != 'cancelled' && canViewThisAction('allocate-budget-all-stages', 'BudgetAllocation')"
                type="button"
                variant="flat-primary"
                :disabled="!purchaseRequest.requestType"
                :to="{ name: 'purchasing-purchase-request-allocate-budget', params: { id: $route.params.id }, query: { type: purchaseRequest.requestType, department: (purchaseRequest.createdBy ? (purchaseRequest.createdBy.department.length ? purchaseRequest.createdBy.department[0]._id : '') : '') } }"
              >
                <span class="align-middle">Allocate Budget</span>
              </b-button>
            </div>
          </b-col>
        </div>
      </b-card>
      <div class="signatories_block pt-2">
        <b-row
          class="content-header"
        >

          <!-- Content Left -->
          <b-col
            v-for="(signatory, index) of pOrder.signatories"
            :key="index"
            cols="3"
            md="3"
            class="mt-md-0 mt-3"
            order="2"
            order-md="1"
          >
            <b-card-text class="mb-0">
              <span class="font-weight-bold">{{ index + 1 }}. {{ signatory.type }}</span>
              <div class="sign-box">
                <div v-if="signatory.user">
                  <b-avatar
                    v-if="signatory.user.image"
                    :src="signatory.user.image"
                  />
                  <b-avatar
                    v-else
                    :text="resolveAcronymName(signatory.user.name)"
                  />
                </div>
                <div v-if="signatory.signed == true">
                  <!-- <div v-if="signatory.user && signatory.user.signature">
                    <b-img
                      class="mb-1 mb-sm-0 user__avatar"
                      height="50"
                      :src="signatory.user.signature"
                    />
                  </div> -->
                  <div v-if="signatory.user && signatory.user.name">
                    <span>{{ signatory.user.name }}</span>
                  </div>
                </div>
                <div v-else>
                  <p>Pending Signature</p>
                </div>
              </div>
              <p class="ml-75 semi-bold">
                {{ signatory.user ? signatory.user.name : '' }}
              </p>
              <p class="ml-75">
                {{ signatory.user ? (signatory.user.department.length ? signatory.user.department[0].name : '') : '' }}
              </p>
              <p class="ml-75">
                {{ signatory.signed == true ? dateFormatWithTime(signatory.date) : '-' }}
              </p>
            </b-card-text>
          </b-col>
        </b-row>
      </div>
    </b-card>

    <!-- <pdf-view
      ref="pdfGenerateComponentPO"
      :purchase-request="purchaseRequest"
      :p-order="pOrder"
      :po-items="poItems"
      :pdf-total-page="pdfTotalPage"
      :chunks-of-item-requests="chunksOfItemRequests"
      :chunk-item-requests="chunkItemRequests"
      :gap-height="gapHeight"
    /> -->

    <b-modal
      id="vendor-selection"
      ref="update-status-modal"
      cancel-variant="flat-primary"
      modal-class="status-update-modal"
      ok-variant="success"
      ok-title="Next"
      cancel-title="Cancel"
      centered
      no-close-on-backdrop
      title="Add a Quotation"
      @ok="submitForm"
    >
      <b-form @submit.prevent="submitForm">
        <validation-observer
          v-if="vendorType === 1"
          ref="existingVendorForm"
        >
          <b-form-group
            label="Select an Existing Vendor*"
            label-for="h-event-status"
          >
            <validation-provider
              #default="{ errors }"
              name="Select an Existing Vendor"
              vid="vendor"
              rules="required"
            >
              <v-select
                id="h-event-status"
                v-model="vendor"
                label="companyName"
                placeholder="Select from the list"
                :options="vendorOptions"
                :reduce="companyName => companyName._id"
                :clearable="false"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-button
            variant="link"
            @click="changeVendorType(2)"
          >
            Add a New Vendor Instead
          </b-button>
          <b-form-group
            label="Does This Vendor Charge GST?"
            label-for="h-booking-gst"
            class="applicable_days"
          >
            <validation-provider
              #default="{ errors }"
              name="Does This Vendor Charge GST?"
              vid="gst"
              rules="required"
            >
              <b-form-radio-group
                id="h-booking-gst"
                v-model="gst"
                button-variant="outline-primary"
                buttons
                class="spaceBetweenRadio"
                multiple
              >
                <b-form-radio
                  v-for="(option, index) in gstOptions"
                  :key="index"
                  :value="option.value"
                  class="mb-50 customCheckbox"
                >
                  <feather-icon
                    v-if="gst === option.value"
                    icon="CheckIcon"
                    class="mr-50"
                    size="16"
                  />
                  <span class="align-middle">{{ option.text }}</span>
                </b-form-radio>
              </b-form-radio-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </validation-observer>
        <validation-observer
          v-else
          ref="newVendorForm"
        >
          <b-form-group
            label="Vendor's Name"
            label-for="h-booking-vendorname"
          >
            <validation-provider
              name="Vendor's Name"
              vid="vendorName"
              rules="required"
            >
              <b-form-input
                id="h-booking-vendorname"
                v-model="vendorName"
                name="vendorName"
                placeholder="E.g. ABC Company"
              />
            </validation-provider>
          </b-form-group>
          <b-button
            variant="link"
            @click="changeVendorType(1)"
          >
            Select an Existing Vendor Instead
          </b-button>
          <b-form-group
            label="Does This Vendor Charge GST?"
            label-for="h-booking-gst"
            class="applicable_days"
          >
            <validation-provider
              #default="{ errors }"
              name="Does This Vendor Charge GST?"
              vid="gst"
              rules="required"
            >
              <b-form-radio-group
                id="h-booking-gst"
                v-model="gst"
                button-variant="outline-primary"
                buttons
                class="spaceBetweenRadio"
                multiple
              >
                <b-form-radio
                  v-for="(option, index) in gstOptions"
                  :key="index"
                  :value="option.value"
                  class="mb-50 customCheckbox"
                >
                  <feather-icon
                    v-if="gst === option.value"
                    icon="CheckIcon"
                    class="mr-50"
                    size="16"
                  />
                  <span class="align-middle">{{ option.text }}</span>
                </b-form-radio>
              </b-form-radio-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </validation-observer>
      </b-form>
    </b-modal>

    <b-modal
      id="email-to-vendor"
      ref="email-to-vendor-modal"
      cancel-variant="flat-primary"
      modal-class="status-update-modal"
      ok-variant="success"
      ok-title="Send"
      cancel-title="Cancel"
      centered
      no-close-on-backdrop
      title="Email PO to Vendor"
      @ok="sendEmailToVendor"
    >
      <b-form @submit.prevent="sendEmailToVendor">
        <validation-observer
          ref="emialToVendorForm"
        >
          <b-form-group
            label="Vendor Person-In-Charge"
            label-for="h-booking-Person-In-Charge"
          >
            <validation-provider
              #default="{ errors }"
              name="Person-In-Charge"
              vid="emailName"
              rules="required"
            >
              <b-form-input
                id="h-booking-Person-In-Charge"
                v-model="emailName"
                name="emailName"
                placeholder="Mr. Tay SC"
                class="mb-25"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group
            label="Email Address"
            label-for="h-booking-email"
          >
            <validation-provider
              #default="{ errors }"
              name="Email"
              vid="emailTo"
              rules="required"
            >
              <b-form-input
                id="h-booking-email"
                v-model="emailTo"
                name="emailTo"
                placeholder="tay_sc@superiorkitchen.com.sg"
                class="mb-25"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group
            label="Message"
            label-for="h-booking-message"
          >
            <validation-provider
              #default="{ errors }"
              name="Message"
              vid="emailMessage"
              rules=""
            >
              <b-form-textarea
                id="h-booking-message"
                v-model="emailMessage"
                placeholder="E.g.."
                class="mb-25"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </validation-observer>
      </b-form>
      <span>A signed copy of the PO will be sent to this vendor in PDF format.</span>
      <!-- <template #modal-footer="{ ok, cancel }">
        <b-button
          variant="flat-primary"
          @click="cancel()"
        >
          Cancel
        </b-button>
        <b-button
          variant="success"
          @click="ok()"
        >
          <feather-icon
            icon="SendIcon"
            size="18"
            class="mr-50"
          />
          <span class="align-middle">Send</span>
        </b-button>
      </template> -->
    </b-modal>

    <b-modal
      id="modal-attachment-description"
      size="lg"
      title="Attachment Description"
      modal-class="status-update-modal"
      centered
      no-stacking
      header-class="front-modal"
      @ok="saveDescription"
      @cancel="closeDescriptionModal"
    >
      <b-row>
        <b-col md="12">
          <b-form-textarea
            id="h-customer-phone-number"
            v-model="description"
            placeholder="E.g. Please remind to make payment"
          />
        </b-col>
      </b-row>
      <template #modal-footer="{ ok, cancel }">
        <b-button
          variant="flat-primary"
          @click="cancel()"
        >
          Cancel
        </b-button>
        <b-button
          variant="primary"
          :disabled="!description || attachmentKey === ''"
          @click="ok()"
        >
          <span class="align-middle">Save</span>
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard, BRow, BButton, BCol, BForm, BFormGroup, BImg, BFormTextarea, BTableSimple, BThead, BTr, BTh, BTd, BTbody,
  BLink, BFormRadioGroup, BFormRadio, BFormInput, BCardText, BAvatar, VBTooltip, BBadge,
} from 'bootstrap-vue'
import { useUtils as useAclUtils } from '@/libs/acl/custom'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { required } from '@validations'
import { jsPDF } from 'jspdf'
import store from '@/store/index'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import '../../../assets/js/DroidSans-Regular-normal'
import '../../../assets/js/DroidSans-Bold-normal'

// import PdfView from './PDFView.vue'
import BudgetExpense from '../purchase-request/BudgetExpense.vue'
import VendorDeliveryInformation from './VendorDeliveryInformation.vue'

const { canViewThisAction } = useAclUtils()

export default {
  components: {
    BCard,
    BRow,
    BButton,
    BCol,
    BForm,
    BFormGroup,
    BImg,
    BFormTextarea,
    BFormInput,
    BLink,
    BFormRadioGroup,
    BFormRadio,
    BCardText,
    BAvatar,
    BTableSimple,
    BThead,
    BTr,
    BTh,
    BTd,
    BTbody,
    BBadge,
    // BDropdown,
    // BDropdownItem,

    vSelect,
    ValidationProvider,
    ValidationObserver,
    // PdfView,
    BudgetExpense,
    VendorDeliveryInformation,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  props: {
    purchaseRequest: {
      type: Object,
      required: true,
    },
    sPurchaseOrder: {
      type: Object,
      required: true,
    },
    prActivityLogs: {
      type: Array,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: [],
    },
  },
  data() {
    return {
      canViewThisAction,
      pOrder: {},
      vendorOptions: [],
      user: store.state.auth.userData,
      vendorName: '',
      vendor: '',
      vendors: localStorage.getItem('vendors') ?? [],
      tabType: '',
      gst: 'No GST',
      purchaseRequestSignatories: [],
      poItems: [],
      purchaseRequestID: '',
      stringID: '',
      status: 'confirmed',
      statusValidation: false,
      statusError: 'Valid status is required',
      chunkItemRequests: [],
      chunksOfItemRequests: [],
      pdfTotalPage: 0,
      elHeight: 0,
      gapHeight: 0,
      statusDataOptions: [
        { title: 'Draft', code: 'draft' },
        { title: 'Pending Edit', code: 'pending edit' },
        { title: 'Pending Approval', code: 'pending approval' },
        { title: 'Verifying', code: 'verifying' },
        { title: 'Verified', code: 'verified' },
        { title: 'Cancelled', code: 'cancelled' },
      ],
      description: '',
      attachmentKey: '',
      vendorType: 1,
      files: [],
      attachments: [],
      required,
      gstOptions: [
        { text: 'No GST', value: 'No GST' },
        { text: 'GST Exclusive', value: 'GST Exclusive' },
        { text: 'GST Inclusive', value: 'GST Inclusive' },
      ],
      emailName: '',
      emailTo: '',
      emailMessage: '',
      // eslint-disable-next-line global-require
      defaultImage: require('@/assets/images/frontend/logo-nirvana-memorial-garden.86fe2ff5.svg'),
    }
  },
  computed: {
    expenseItems() {
      return store.state['pr-detail-store'].expenseItems
    },
    budgetExpense() {
      return store.state['pr-detail-store'].budgetExpense
    },
  },
  mounted() {
    this.$http.get(`purchase/purchase-order/purchase-request/${this.$route.params.id}/detail`)
      .then(response => {
        this.vendorOptions = response.data.vendorOptions ?? []
        this.pOrder = response.data.purchaseOrder || {}
        this.$emit('update:sPurchaseOrder', this.pOrder)
        this.poItems = response.data.poItems || []
        // eslint-disable-next-line no-plusplus
        // for (let i = 0; i < 4; i++) {
        //   this.poItems = this.poItems.concat(response.data.poItems)
        // }
        this.emailName = this.pOrder.salesPersonName
        this.emailTo = this.pOrder.companyEmail
        // this.setData()
      })
      .catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  },

  methods: {
    setAttachment(e) {
      if (e.target.files.length) {
        // const reader = new FileReader()
        // reader.readAsDataURL(this.file)
        // reader.onload = () => {
        //   e.target.filesrc = reader.result
        // }
        const acceptedTypes = ['image/jpeg', 'image/png', 'application/pdf']
        e.target.files.forEach(element => {
          if (acceptedTypes.includes(element.type)) {
            if (element.size > 20 * 1024 * 1024) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Can't add files more than 20mb",
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
            } else {
              const reader = new FileReader()
              reader.readAsDataURL(element)

              reader.onload = event => {
                const fileObj = {}
                fileObj.name = element.name
                fileObj.data = event.target.result
                fileObj.size = (element.size / (1024 * 1024)).toFixed(2)
                fileObj.type = element.type
                this.attachments.push(fileObj)
              }
            }
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Unsupported file type',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          }
        })
      }
    },
    uploadFile() {
      const formData = new FormData()
      formData.append('attachments', JSON.stringify(this.attachments))
      formData.append('purchaseRequest', this.$route.params.id)
      formData.append('caseID', this.purchaseRequest.caseID)
      this.$http.post('purchase/purchase-request/upload/new-file', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
        .then(response => {
          this.attachments = []
          this.$emit('update:purchaseRequest', response.data.data)
          this.$emit('update:prActivityLogs', response.data.data.activityLog.sort((a, b) => new Date(b.time) - new Date(a.time)))
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.data.message || '',
              icon: 'AlertTriangleIcon',
              variant: 'success',
            },
          })
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.data.message || '',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    changeVendorType(value) {
      this.vendorType = value
    },
    closeDescriptionModal() {
      this.$root.$emit('bv::hide::modal', 'modal-attachment-description', '')
    },
    resolveAcronymName(name) {
      return name.split(' ').map(item => item.charAt(0)).join('').toUpperCase()
    },
    getSubTotal(key) {
      const vendor = this.pOrder.comparedVendors[key]
      let total = 0
      if (vendor.code === 'GST Inclusive') {
        this.poItems.forEach(item => {
          total += item.unitCost[key] ? item.quantity * item.unitCost[key] : 0
        })
        total -= this.getGST(key)
      } else {
        this.poItems.forEach(item => {
          total += item.unitCost[key] ? item.quantity * item.unitCost[key] : 0
        })
      }

      const discount = this.pOrder.discountList[key] ? this.pOrder.discountList[key] : 0
      const dCost = this.pOrder.deliveryCost[key] ? this.pOrder.deliveryCost[key] : 0

      total = total - discount + parseFloat(dCost)
      return Number.isNaN(total) ? '0.00' : total.toFixed(2)
    },
    getGST(key) {
      const vendor = this.pOrder.comparedVendors[key]
      if (vendor.code === 'No GST') {
        return '-'
      }

      if (vendor.code === 'GST Inclusive') {
        let gstableAmount = 0
        this.poItems.forEach(item => {
          gstableAmount += (item.gst[key] && item.gst[key] === 'yes') ? item.quantity * (item.unitCost[key] ? item.unitCost[key] : 0) : 0
        })
        // eslint-disable-next-line no-mixed-operators
        const gstAmount = ((gstableAmount / (100 + parseFloat(this.pOrder.gstPercentage))) * parseFloat(this.pOrder.gstPercentage))
        return Number.isNaN(gstAmount) ? '0.00' : gstAmount.toFixed(2)
      }

      let total = 0

      this.poItems.forEach(item => {
        total += (item.gst[key] && item.gst[key] === 'yes') ? item.quantity * (item.unitCost[key] ? item.unitCost[key] : 0) : 0
      })

      // eslint-disable-next-line operator-assignment
      total = ((this.pOrder.gstPercentage / 100) * total)

      return Number.isNaN(total) ? '0.00' : total.toFixed(2)
    },
    getGrandTotal(key) {
      return ((this.getGST(key) === '-' ? 0 : parseFloat(this.getGST(key))) + parseFloat(this.getSubTotal(key))).toFixed(2)
    },
    updateDescription(key) {
      this.attachmentKey = key
      this.description = this.purchaseRequest.attachments[key].description
      this.$root.$emit('bv::show::modal', 'modal-attachment-description', '')
    },
    removeAttachment(url) {
      this.$http
        .post('purchase/purchase-request/remove-attachment', { url, purchaseRequest: this.$route.params.id })
        .then(response => {
          this.$emit('update:purchaseRequest', response.data.data)
          this.$emit('update:prActivityLogs', response.data.data.activityLog.sort((a, b) => new Date(b.time) - new Date(a.time)))
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.data.message || '',
              icon: 'AlertTriangleIcon',
              variant: 'success',
            },
          })
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.data.message || '',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    saveDescription() {
      const url = this.purchaseRequest.attachments[this.attachmentKey].data
      this.$http
        .post('purchase/purchase-request/save-description', { url, description: this.description, purchaseRequest: this.$route.params.id })
        .then(response => {
          this.$emit('update:purchaseRequest', response.data.data)
          this.closeDescriptionModal()
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.data.message || '',
              icon: 'AlertTriangleIcon',
              variant: 'success',
            },
          })
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.data.message || '',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    downloadPO() {
      const formData = new FormData()
      formData.append('url', `${window.location.origin}/purchase-request/download/po/${this.$route.params.id}`)
      formData.append('landscape', false)
      this.$http.post('purchase/pdf/download', formData, {
        headers: { Accept: 'application/pdf' },
        responseType: 'arraybuffer',
      })
        .then(response => {
          const blob = new Blob([response.data], { type: 'application/pdf' })
          const link = document.createElement('a')
          link.href = window.URL.createObjectURL(blob)
          link.download = `${this.purchaseRequest.caseID}.pdf`
          link.click()
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    async oldDownloadPO() {
      await this.calculateTotalPage()
      const vm = this
      const element = document.getElementById('pdfGenerateDiv')
      // element.style.fontFamily = 'DroidSans-Bold'
      element.style.letterSpacing = '0.5px'
      let height = 282.5
      if (this.pdfTotalPage === 1) {
        height = 297
      }
      if (this.pdfTotalPage === 2) {
        height = 284
      }
      // eslint-disable-next-line new-cap
      const pdf = new jsPDF('p', 'mm', [height, 210], true)
      // const pageWidth = pdf.internal.pageSize.width - (10 * 2)
      // pdf.setFont('DroidSans-Bold')
      pdf.setProperties({
        title: `${vm.purchaseRequest.caseID}`,
      })
      pdf.html(element, {
        margin: [5, 5, 5, 5],
        html2canvas: {
          scale: 0.25,
        },
        // width: pageWidth,
        // windowWidth: pageWidth,
        callback(pdfr) {
          pdfr.save(`${vm.purchaseRequest.caseID}.pdf`)
        },
        // x: 10,
        // y: 10,
        autoPaging: 'text',
        // fontFaces: [{
        //   family: 'Montserrat-Regular', weight: 400, stretch: 'normal', src: ['assets/fonts/Montserrat-Regular.ttf'],
        // }],
      })
    },
    sendEmailToVendor(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.$refs.emialToVendorForm.validate().then(async success => {
        if (success) {
          this.$refs['email-to-vendor-modal'].toggle('#toggle-btn')
          const formData = new FormData()
          formData.append('url', `${window.location.origin}/purchase-request/download/po/${this.$route.params.id}`)
          formData.append('landscape', false)

          formData.append('po', this.pOrder._id)
          formData.append('pr', this.$route.params.id)
          formData.append('emailTo', this.emailTo)
          formData.append('emailName', this.emailName)
          formData.append('emailMessage', this.emailMessage)
          formData.append('fileName', `${this.purchaseRequest.caseID}.pdf`)
          this.$http.post('purchase/purchase-request/send-email/po/new', formData, {
            headers: { Accept: 'application/pdf' },
            responseType: 'arraybuffer',
          })
            .then(response => {
              this.$swal({
                title: 'Email Sent',
                html: response.data.message || '',
                // eslint-disable-next-line global-require
                imageUrl: require('@/assets/images/icons/success.png'),
                imageWidth: 80,
                imageHeight: 80,
                imageAlt: 'Custom Icon',
                showCancelButton: false,
                cancelButtonText: '',
                allowOutsideClick: false,
                confirmButtonText: 'Ok',
                customClass: {
                  confirmButton: 'btn btn-primary mr-1',
                  cancelButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            })
            .catch(error => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
            })
        }
      })
    },
    oldSendEmailToVendor(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.$refs.emialToVendorForm.validate().then(async success => {
        if (success) {
          this.$refs['email-to-vendor-modal'].toggle('#toggle-btn')
          const vm = this
          await this.calculateTotalPage()
          const element = document.getElementById('pdfGenerateDiv')
          // element.style.fontFamily = 'DroidSans-Bold'
          element.style.letterSpacing = '0.5px'
          let height = 282.5
          if (this.pdfTotalPage === 1) {
            height = 297
          }
          if (this.pdfTotalPage === 2) {
            height = 284
          }
          // eslint-disable-next-line new-cap
          // const pdf = new jsPDF('p', 'pt', 'a4', true)
          // eslint-disable-next-line new-cap
          const pdf = new jsPDF('p', 'mm', [height, 210], true)
          // console.log(pdf.getFontList())
          // pdf.setFont('DroidSans-Bold')
          pdf.setProperties({
            title: `${vm.purchaseRequest.caseID}`,
          })
          pdf.html(element, {
            margin: [5, 5, 5, 5],
            html2canvas: {
              scale: 0.25,
            },
            // width: 1200,
            // windowWidth: 1200,
            callback(pdfr) {
              // pdfr.save(`${vm.purchaseRequest.caseID}.pdf`)
              const formData = new FormData()

              formData.append('po', vm.pOrder._id)
              formData.append('pr', vm.$route.params.id)
              formData.append('emailTo', vm.emailTo)
              formData.append('emailName', vm.emailName)
              formData.append('emailMessage', vm.emailMessage)
              formData.append('fileName', `${vm.purchaseRequest.caseID}.pdf`)
              formData.append('file', pdfr.output('datauristring'))
              vm.$http.post('purchase/purchase-request/send-email/po', formData, {
                headers: { 'Content-Type': 'multipart/form-data' },
              })
                .then(response => {
                  vm.$swal({
                    title: 'Email Sent',
                    html: response.data.message || '',
                    // eslint-disable-next-line global-require
                    imageUrl: require('@/assets/images/icons/success.png'),
                    imageWidth: 80,
                    imageHeight: 80,
                    imageAlt: 'Custom Icon',
                    showCancelButton: false,
                    cancelButtonText: '',
                    allowOutsideClick: false,
                    confirmButtonText: 'Ok',
                    customClass: {
                      confirmButton: 'btn btn-primary mr-1',
                      cancelButton: 'btn btn-primary',
                    },
                    buttonsStyling: false,
                  })
                })
                .catch(error => {
                  vm.$toast({
                    component: ToastificationContent,
                    props: {
                      title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                      icon: 'AlertTriangleIcon',
                      variant: 'danger',
                    },
                  })
                })
            },
            // x: 10,
            // y: 10,
            autoPaging: 'text',
            // fontFaces: [{
            //   family: 'Montserrat-Regular', weight: 400, stretch: 'normal', src: ['assets/fonts/Montserrat-Regular.ttf'],
            // }],
          })
        }
      })
    },
    async calculateTotalPage() {
      await this.$nextTick()
      this.chunksOfItemRequests = []
      this.chunkItemRequests = []
      this.pdfTotalPage = 0
      const logoHeight = document.getElementById('main-header').offsetHeight
      const vendorAndDeliveryDetailHeight = document.getElementById('vendorAndDeliveryDetail').offsetHeight
      const footerHeight = document.getElementById('footer-div').offsetHeight
      let contentHeight = 0
      let totalItems = this.poItems.length
      let i = 0
      let sliceStart = 0
      let sliceEnd = 12
      if (totalItems > 12) {
        sliceEnd = 15
      }
      while (totalItems > 0) {
        if (i === 0) {
          contentHeight = 1080 - (logoHeight + vendorAndDeliveryDetailHeight + footerHeight)
        } else {
          contentHeight = 1080 - (logoHeight + footerHeight)
        }
        this.chunkItemRequests = this.poItems.slice(sliceStart, sliceEnd)
        // eslint-disable-next-line no-await-in-loop
        await this.$nextTick()
        let tableHeight = document.getElementById('content-div').offsetHeight - vendorAndDeliveryDetailHeight
        if (tableHeight > contentHeight) {
          contentHeight += footerHeight
          contentHeight += 65
          // contentHeight += vendorAndDeliveryDetailHeight
        }
        while (tableHeight > contentHeight) {
          sliceEnd -= 1
          this.chunkItemRequests = this.poItems.slice(sliceStart, sliceEnd)
          // eslint-disable-next-line no-await-in-loop
          await this.$nextTick()
          tableHeight = document.getElementById('content-div').offsetHeight - vendorAndDeliveryDetailHeight - 65
        }
        this.chunksOfItemRequests.push(this.chunkItemRequests)
        this.pdfTotalPage += 1
        i += 1
        sliceStart = sliceEnd
        sliceEnd += 17
        totalItems -= this.chunkItemRequests.length
      }
      let remainingHeight = 0
      const dHeight = 1080
      let finalDataHeight = 0
      if (this.pdfTotalPage === 1) {
        finalDataHeight = document.getElementById('content-div').offsetHeight
      } else {
        finalDataHeight = document.getElementById('content-div').offsetHeight - vendorAndDeliveryDetailHeight
      }
      this.gapHeight = 0
      remainingHeight = dHeight - (logoHeight + footerHeight + finalDataHeight)
      if (remainingHeight > 0) {
        this.gapHeight = remainingHeight
      }
      if (finalDataHeight >= (dHeight - (logoHeight + footerHeight))) {
        this.pdfTotalPage += 1
        this.gapHeight = dHeight - (logoHeight + footerHeight)
      }
    },
    approvePO(id, index) {
      this.$swal({
        title: 'Sign this PO?',
        html: 'You are proceeding to sign on this PO. The Purchase Request will enter next step.',
        // eslint-disable-next-line global-require
        imageUrl: require('@/assets/images/icons/fly.png'),
        reverseButtons: true,
        showCancelButton: true,
        cancelButtonText: 'No, Cancel',
        confirmButtonText: 'Yes, Proceed!',
        customClass: {
          confirmButton: 'btn btn-primary ml-1',
          cancelButton: 'btn btn-outline-primary',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            const formData = new FormData()
            formData.append('id', id)
            formData.append('index', index)
            formData.append('signatoryRequired', this.pOrder.signatoryRequired)

            this.$http.post(`purchase/purchase-request/${this.$route.params.id}/approve-po/${this.pOrder._id}`, formData, {
              headers: { 'Content-Type': 'multipart/form-data' },
            })
              .then(response => {
                this.$emit('update:purchaseRequest', response.data.data)
                this.pOrder = response.data.po
                this.$emit('update:sPurchaseOrder', this.pOrder)
                this.$swal({
                  title: 'PO Signed',
                  html: response.data.message,
                  // eslint-disable-next-line global-require
                  imageUrl: require('@/assets/images/icons/success.png'),
                  imageWidth: 80,
                  imageHeight: 80,
                  imageAlt: 'Custom Icon',
                  showCancelButton: false,
                  confirmButtonText: 'Okay',
                  customClass: {
                    confirmButton: 'btn btn-primary',
                  },
                  buttonsStyling: false,
                })
              })
              .catch(error => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              })
          }
        })
    },
    submitForApproval() {
      this.$swal({
        title: 'Submit this PO?',
        html: `You are proceeding to sign this PO. The PO will then be sent to ${this.pOrder.signatories[1] ? this.pOrder.signatories[1].user.name : ''} for signing.`,
        // eslint-disable-next-line global-require
        imageUrl: require('@/assets/images/icons/warning.png'),
        reverseButtons: true,
        showCancelButton: true,
        cancelButtonText: 'No, Cancel',
        confirmButtonText: 'Yes, Proceed!',
        customClass: {
          confirmButton: 'btn btn-primary ml-1',
          cancelButton: 'btn btn-outline-primary',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            this.$http.post(`purchase/purchase-request/${this.$route.params.id}/purchase-order/${this.pOrder._id}/requester/submit`, {
              headers: { 'Content-Type': 'multipart/form-data' },
            })
              .then(response => {
                this.$emit('update:purchaseRequest', response.data.data)
                this.pOrder = response.data.po
                this.$emit('update:sPurchaseOrder', this.pOrder)
                this.$swal({
                  title: 'Submitted for Approval',
                  html: 'This po will be sent for approval. All relevant parties will receive a notification.',
                  // eslint-disable-next-line global-require
                  imageUrl: require('@/assets/images/icons/success.png'),
                  imageWidth: 80,
                  imageHeight: 80,
                  imageAlt: 'Custom Icon',
                  showCancelButton: false,
                  confirmButtonText: 'Okay',
                  customClass: {
                    confirmButton: 'btn btn-primary',
                  },
                  buttonsStyling: false,
                })
              })
              .catch(error => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              })
          }
        })
    },
    setData() {
      this.purchaseRequestID = this.purchaseRequest._id
      this.status = this.purchaseRequest.status
      this.stringID = this.purchaseRequest.caseID

      this.$http.get(`directory/users/${this.purchaseRequest.createdBy._id}/show`)
        .then(response => {
          this.user = response.data ?? {}
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    warningBeforeEdit() {
      this.$swal({
        title: 'Are You Sure?',
        html: 'Editing the form now will <strong>reset the approval process</strong> and require all signatories to confirm and sign once again.',
        // eslint-disable-next-line global-require
        imageUrl: require('@/assets/images/icons/warning.png'),
        reverseButtons: true,
        showCancelButton: true,
        cancelButtonText: 'No, Cancel',
        confirmButtonText: 'Yes, Edit this PO',
        customClass: {
          confirmButton: 'btn btn-primary ml-1',
          cancelButton: 'btn btn-outline-primary',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            this.$router.push({ name: 'purchasing-purchase-order-edit', params: { parent: this.$route.params.id, id: this.pOrder._id } })
          }
        })
    },

    convertToFile(data) {
      const arr = data.split(',')
      const mime = arr[0].match(/:(.*?);/)[1]
      const bstr = atob(arr[1])
      let n = bstr.length
      const u8arr = new Uint8Array(n)

      // eslint-disable-next-line no-plusplus
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
      }

      return new File([u8arr], 'avatar', { type: mime })
    },

    submitForm() {
      if (this.vendorType === 1) {
        this.$refs.existingVendorForm.validate().then(success => {
          if (success) {
            const selected = this.vendorOptions.find(o => o._id === this.vendor)
            const vendor = {
              id: this.vendor, name: selected.companyName, code: this.gst, type: 1,
            }
            // this.vendors.push(purchase)
            localStorage.setItem('vendor', JSON.stringify(vendor))
            this.$router.push({ name: 'purchasing-quotations-create', params: { id: this.$route.params.id } })
          }
        })
      } else {
        this.$refs.newVendorForm.validate().then(success => {
          if (success) {
            const vendor = {
              id: null, name: this.vendorName, code: this.gst, type: 2,
            }
            // this.vendors.push(purchase)
            localStorage.setItem('vendor', JSON.stringify(vendor))
            this.$router.push({ name: 'purchasing-quotations-create', params: { id: this.$route.params.id } })
          }
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
