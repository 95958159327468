<template>

  <div>
    <!-- Table Container Card -->
    <b-card
      no-body
    >
      <b-row
        class="content-header"
      >
        <b-col
          class="content-header-left mb-2"
          cols="12"
          md="12"
        >
          <b-row class="breadcrumbs-top">
            <b-col cols="6">
              <h1 class="custom-header-title">
                {{ purchaseRequest.prID }}
              </h1>
              <p class="log_info">
                Last edited by {{ purchaseRequestForm.updatedBy ? purchaseRequestForm.updatedBy.name : '' }} on {{ dateFormatWithTime(purchaseRequestForm.updatedAt) }}
              </p>
            </b-col>
            <b-col
              class="text-right justify-content-end align-items-right ml-auto"
              cols="6"
            >
              <div class="demo-inline-spacing">
                <b-button
                  v-if="purchaseRequestForm.status == 'draft' || purchaseRequestForm.status == 'pending edit' || (purchaseRequestForm.status == 'pending signatories' && purchaseRequestForm.createdBySign == false)"
                  v-b-tooltip.hover
                  :title="`Pending Submission by ${purchaseRequest.createdBy ? purchaseRequest.createdBy.name : ''}`"
                  variant="flat-primary"
                  class="btn-icon"
                >
                  <feather-icon
                    icon="FeatherIcon"
                    size="18"
                  />
                </b-button>
                <b-button
                  v-else
                  v-b-tooltip.hover
                  :title="`Signed by ${purchaseRequest.createdBy ? purchaseRequest.createdBy.name : ''}`"
                  variant="success"
                  class="btn-icon"
                >
                  <feather-icon
                    icon="CheckIcon"
                    size="18"
                  />
                </b-button>
                <b-button
                  v-if="purchaseRequestForm.confirmedBySign == false"
                  v-b-tooltip.hover
                  :title="`Pending Approval by ${purchaseRequestForm.confirmedBy ? purchaseRequestForm.confirmedBy.name : ''}`"
                  variant="flat-primary"
                  class="btn-icon"
                >
                  <feather-icon
                    icon="FeatherIcon"
                    size="18"
                  />
                </b-button>
                <b-button
                  v-else
                  v-b-tooltip.hover
                  :title="`Signed by ${purchaseRequestForm.confirmedBy ? purchaseRequestForm.confirmedBy.name : ''}`"
                  variant="success"
                  class="btn-icon"
                >
                  <feather-icon
                    icon="CheckIcon"
                    size="18"
                  />
                </b-button>
                <b-button
                  v-if="purchaseRequestForm.approvedBySign == false"
                  v-b-tooltip.hover
                  :title="`Pending Approval by ${purchaseRequestForm.approvedBy ? purchaseRequestForm.approvedBy.name : ''}`"
                  variant="flat-primary"
                  class="btn-icon"
                >
                  <feather-icon
                    icon="FeatherIcon"
                    size="18"
                  />
                </b-button>
                <b-button
                  v-else
                  v-b-tooltip.hover
                  :title="`Signed by ${purchaseRequestForm.approvedBy ? purchaseRequestForm.approvedBy.name : ''}`"
                  variant="success"
                  class="btn-icon"
                >
                  <feather-icon
                    icon="CheckIcon"
                    size="18"
                  />
                </b-button>
                <b-button
                  v-if="purchaseRequestForm.assignedToSign == false"
                  v-b-tooltip.hover
                  :title="`Pending Verification by ${purchaseRequestForm.assignedTo ? purchaseRequestForm.assignedTo.name : ''}`"
                  variant="flat-primary"
                  class="btn-icon"
                >
                  <feather-icon
                    icon="FeatherIcon"
                    size="18"
                  />
                </b-button>
                <b-button
                  v-else
                  v-b-tooltip.hover
                  :title="`Signed by ${purchaseRequestForm.assignedTo ? purchaseRequestForm.assignedTo.name : ''}`"
                  variant="success"
                  class="btn-icon"
                >
                  <feather-icon
                    icon="CheckIcon"
                    size="18"
                  />
                </b-button>
              </div>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <div class="pur_con_header">
        <b-row
          class="content-header"
        >
          <!-- Content Left -->
          <b-col
            class="content-header-left mb-2"
            cols="12"
            md="6"
          >
            <b-button
              variant="primary"
              class="mr-1 md-mb-1"
              :disabled="purchaseRequest.status == 'cancelled'"
              @click="$refs.hiddenFileInput.click()"
            >
              Upload File
            </b-button>
            <b-button
              v-if="attachments.length"
              variant="success"
              class="mr-1 md-mb-1"
              @click="uploadFile"
            >
              Save File
            </b-button>
            <input
              ref="hiddenFileInput"
              type="file"
              multiple
              capture
              accept="image/jpeg, image/png, application/pdf"
              class="d-none"
              @change="setAttachment"
            >
            <b-button
              variant="primary"
              class="mr-1 md-mb-1"
              :disabled="purchaseRequest.status == 'cancelled' || ['in-order', 'in-delivery'].includes(purchaseRequest.stage) || !(canViewThisAction('update', 'PurchaseRequest') || (canViewThisAction('my-request-update', 'MyPurchaseRequest') && (purchaseRequest.createdBy ? purchaseRequest.createdBy._id : 0) == user._id) || (canViewThisAction('my-department-request-edit', 'MyPurchaseRequest') && departmentPermission))"
              @click="warningBeforeEdit()"
            >
              Edit
            </b-button>
            <b-button
              variant="primary"
              class="md-mb-1"
              :disabled="purchaseRequest.stage == 'in-request' || (!canViewThisAction('download-prf', 'PurchaseRequest') && !canViewThisAction('my-request-download-prf', 'MyPurchaseRequest'))"
              @click="downloadPRF"
            >
              <feather-icon
                icon="DownloadIcon"
                size="14"
              />
            </b-button>
            <!-- <b-dropdown
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              class=""
              right
              no-caret
              size="sm"
              variant="primary"
            >
              <template #button-content>
                <feather-icon
                  icon="DownloadIcon"
                  size="21"
                  class="align-middle"
                />
              </template>
              <b-dropdown-item>View History</b-dropdown-item>
              <b-dropdown-item
                :disabled="purchaseRequest.stage == 'in-request' || !canViewThisAction('download-prf', 'PurchaseRequest')"
                @click="downloadPRF"
              >
                Print
              </b-dropdown-item>
            </b-dropdown> -->
          </b-col>
          <b-col
            class="content-header-left mb-2"
            cols="12"
            md="6"
          >
            <b-button
              v-if="purchaseRequest.status != 'cancelled' && user._id == (purchaseRequest.createdBy ? purchaseRequest.createdBy._id : '') && purchaseRequestForm.status == 'draft'"
              variant="success"
              :to="{ name: 'purchasing-purchase-requests-prf-preview', params: { id: $route.params.id } }"
            >
              <feather-icon
                icon="CheckIcon"
                class="mr-50"
                size="18"
              />
              <span class="align-middle">
                Submit For Approval
              </span>
            </b-button>
            <b-button
              v-if="purchaseRequest.status != 'cancelled' && user._id == (purchaseRequest.createdBy ? purchaseRequest.createdBy._id : '') && (purchaseRequestForm.status == 'pending edit' || (purchaseRequestForm.status == 'pending signatories' && purchaseRequestForm.createdBySign == false))"
              variant="success"
              :to="{ name: 'purchasing-purchase-requests-prf-preview', params: { id: $route.params.id } }"
            >
              <feather-icon
                icon="CheckIcon"
                class="mr-50"
                size="18"
              />
              <span class="align-middle">
                Preview PRF and Submit for Approval
              </span>
            </b-button>
            <b-button
              v-if="purchaseRequest.status != 'cancelled' && purchaseRequestForm.createdBySign == true && purchaseRequestForm.confirmedBySign != true && purchaseRequestForm.status == 'pending approval' && user._id == (purchaseRequestForm.confirmedBy ? purchaseRequestForm.confirmedBy._id : '')"
              v-b-modal.modal-send-back
              variant="primary"
              :disabled="purchaseRequestForm.status != 'pending approval' || purchaseRequestForm.confirmedBySign == true"
              class="mr-50"
            >
              <feather-icon
                icon="SendIcon"
                class="mr-50"
                size="18"
              />
              <span class="align-middle">
                Send Back to Requester
              </span>
            </b-button>
            <b-button
              v-if="purchaseRequest.status != 'cancelled' && purchaseRequestForm.createdBySign == true && purchaseRequestForm.confirmedBySign != true && purchaseRequestForm.status == 'pending approval' && user._id == (purchaseRequestForm.confirmedBy ? purchaseRequestForm.confirmedBy._id : '')"
              variant="success"
              :disabled="purchaseRequestForm.status != 'pending approval' || purchaseRequestForm.confirmedBySign == true"
              :to="{ name: 'purchasing-purchase-requests-prf-preview', params: { id: $route.params.id }, query: { type: 'confirm' } }"
            >
              <feather-icon
                icon="CheckIcon"
                class="mr-50"
                size="18"
              />
              <span class="align-middle">
                Approve PRF
              </span>
            </b-button>
            <b-button
              v-if="purchaseRequest.status != 'cancelled' && purchaseRequestForm.confirmedBySign == true && purchaseRequestForm.approvedBySign != true && user._id == (purchaseRequestForm.approvedBy ? purchaseRequestForm.approvedBy._id : '')"
              v-b-modal.modal-send-back
              variant="primary"
              :disabled="purchaseRequestForm.confirmedBySign == false || purchaseRequestForm.approvedBySign == true"
              class="mr-50"
            >
              <feather-icon
                icon="SendIcon"
                class="mr-50"
                size="18"
              />
              <span class="align-middle">
                Send Back to Requester
              </span>
            </b-button>
            <b-button
              v-if="purchaseRequest.status != 'cancelled' && expenseItems.length && purchaseRequestForm.confirmedBySign == true && purchaseRequestForm.approvedBySign != true && user._id == (purchaseRequestForm.approvedBy ? purchaseRequestForm.approvedBy._id : '')"
              variant="success"
              :disabled="purchaseRequestForm.confirmedBySign == false || purchaseRequestForm.approvedBySign == true"
              :to="{ name: 'purchasing-purchase-requests-prf-preview', params: { id: $route.params.id }, query: { type: 'approve' } }"
            >
              <feather-icon
                icon="CheckIcon"
                class="mr-50"
                size="18"
              />
              <span class="align-middle">
                Approve PRF
              </span>
            </b-button>
            <b-button
              v-if="purchaseRequest.status != 'cancelled' && !expenseItems.length && canViewThisAction('approve-pr-without-budget', 'BudgetAllocation') && purchaseRequestForm.confirmedBySign == true && purchaseRequestForm.approvedBySign != true && user._id == (purchaseRequestForm.approvedBy ? purchaseRequestForm.approvedBy._id : '')"
              variant="success"
              :disabled="purchaseRequestForm.confirmedBySign == false || purchaseRequestForm.approvedBySign == true"
              :to="{ name: 'purchasing-purchase-requests-prf-preview', params: { id: $route.params.id }, query: { type: 'approve' } }"
            >
              <feather-icon
                icon="CheckIcon"
                class="mr-50"
                size="18"
              />
              <span class="align-middle">
                Approve PRF
              </span>
            </b-button>
            <b-button
              v-else-if="purchaseRequest.status != 'cancelled' && !expenseItems.length && purchaseRequestForm.confirmedBySign == true && purchaseRequestForm.approvedBySign != true && user._id == (purchaseRequestForm.approvedBy ? purchaseRequestForm.approvedBy._id : '')"
              v-b-tooltip.hover
              variant="success"
              title="Budget Allocation is mandatory before approving PRF "
              :disabled="purchaseRequestForm.confirmedBySign == false || purchaseRequestForm.approvedBySign == true"
              @click="budgetAllocationWarning()"
            >
              <feather-icon
                icon="CheckIcon"
                class="mr-50"
                size="18"
              />
              <span class="align-middle">
                Approve PRF
              </span>
            </b-button>
            <b-button
              v-if="purchaseRequest.status != 'cancelled' && purchaseRequestForm.approvedBySign == true && purchaseRequestForm.assignedToSign != true && user._id == (purchaseRequestForm.assignedTo ? purchaseRequestForm.assignedTo._id : '')"
              v-b-modal.modal-send-back
              variant="primary"
              :disabled="purchaseRequestForm.approvedBySign == false || purchaseRequestForm.assignedToSign == true"
              class="mr-50"
            >
              <feather-icon
                icon="SendIcon"
                class="mr-50"
                size="18"
              />
              <span class="align-middle">
                Send Back to Requester
              </span>
            </b-button>
            <b-button
              v-if="purchaseRequest.status != 'cancelled' && purchaseRequestForm.approvedBySign == true && purchaseRequestForm.assignedToSign != true && user._id == (purchaseRequestForm.assignedTo ? purchaseRequestForm.assignedTo._id : '')"
              variant="success"
              :disabled="purchaseRequestForm.approvedBySign == false || purchaseRequestForm.assignedToSign == true"
              :to="{ name: 'purchasing-purchase-requests-prf-preview', params: { id: $route.params.id }, query: { type: 'verify' } }"
            >
              <feather-icon
                icon="CheckIcon"
                class="mr-50"
                size="18"
              />
              <span class="align-middle">
                Verify PRF
              </span>
            </b-button>
          </b-col>
        </b-row>
      </div>
      <div class="pur_con_body">
        <b-table-lite
          responsive
          :items="purchaseRequestForm ? purchaseRequestForm.items : []"
          :fields="['no', { key: 'itemsMaster', label: 'description' }, { key: 'inventoryID', label: 'Stock balance' }, { key: 'quantity', label: 'QTY' },
                    { key: 'unit', label: 'Unit' }, { key: 'totalCost', label: 'Est. total cost (S$)' }]"
        >
          <template #cell(no)="data">
            {{ data.index + 1 }}
          </template>
          <template #cell(itemsMaster)="data">
            <b-card-text class="font-weight-bold mb-25">
              {{ data.item.itemsMaster }}
            </b-card-text>
            <b-card-text class="text-pre">{{ data.item.description }}
            </b-card-text>
          </template>
          <template #cell(inventoryID)="data">
            <div
              v-if="data.item.stocks.length"
            >
              <template
                v-for="(stock, key) in data.item.stocks"
              >
                <p
                  v-if="stock.store.status == 'active'"
                  :key="key"
                  class="mb-0"
                >
                  {{ stock.stock }} - {{ stock.store.name }}
                </p>
              </template>
            </div>
            <div v-else>
              -
            </div>
          </template>
          <template #cell(totalCost)="data">
            {{ numberFormat(data.item.totalCost) }}
          </template>
        </b-table-lite>
        <hr
          v-if="purchaseRequest.attachments && purchaseRequest.attachments.length"
          class="dividerHR"
        >
        <div
          v-if="purchaseRequest.attachments && purchaseRequest.attachments.length"
          class="attach__block"
        >
          <b-row
            class="content-header"
          >

            <!-- Content Left -->
            <b-col
              class="content-header-left"
              cols="12"
              md="12"
            >
              <h3>Attachments</h3>
            </b-col>
          </b-row>
          <b-row>
            <!-- Content Left -->
            <b-col
              v-for="(file, key) in purchaseRequest.attachments"
              :key="key"
              class="content-header-left mb-2"
              cols="12"
              md="4"
            >
              <div
                class="attachment-item"
              >
                <b-img
                  v-if="file.type.includes('image')"
                  left
                  height="40"
                  :src="require('@/assets/images/admin/doc_jpg.png')"
                  alt="Left image"
                />
                <b-img
                  v-if="file.type.includes('application')"
                  left
                  height="40"
                  :src="require('@/assets/images/admin/doc_file.png')"
                  alt="Left image"
                />
                <strong>
                  <b-link
                    :href="file ? file.data : ''"
                    target="_blank"
                  >
                    {{ file ? file.name : '' }}
                  </b-link>
                </strong>
                <span>{{ file ? `${file.size}mb` : '' }}</span>
                <span class="remove__doc">
                  <b-button
                    v-if="purchaseRequest.status != 'cancelled'"
                    variant="flat-primary"
                    @click="removeAttachment(file.data)"
                  >
                    <feather-icon
                      icon="XIcon"
                      class="mr-50"
                      size="18"
                    />
                  </b-button>
                </span>
              </div>
              <div class="doc__desc">
                <span v-if="file.description">
                  {{ file.description }}
                </span>
                <span v-else>
                  <button
                    v-if="purchaseRequest.status != 'cancelled'"
                    @click="updateDescription(key)"
                  >
                    Add a description
                  </button>
                </span>
                <span
                  v-if="file.description"
                  class="edit__desc"
                >
                  <b-button
                    v-if="purchaseRequest.status != 'cancelled'"
                    variant="flat-primary"
                    @click="updateDescription(key)"
                  >
                    <feather-icon
                      icon="EditIcon"
                      class="mr-50"
                      size="18"
                    />
                  </b-button>
                </span>
              </div>
            </b-col>
          </b-row>
        </div>
      </div>
    </b-card>
    <b-card
      header-tag="header"
      no-body
      class="budget-expense-card"
    >
      <template #header>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          type="button"
          variant="flat-primary"
          class="primary-button-with-background mr-3 header-icon"
        >
          <feather-icon
            icon="DollarSignIcon"
            class=""
            size="20"
          />
        </b-button>
        <h3 class="align-middle mr-2">
          Budget
        </h3>
        <b-badge
          v-if="purchaseRequest.budgetStatus"
          :variant="`light-${purchaseRequest.budgetStatus == 'allocated' ? 'success' : (purchaseRequest.budgetStatus == 'not-allocated' ? 'warning' : 'danger')}`"
          class="text-capitalize-first"
        >
          {{ purchaseRequest.budgetStatus }}
        </b-badge>
        <b-button
          v-if="purchaseRequest.status != 'cancelled' && expenseItems.length && (canViewThisAction('update', 'BudgetAllocation') || canViewThisAction('edit-budget-all-stages', 'BudgetAllocation'))"
          variant="flat-primary"
          class="ml-auto"
          :to="{ name: 'purchasing-purchase-request-update-allocated-budget', params: { parent: $route.params.id, id: budgetExpense._id }, query: { type: purchaseRequest.requestType } }"
        >
          <feather-icon
            icon="EditIcon"
            class="mr-50"
            size="16"
          />
          <span class="align-middle">Edit Budget</span>
        </b-button>
      </template>
      <div
        v-if="expenseItems.length"
      >
        <budget-expense />
      </div>
      <div
        v-else
        class="row"
      >
        <b-col>
          <div class="empty_block">
            <h4>Budget not allocated yet.</h4>
            <b-button
              v-if="(purchaseRequest.stage == 'in-request' && purchaseRequest.status != 'cancelled' && canViewThisAction('create', 'BudgetAllocation')) || (purchaseRequest.status != 'cancelled' && canViewThisAction('allocate-budget-all-stages', 'BudgetAllocation'))"
              type="button"
              variant="flat-primary"
              :disabled="!purchaseRequest.requestType"
              :to="{ name: 'purchasing-purchase-request-allocate-budget', params: { id: $route.params.id }, query: { type: purchaseRequest.requestType, department: (purchaseRequest.createdBy ? (purchaseRequest.createdBy.department.length ? purchaseRequest.createdBy.department[0]._id : '') : '') } }"
            >
              <span class="align-middle">Allocate Budget</span>
            </b-button>
          </div>
        </b-col>
      </div>
    </b-card>
    <div class="signatories_block">
      <b-row>
        <!-- Col: Sales Persion -->
        <b-col
          cols="3"
          md="3"
          class="mt-md-0 mt-3"
          order="2"
          order-md="1"
        >
          <b-card-text class="mb-0">
            <span class="font-weight-bold">1. Requested By</span>
            <div class="sign-box">
              <div v-if="purchaseRequest.createdBy">
                <b-avatar
                  v-if="purchaseRequest.createdBy.image"
                  :src="purchaseRequest.createdBy.image"
                />
                <b-avatar
                  v-else
                  :text="resolveAcronymName(purchaseRequest.createdBy.name)"
                />
              </div>
              <div v-if="purchaseRequestForm && purchaseRequestForm.createdBySign == true">
                <!-- <div v-if="purchaseRequest.createdBy && purchaseRequest.createdBy.signature">
                  <b-img
                    class="mb-1 mb-sm-0 user__avatar"
                    height="50"
                    :src="purchaseRequest.createdBy.signature"
                  />
                </div> -->
                <div v-if="purchaseRequest.createdBy && purchaseRequest.createdBy.name">
                  <span>{{ purchaseRequest.createdBy.name }}</span>
                </div>
              </div>
              <div v-else>
                <p>Pending Signature</p>
              </div>
            </div>
            <p class="ml-75 semi-bold">
              {{ purchaseRequest.createdBy ? purchaseRequest.createdBy.name : '' }}
            </p>
            <p class="ml-75">
              {{ purchaseRequest.department ? purchaseRequest.department.name : '' }}
            </p>
            <p class="ml-75">
              {{ purchaseRequestForm ? (purchaseRequestForm.createdBySign == true ? dateFormatWithTime(purchaseRequestForm.createdBySignDate) : '-') : '-' }}
            </p>
          </b-card-text>
        </b-col>
        <b-col
          cols="3"
          md="3"
          class="mt-md-0 mt-3"
          order="2"
          order-md="1"
        >
          <b-card-text class="mb-0">
            <span class="font-weight-bold">2. Confirmed By</span>
            <div class="sign-box">
              <div v-if="purchaseRequestForm && purchaseRequestForm.confirmedBy">
                <b-avatar
                  v-if="purchaseRequestForm.confirmedBy.image"
                  :src="purchaseRequestForm.confirmedBy.image"
                />
                <b-avatar
                  v-else
                  :text="resolveAcronymName(purchaseRequestForm.confirmedBy.name)"
                />
              </div>
              <div v-if="purchaseRequestForm && purchaseRequestForm.confirmedBySign == true">
                <!-- <div v-if="purchaseRequestForm.confirmedBy && purchaseRequestForm.confirmedBy.signature">
                  <b-img
                    class="mb-1 mb-sm-0 user__avatar"
                    height="50"
                    :src="purchaseRequestForm.confirmedBy.signature"
                  />
                </div> -->
                <div v-if="purchaseRequestForm.confirmedBy && purchaseRequestForm.confirmedBy.name">
                  <span>{{ purchaseRequestForm.confirmedBy.name }}</span>
                </div>
              </div>
              <div v-else>
                <p>Pending Signature</p>
              </div>
            </div>
            <p class="ml-75 semi-bold">
              {{ purchaseRequestForm ? (purchaseRequestForm.confirmedBy ? purchaseRequestForm.confirmedBy.name : '') : '' }}
            </p>
            <p class="ml-75">
              {{ purchaseRequestForm ? (purchaseRequestForm.confirmedBy ? (purchaseRequestForm.confirmedBy.department.length ? purchaseRequestForm.confirmedBy.department[0].name : '') : '') : '' }}
            </p>
            <p class="ml-75">
              {{ purchaseRequestForm ? (purchaseRequestForm.confirmedBySign == true ? dateFormatWithTime(purchaseRequestForm.confirmedBySignDate) : '-') : '-' }}
            </p>
          </b-card-text>
        </b-col>
        <b-col
          cols="3"
          md="3"
          class="mt-md-0 mt-3"
          order="2"
          order-md="1"
        >
          <b-card-text class="mb-0">
            <span class="font-weight-bold">3. Approved By</span>
            <div class="sign-box">
              <div v-if="purchaseRequestForm && purchaseRequestForm.approvedBy">
                <b-avatar
                  v-if="purchaseRequestForm.approvedBy.image"
                  :src="purchaseRequestForm.approvedBy.image"
                />
                <b-avatar
                  v-else
                  :text="resolveAcronymName(purchaseRequestForm.approvedBy.name)"
                />
              </div>
              <div v-if="purchaseRequestForm && purchaseRequestForm.approvedBySign == true">
                <!-- <div v-if="purchaseRequestForm.approvedBy && purchaseRequestForm.approvedBy.signature">
                  <b-img
                    class="mb-1 mb-sm-0 user__avatar"
                    height="50"
                    :src="purchaseRequestForm.approvedBy.signature"
                  />
                </div> -->
                <div v-if="purchaseRequestForm.approvedBy && purchaseRequestForm.approvedBy.name">
                  <span>{{ purchaseRequestForm.approvedBy.name }}</span>
                </div>
              </div>
              <div v-else>
                <p>Pending Signature</p>
              </div>
            </div>
            <p class="ml-75 semi-bold">
              {{ purchaseRequestForm ? (purchaseRequestForm.approvedBy ? purchaseRequestForm.approvedBy.name : '') : '' }}
            </p>
            <p class="ml-75">
              {{ purchaseRequestForm ? (purchaseRequestForm.approvedBy ? (purchaseRequestForm.approvedBy.department.length ? purchaseRequestForm.approvedBy.department[0].name : '') : '') : '' }}
            </p>
            <p class="ml-75">
              {{ purchaseRequestForm ? (purchaseRequestForm.approvedBySign == true ? dateFormatWithTime(purchaseRequestForm.approvedBySignDate) : '-') : '-' }}
            </p>
          </b-card-text>
        </b-col>
        <b-col
          cols="3"
          md="3"
          class="mt-md-0 mt-3"
          order="2"
          order-md="1"
        >
          <b-card-text class="mb-0">
            <span class="font-weight-bold">4. Verified By</span>
            <div class="sign-box">
              <div v-if="purchaseRequestForm && purchaseRequestForm.assignedTo">
                <b-avatar
                  v-if="purchaseRequestForm.assignedTo.image"
                  :src="purchaseRequestForm.assignedTo.image"
                />
                <b-avatar
                  v-else
                  :text="resolveAcronymName(purchaseRequestForm.assignedTo.name)"
                />
              </div>
              <div v-if="purchaseRequestForm && purchaseRequestForm.assignedToSign == true">
                <!-- <div v-if="purchaseRequestForm.assignedTo && purchaseRequestForm.assignedTo.signature">
                  <b-img
                    class="mb-1 mb-sm-0 user__avatar"
                    height="50"
                    :src="purchaseRequestForm.assignedTo.signature"
                  />
                </div> -->
                <div v-if="purchaseRequestForm.assignedTo && purchaseRequestForm.assignedTo.name">
                  <span>{{ purchaseRequestForm.assignedTo.name }}</span>
                </div>
              </div>
              <div v-else>
                <p>Pending Signature</p>
              </div>
            </div>
            <p class="ml-75 semi-bold">
              {{ purchaseRequestForm ? (purchaseRequestForm.assignedTo ? purchaseRequestForm.assignedTo.name : '') : '' }}
            </p>
            <p class="ml-75">
              {{ purchaseRequestForm ? (purchaseRequestForm.assignedTo ? (purchaseRequestForm.assignedTo.department.length ? purchaseRequestForm.assignedTo.department[0].name : '') : '') : '' }}
            </p>
            <p class="ml-75">
              {{ purchaseRequestForm ? (purchaseRequestForm.assignedToSign == true ? dateFormatWithTime(purchaseRequestForm.assignedToSignDate) : '-') : '-' }}
            </p>
          </b-card-text>
        </b-col>
      </b-row>
    </div>
    <!-- <pdf-view
      ref="pdfGenerateComponentPRF"
      :purchase-request="purchaseRequest"
      :purchase-request-form="purchaseRequestForm"
      :purchase-request-items="purchaseRequestItems"
      :pdf-total-page="pdfTotalPage"
      :chunks-of-item-requests="chunksOfItemRequests"
      :chunk-item-requests="chunkItemRequests"
      :gap-height="gapHeight"
    /> -->
    <b-modal
      id="modal-attachment-description"
      size="lg"
      title="Attachment Description"
      modal-class="status-update-modal"
      centered
      no-stacking
      header-class="front-modal"
      @ok="saveDescription"
      @cancel="closeDescriptionModal"
    >
      <b-row>
        <b-col md="12">
          <b-form-textarea
            id="h-customer-phone-number"
            v-model="description"
            placeholder="E.g. Please remind to make payment"
          />
        </b-col>
      </b-row>
      <template #modal-footer="{ ok, cancel }">
        <b-button
          variant="flat-primary"
          @click="cancel()"
        >
          Cancel
        </b-button>
        <b-button
          variant="primary"
          :disabled="!description || attachmentKey === ''"
          @click="ok()"
        >
          <span class="align-middle">Save</span>
        </b-button>
      </template>
    </b-modal>
    <b-modal
      id="modal-send-back"
      ref="modal-send-back"
      size="lg"
      cancel-variant="flat-primary"
      modal-class="status-update-modal"
      ok-variant="success"
      ok-title="Send and Update PR to “Pending Edit”"
      cancel-title="Cancel"
      centered
      no-close-on-backdrop
      title="Send Back to Requester"
      @ok="sendBackToRequester"
    >
      <b-form @submit.prevent="sendBackToRequester">
        <validation-observer
          ref="sendBackToRequesterForm"
        >
          <b-form-group
            label-for="h-purchaseRequest-cpUser"
          >
            <validation-provider
              #default="{ errors }"
              name="remark"
              vid="status"
              rules="required"
            >
              <b-form-textarea
                id="h-purchaseRequest-cpUser"
                v-model="comment"
                class="mb-25"
                trim
                placeholder="Share with this requester what edits are required, and why. This remark will be added to the comments and is visible to all users who have access to this Purchase Request."
              />
              <small class="text-danger">{{ errors[0] }}</small>
              <p>By sending this PR back to requester, all signatures will be cleared and this PR will need to be re-approved.</p>
            </validation-provider>
          </b-form-group>
        </validation-observer>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard, BRow, BButton, BCol, BImg, BFormTextarea, BLink, BTableLite, BCardText, BAvatar, VBTooltip, BFormGroup, BForm, BBadge,
} from 'bootstrap-vue'
import { required } from '@validations'
import Ripple from 'vue-ripple-directive'
import { useUtils as useAclUtils } from '@/libs/acl/custom'
import store from '@/store/index'
import { jsPDF } from 'jspdf'

// import '../../../../assets/js/Montserrat-Bold-normal'
// import '../../../../assets/js/Montserrat-Regular-normal'
// eslint-disable-next-line no-unused-vars
import '../../../../assets/js/DroidSans-Regular-normal'
// eslint-disable-next-line no-unused-vars
import '../../../../assets/js/DroidSans-Bold-normal'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// import PdfView from './PDFView.vue'
import BudgetExpense from '../BudgetExpense.vue'

const { canViewThisAction } = useAclUtils()

export default {
  components: {
    BCard,
    BRow,
    BButton,
    BCol,
    BImg,
    BFormTextarea,
    BLink,
    BTableLite,
    BCardText,
    BAvatar,
    BFormGroup,
    BForm,
    BBadge,
    // BDropdown,
    // BDropdownItem,

    ValidationProvider,
    ValidationObserver,
    BudgetExpense,
    // PdfView,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  props: {
    purchaseRequest: {
      type: Object,
      required: true,
    },
    purchaseRequestForm: {
      type: Object,
      required: true,
    },
    prActivityLogs: {
      type: Array,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: [],
    },
  },
  data() {
    return {
      canViewThisAction,
      user: store.state.auth.userData,
      // expenseItems: store.state['pr-detail-store'].expenseItems,
      tabType: '',
      purchaseRequestSignatories: [],
      purchaseRequestItems: [],
      purchaseRequestID: '',
      stringID: '',
      comment: '',
      commentValidation: false,
      commentError: '',
      status: 'confirmed',
      statusValidation: false,
      statusError: 'Valid status is required',
      statusDataOptions: [
        { title: 'Draft', code: 'draft' },
        { title: 'Pending Edit', code: 'pending edit' },
        { title: 'Pending Approval', code: 'pending approval' },
        { title: 'Verifying', code: 'verifying' },
        { title: 'Verified', code: 'verified' },
        { title: 'Cancelled', code: 'cancelled' },
      ],
      description: '',
      attachmentKey: '',
      required,
      lqas: [
        '1. Prepared by',
        '2. Requested by',
        '3. Verified by',
        '4. Recommended by',
        '5. Agreed by',
        '6. Certified by',
        '7. Confirmed by',
        '8. Supported by',
        '9. Validated by',
        '10. Approved by',
      ],
      files: [],
      attachments: [],
      chunkItemRequests: [],
      chunksOfItemRequests: [],
      pdfTotalPage: 0,
      elHeight: 0,
      gapHeight: 0,
      departmentPermission: false,
      // eslint-disable-next-line global-require
      defaultImage: require('@/assets/images/frontend/logo-nirvana-memorial-garden.86fe2ff5.svg'),
    }
  },
  computed: {
    expenseItems() {
      return store.state['pr-detail-store'].expenseItems
    },
    budgetExpense() {
      return store.state['pr-detail-store'].budgetExpense
    },
  },
  // mounted() {
  //   this.$http.get(`purchase/purchase-request/${this.$route.params.id}/show`)
  //     .then(response => {
  //       this.purchaseRequest = response.data.data || {}
  //       this.purchaseRequestSignatories = response.data.purchaseRequestSignatories || []
  //       this.purchaseRequestItems = response.data.purchaseRequestItems || []

  //       this.setData()
  //     })
  //     .catch(error => {
  //       this.$toast({
  //         component: ToastificationContent,
  //         props: {
  //           title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
  //           icon: 'AlertTriangleIcon',
  //           variant: 'danger',
  //         },
  //       })
  //     })
  // },
  created() {
    const userDepartmentArray = this.user.department.map(({ _id }) => _id)
    const purchaserDepartmentArray = this.purchaseRequest.createdBy.department.filter(d => userDepartmentArray.includes(d))
    if (purchaserDepartmentArray.length) {
      this.departmentPermission = true
    }
  },

  methods: {
    setAttachment(e) {
      if (e.target.files.length) {
        // const reader = new FileReader()
        // reader.readAsDataURL(this.file)
        // reader.onload = () => {
        //   e.target.filesrc = reader.result
        // }
        const acceptedTypes = ['image/jpeg', 'image/png', 'application/pdf']
        e.target.files.forEach(element => {
          if (acceptedTypes.includes(element.type)) {
            if (element.size > 20 * 1024 * 1024) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Can't add files more than 20mb",
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
            } else {
              const reader = new FileReader()
              reader.readAsDataURL(element)

              reader.onload = event => {
                const fileObj = {}
                fileObj.name = element.name
                fileObj.data = event.target.result
                fileObj.size = (element.size / (1024 * 1024)).toFixed(2)
                fileObj.type = element.type
                this.attachments.push(fileObj)
              }
            }
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Unsupported file type',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          }
        })
      }
    },
    uploadFile() {
      const formData = new FormData()
      formData.append('attachments', JSON.stringify(this.attachments))
      formData.append('purchaseRequest', this.$route.params.id)
      formData.append('caseID', this.purchaseRequest.caseID)
      this.$http.post('purchase/purchase-request/upload/new-file', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
        .then(response => {
          this.attachments = []
          this.$emit('update:purchaseRequest', response.data.data)
          this.$emit('update:prActivityLogs', response.data.data.activityLog.sort((a, b) => new Date(b.time) - new Date(a.time)))
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.data.message || '',
              icon: 'AlertTriangleIcon',
              variant: 'success',
            },
          })
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.data.message || '',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    closeDescriptionModal() {
      this.$root.$emit('bv::hide::modal', 'modal-attachment-description', '')
    },
    updateDescription(key) {
      this.attachmentKey = key
      this.description = this.purchaseRequest.attachments[key].description
      this.$root.$emit('bv::show::modal', 'modal-attachment-description', '')
    },
    resolveAcronymName(name) {
      return name.split(' ').map(item => item.charAt(0)).join('').toUpperCase()
    },
    removeAttachment(url) {
      this.$http
        .post('purchase/purchase-request/remove-attachment', { url, purchaseRequest: this.$route.params.id })
        .then(response => {
          this.$emit('update:purchaseRequest', response.data.data)
          this.$emit('update:prActivityLogs', response.data.data.activityLog.sort((a, b) => new Date(b.time) - new Date(a.time)))
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.data.message || '',
              icon: 'AlertTriangleIcon',
              variant: 'success',
            },
          })
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.data.message || '',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    saveDescription() {
      const url = this.purchaseRequest.attachments[this.attachmentKey].data
      this.$http
        .post('purchase/purchase-request/save-description', { url, description: this.description, purchaseRequest: this.$route.params.id })
        .then(response => {
          this.$emit('update:purchaseRequest', response.data.data)
          this.closeDescriptionModal()
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.data.message || '',
              icon: 'AlertTriangleIcon',
              variant: 'success',
            },
          })
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.data.message || '',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },

    budgetAllocationWarning() {
      this.$swal({
        title: 'Budget have not been allocated',
        html: 'Budget allocation is mandatory to proceed, please choose a budget to allocate to this PRF.',
        // eslint-disable-next-line global-require
        imageUrl: require('@/assets/images/icons/warning.png'),
        reverseButtons: true,
        showCancelButton: true,
        cancelButtonText: 'Cancel',
        confirmButtonText: 'Allocate Budget',
        customClass: {
          confirmButton: 'btn btn-primary ml-1',
          cancelButton: 'btn btn-outline-primary',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            // eslint-disable-next-line no-nested-ternary
            this.$router.push({ name: 'purchasing-purchase-request-allocate-budget', params: { id: this.$route.params.id }, query: { type: this.purchaseRequest.requestType, department: (this.purchaseRequest.createdBy ? (this.purchaseRequest.createdBy.department.length ? this.purchaseRequest.createdBy.department[0]._id : '') : '') } })
          }
        })
    },

    downloadPRF() {
      const formData = new FormData()
      formData.append('url', `${window.location.origin}/purchase-request/download/prf/${this.$route.params.id}`)
      formData.append('landscape', false)
      this.$http.post('purchase/pdf/download', formData, {
        headers: { Accept: 'application/pdf' },
        responseType: 'arraybuffer',
      })
        .then(response => {
          const blob = new Blob([response.data], { type: 'application/pdf' })
          const link = document.createElement('a')
          link.href = window.URL.createObjectURL(blob)
          link.download = `PRF-${this.purchaseRequest.stringID}.pdf`
          link.click()
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },

    async oldDownloadPRF() {
      await this.calculateTotalPage()
      const vm = this
      const element = document.getElementById('pdfGenerateDiv')
      // element.style.fontFamily = 'Montserrat-Regular'
      element.style.letterSpacing = '0.5px'
      let height = 282.5
      if (this.pdfTotalPage === 1) {
        height = 297
      }
      if (this.pdfTotalPage === 2) {
        height = 284
      }
      // eslint-disable-next-line new-cap
      const pdf = new jsPDF('p', 'mm', [height, 210], true)
      // const pageWidth = pdf.internal.pageSize.width - (10 * 2)
      // pdf.setFont('Montserrat-Regular')
      pdf.setProperties({
        title: `PRF-${vm.purchaseRequest.stringID}`,
      })
      pdf.html(element, {
        margin: [5, 5, 5, 5],
        html2canvas: {
          scale: 0.25,
        },
        // width: pageWidth,
        // windowWidth: pageWidth,
        callback(pdfr) {
          pdfr.save(`PRF-${vm.purchaseRequest.stringID}.pdf`)
        },
        // x: 10,
        // y: 10,
        autoPaging: 'text',
        // fontFaces: [{
        //   family: 'Montserrat-Regular', weight: 400, stretch: 'normal', src: ['assets/fonts/Montserrat-Regular.ttf'],
        // }],
      })
    },
    async calculateTotalPage() {
      await this.$nextTick()
      this.chunksOfItemRequests = []
      this.chunkItemRequests = []
      this.pdfTotalPage = 0
      const logoHeight = 135
      const requesterHeight = document.getElementById('requester-header').offsetHeight || 200
      // footer height will be calculated based on assignee in other pdfs. for pr assignee is limited to 3
      const footerHeight = 210
      let contentHeight = 0
      let totalItems = this.purchaseRequestForm.items.length
      let i = 0
      let sliceStart = 0
      let sliceEnd = 10
      if (totalItems > 10) {
        sliceEnd = 14
      }
      while (totalItems > 0) {
        if (i === 0) {
          contentHeight = 1080 - (logoHeight + requesterHeight + footerHeight)
        } else {
          contentHeight = 1080 - (logoHeight + footerHeight)
        }
        // console.log(contentHeight)
        this.chunkItemRequests = this.purchaseRequestForm.items.slice(sliceStart, sliceEnd)
        // eslint-disable-next-line no-await-in-loop
        await this.$nextTick()
        let tableHeight = document.getElementById('content-div').offsetHeight
        // console.log(tableHeight)
        if (tableHeight > contentHeight) {
          contentHeight += footerHeight
        }
        // // console.log(tableHeight)
        while (tableHeight > contentHeight) {
          sliceEnd -= 1
          this.chunkItemRequests = this.purchaseRequestForm.items.slice(sliceStart, sliceEnd)
          // eslint-disable-next-line no-await-in-loop
          await this.$nextTick()
          tableHeight = document.getElementById('content-div').offsetHeight
        }
        this.chunksOfItemRequests.push(this.chunkItemRequests)
        this.pdfTotalPage += 1
        i += 1
        sliceStart = sliceEnd
        sliceEnd += 17
        totalItems -= this.chunkItemRequests.length
      }

      let remainingHeight = 0
      const finalDataHeight = document.getElementById('content-div').offsetHeight
      this.gapHeight = 0
      const dHeight = 1080
      // if (this.isMobile()) {
      //   dHeight = 590
      // }
      if (this.pdfTotalPage === 1) {
        remainingHeight = dHeight - (logoHeight + requesterHeight + footerHeight + finalDataHeight)
        if (remainingHeight > 0) {
          this.gapHeight = remainingHeight
        }
        if (finalDataHeight >= 650) {
          this.pdfTotalPage += 1
          this.gapHeight = dHeight - (logoHeight + footerHeight)
        }
      } else {
        remainingHeight = dHeight - (logoHeight + footerHeight + finalDataHeight)
        if (remainingHeight > 0) {
          this.gapHeight = remainingHeight
        }
        if (finalDataHeight >= 850) {
          this.pdfTotalPage += 1
          this.gapHeight = dHeight - (logoHeight + footerHeight)
        }
      }
    },
    warningBeforeEdit() {
      this.$swal({
        title: 'Are You Sure?',
        html: 'Editing the form now will <strong>reset the approval process</strong> and require all signatories to confirm and sign once again.',
        // eslint-disable-next-line global-require
        imageUrl: require('@/assets/images/icons/warning.png'),
        reverseButtons: true,
        showCancelButton: true,
        cancelButtonText: 'No, Cancel',
        confirmButtonText: 'Yes, Edit this PRF',
        customClass: {
          confirmButton: 'btn btn-primary ml-1',
          cancelButton: 'btn btn-outline-primary',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            this.$router.push({ name: 'purchasing-purchase-requests-edit', params: { id: this.$route.params.id } })
          }
        })
    },
    submitForApproval() {
      this.$swal({
        title: 'Sign and Send this PRF?',
        html: `You are proceeding to sign this PRF. The PRF will then be sent to ${this.purchaseRequestForm.confirmedBy ? this.purchaseRequestForm.confirmedBy.name : ''} for signing.`,
        // eslint-disable-next-line global-require
        imageUrl: require('@/assets/images/icons/fly.png'),
        reverseButtons: true,
        showCancelButton: true,
        cancelButtonText: 'No, Cancel',
        confirmButtonText: 'Yes, Proceed!',
        customClass: {
          confirmButton: 'btn btn-primary ml-1',
          cancelButton: 'btn btn-outline-primary',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            this.$http.post(`purchase/purchase-request/${this.$route.params.id}/requester/submit`, {
              headers: { 'Content-Type': 'multipart/form-data' },
            })
              .then(response => {
                this.$emit('update:purchaseRequest', response.data.data)
                this.$emit('update:purchaseRequestForm', response.data.prf)
                this.$swal({
                  title: 'Submitted for Approval',
                  html: 'This purchase request will be sent for verifying.<br>All relevant parties will receive a notification.',
                  // eslint-disable-next-line global-require
                  imageUrl: require('@/assets/images/icons/success.png'),
                  imageWidth: 80,
                  imageHeight: 80,
                  imageAlt: 'Custom Icon',
                  showCancelButton: false,
                  confirmButtonText: 'Okay',
                  customClass: {
                    confirmButton: 'btn btn-primary',
                  },
                  buttonsStyling: false,
                })
              })
              .catch(error => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              })
          }
        })
    },
    sendBackToRequester(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.$refs.sendBackToRequesterForm.validate().then(success => {
        if (success) {
          this.$refs['modal-send-back'].toggle('#toggle-btn')
          const formData = new FormData()
          formData.append('url', window.location.origin)
          formData.append('comment', this.comment)
          formData.append('stage', 'in-request')
          formData.append('stringID', this.purchaseRequest.stringID)

          this.$http.post(`purchase/purchase-request/${this.$route.params.id}/send-back`, formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          })
            .then(response => {
              this.$emit('update:purchaseRequest', response.data.data)
              this.$emit('update:purchaseRequestForm', response.data.prf)
              this.$swal({
                title: 'PRF updated to Pending Edit',
                html: `The requester (${this.purchaseRequest.createdBy ? this.purchaseRequest.createdBy.name : ''}) has been informed of your comments. You may verify this PRF after the requester re-submits this PRF.`,
                // eslint-disable-next-line global-require
                imageUrl: require('@/assets/images/icons/success.png'),
                imageWidth: 80,
                imageHeight: 80,
                imageAlt: 'Custom Icon',
                showCancelButton: false,
                confirmButtonText: 'Okay',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            })
            .catch(error => {
              if (error.data.errors) {
                error.data.errors.forEach(validationError => {
                  if (validationError.param === 'comment') {
                    this.commentError = validationError.msg
                    this.commentValidation = true
                  }
                })
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              }
            })
        }
      })
    },

    approvePRF(stage) {
      let message = 'You are proceeding to sign on this PRF.'
      if (stage === 'verify') {
        message = 'You are proceeding to sign on this PRF.<br>The Purchase Request will enter Quotation stage.'
      }
      this.$swal({
        title: `${stage === 'verify' ? 'Verify' : 'Approve'} This PRF?`,
        html: message,
        // eslint-disable-next-line global-require
        imageUrl: require('@/assets/images/icons/fly.png'),
        reverseButtons: true,
        showCancelButton: true,
        cancelButtonText: 'No, go back',
        confirmButtonText: 'Yes, Proceed!',
        customClass: {
          confirmButton: 'btn btn-primary ml-1',
          cancelButton: 'btn btn-outline-primary',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            const formData = new FormData()
            formData.append('stage', stage)
            this.$http.post(`purchase/purchase-request/${this.$route.params.id}/approve-prf`, formData, {
              headers: { 'Content-Type': 'multipart/form-data' },
            })
              .then(response => {
                this.$emit('update:purchaseRequest', response.data.data)
                this.$emit('update:purchaseRequestForm', response.data.prf)
                this.$swal({
                  title: `PRF ${stage === 'verify' ? 'Verified' : 'Approved'}`,
                  html: response.data.message,
                  // eslint-disable-next-line global-require
                  imageUrl: require('@/assets/images/icons/success.png'),
                  imageWidth: 80,
                  imageHeight: 80,
                  imageAlt: 'Custom Icon',
                  showCancelButton: false,
                  confirmButtonText: 'Okay',
                  customClass: {
                    confirmButton: 'btn btn-primary',
                  },
                  buttonsStyling: false,
                })
              })
              .catch(error => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              })
          }
        })
    },
    setData() {
      this.purchaseRequestID = this.purchaseRequest._id
      this.status = this.purchaseRequest.status
      this.stringID = this.purchaseRequest.stringID

      this.$http.get(`directory/users/${this.purchaseRequest.createdBy._id}/show`)
        .then(response => {
          this.user = response.data ?? {}
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },

    submitForm() {
      this.$refs.purchaseRequestStatusEditForm.validate().then(success => {
        if (success) {
          const formData = new FormData()
          formData.append('status', this.status)

          this.$http.patch(`purchase/purchase-request/${this.purchaseRequestID}/status/update`, formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          })
            .then(response => {
              this.purchaseRequest.status = response.data.data.status || 'confirmed'
              this.setData()
              this.$swal({
                title: 'Status Updated!',
                html: `The status for <strong>${this.stringID}</strong> has been updated`,
                // eslint-disable-next-line global-require
                imageUrl: require('@/assets/images/icons/save.png'),
                imageWidth: 80,
                imageHeight: 80,
                imageAlt: 'Custom Icon',
                showCancelButton: false,
                confirmButtonText: 'Okay',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            })
            .catch(error => {
              if (error.data.errors) {
                error.data.errors.forEach(validationError => {
                  if (validationError.param === 'status') {
                    this.statusError = validationError.msg
                    this.statusValidation = true
                  }
                })
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              }
            })
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
