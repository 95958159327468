import axios from '@axios'

export default {
  namespaced: true,
  state: {
    budgetExpense: {},
    expenseItems: [],
  },
  getters: {},
  mutations: {
    set_expense_data(state, { budgetExpense, expenseItems }) {
      state.budgetExpense = budgetExpense
      state.expenseItems = expenseItems
    },
    update_expense(state, { budgetExpense }) {
      state.budgetExpense = budgetExpense
    },
  },
  actions: {
    fetchPurchaseRequests(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('purchase/purchase-request', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deletePurchaseRequest(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`purchase/purchase-request/${id}/delete`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
