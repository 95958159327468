<template>

  <div class="po__details_page">
    <!-- Table Container Card -->
    <b-card
      no-body
    >
      <b-row
        class="content-header"
      >
        <b-col
          class="content-header-left mb-0"
          cols="12"
          md="12"
        >
          <b-row class="breadcrumbs-top">
            <b-col cols="12">
              <h1 class="custom-header-title">
                Delivery Status
              </h1>
              <p class="log_info">
                Last edited by {{ pOrder.updatedBy ? pOrder.updatedBy.name : '' }} on {{ dateFormatWithTime(pOrder.updatedAt) }}
              </p>
            </b-col>
          </b-row>
        </b-col><!-- Content Left -->
      </b-row>
    </b-card>
    <vendor-delivery-information
      ref="deliveryComponent"
      :purchase-request.sync="purchaseRequest"
      :p-order.sync="pOrder"
    />
    <b-card
      no-body
    >
      <div class="pur_con_header">
        <b-row
          class="content-header"
        >
          <!-- Content Left -->
          <b-col
            class="content-header-left mb-2"
            cols="12"
            md="5"
          >
            <div
              v-if="purchaseRequest.status != 'cancelled'"
              class="bulk_selection_with_button bulk_selection md-mb-1 d-flex"
            >
              <div class="action-left pt-50">
                <b-form-checkbox
                  :checked="selectAllPurchaseItemCheckbox"
                  :indeterminate="isSelectedAllPurchaseItemCheckboxIndeterminate"
                  @change="selectAllCheckboxUpdate"
                />
              </div>
              <b-dropdown
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                class="mx-1 d-inline-block"
                right
                text="Bulk Actions"
                variant="primary"
                :disabled="!selectedPurchaseItems.length"
              >
                <b-dropdown-item
                  v-if="canViewThisAction('update', 'PurchaseOrderItem')"
                  @click="setItemsToUpdate()"
                >
                  Update Status
                </b-dropdown-item>
              </b-dropdown>
            </div>
            <b-button
              variant="primary"
              class="mr-1 md-mb-1"
              :disabled="purchaseRequest.status == 'cancelled'"
              @click="$refs.hiddenFileInput.click()"
            >
              Upload File
            </b-button>
            <b-button
              v-if="attachments.length"
              variant="success"
              class="mr-1 md-mb-1"
              @click="uploadFile"
            >
              Save File
            </b-button>
            <input
              ref="hiddenFileInput"
              type="file"
              multiple
              capture
              accept="image/jpeg, image/png, application/pdf"
              class="d-none"
              @change="setAttachment"
            >
          </b-col>
          <b-col
            class="content-header-left text-right mb-2"
            cols="12"
            md="7"
          >
            <b-button
              v-if="(purchaseRequest.status != 'cancelled' && purchaseRequest.status != 'completed') && purchaseRequest.stage == 'in-delivery' && canViewThisAction('mark-complete', 'PurchaseRequest')"
              variant="success"
              :class="{'mr-1': !isMobile(), 'mb-1': isMobile()}"
              :disabled="purchaseRequest.status != 'delivered'"
              @click="markPrAsCompleted"
            >
              <feather-icon
                icon="CheckIcon"
                class="mr-50"
                size="18"
              />
              <span class="align-middle">
                Mark as complete
              </span>
            </b-button>
            <b-button
              v-if="purchaseRequest.status != 'cancelled' && purchaseRequest.stage == 'in-delivery' && canViewThisAction('create', 'DeliveryReceipt')"
              :to="{ name: 'purchasing-delivery-receipts-create', query: { pr: $route.params.id, caseID: purchaseRequest.caseID, po: pOrder._id, vendor: pOrder.vendor } }"
              variant="success"
              :disabled="!pOrder._id"
              :class="{'mr-1': !isMobile(), 'mb-1': isMobile()}"
            >
              <feather-icon
                icon="PlusIcon"
                class="mr-50"
                size="18"
              />
              <span class="align-middle">
                Create New Delivery Receipt
              </span>
            </b-button>
            <b-button
              v-if="purchaseRequest.status != 'cancelled' && purchaseRequest.stage == 'in-delivery' && canViewThisAction('create', 'NonConformanceForm')"
              :to="{ name: 'purchasing-ncf-create', query: { po: $route.params.id } }"
              variant="success"
            >
              <feather-icon
                icon="PlusIcon"
                class="mr-50"
                size="18"
              />
              <span class="align-middle">
                Create NCF
              </span>
            </b-button>
          </b-col>
        </b-row>
      </div>
      <div class="pur_con_body quote__table">
        <b-table-simple
          ref="teamsTable"
          class="position-relative has_padding quote__table"
          responsive
          caption-top
        >
          <b-thead head-variant="dark">
            <b-tr class="group__tag">
              <b-th>
                No
              </b-th>
              <b-th>
                Description
              </b-th>
              <b-th>
                Qty
              </b-th>
              <b-th>
                Unit
              </b-th>
              <b-th>
                U. Price(S$)
              </b-th>
              <b-th>
                Total(S$)
              </b-th>
              <th class="vertical-divider" />
              <b-th>
                GRN
              </b-th>
              <b-th>
                Est. Delivery Date
              </b-th>
              <b-th>
                Status
              </b-th>
              <b-th>
                Remarks
              </b-th>
              <b-th>
                Stock in ID
              </b-th>
              <b-th>
                QTY
              </b-th>
              <th />
            </b-tr>
          </b-thead>
          <b-tbody
            class="white-body-background"
          >
            <b-tr
              v-for="(item, index) in poItems"
              :key="index"
            >
              <b-td>
                <div class="text-nowrap">
                  <b-form-checkbox
                    :checked="selectedPurchaseItems.includes(item._id)"
                    @change="toggleSelectedItems(item._id)"
                    @click.native.stop
                  >
                    {{ index + 1 }}
                  </b-form-checkbox>
                </div>
              </b-td>
              <b-td>
                <span class="text-bold-black">{{ item.itemName }}</span>
                <br>
                <span class="text-pre">{{ item.itemDescription }}</span>
              </b-td>
              <b-td>
                {{ item.quantity }}
              </b-td>
              <td>
                {{ item.unit }}
              </td>
              <td>
                {{ item.unitCost }}
              </td>
              <td>
                {{ numberFormat(item.amount) }}
              </td>
              <td class="vertical-divider" />
              <td>
                <span
                  v-if="item.status == 'delivered' && item.grn"
                >
                  {{ item.grn }}
                </span>
                <span
                  v-if="item.status == 'delivered' && !item.grn"
                >
                  <b-button
                    v-if="canViewThisAction('generate-grn', 'PurchaseOrderItem')"
                    v-ripple.400="'rgba(30, 30, 30, 0.15)'"
                    variant="link"
                    class="px-0"
                    @click="generateGRN(item._id)"
                  >
                    Generate GRN
                  </b-button>
                </span>
                <span
                  v-if="item.status != 'delivered'"
                >
                  <b-button
                    v-if="canViewThisAction('generate-grn', 'PurchaseOrderItem')"
                    v-ripple.400="'rgba(30, 30, 30, 0.15)'"
                    variant="link"
                    class="px-0"
                    disabled
                  >
                    Generate GRN
                  </b-button>
                </span>
              </td>
              <td>
                {{ item.estDeliveryDate ? dateFormatWithTime(item.estDeliveryDate) : '' }}
              </td>
              <td>
                <b-badge
                  :variant="`light-${resolveItemStatusVariant(item.status)}`"
                  class="text-capitalize"
                >
                  {{ resolveItemStatus(item.status) }}
                </b-badge>
                <span v-if="item.status == 'delivered'">
                  <br>
                  on {{ item.deliveryDate ? dateFormatWithTime(item.deliveryDate) : '' }}
                </span>
              </td>
              <td>
                {{ item.remarks }}
              </td>
              <td>
                <div
                  v-if="item.stockInTransactions.length"
                >
                  <!-- <p
                    v-for="(transaction, key) in item.stockInTransactions"
                    :key="key"
                    class="mb-0"
                    style="font-weight: 600; font-size: 14px; color: #104D9D;"
                  >
                    {{ transaction.transactionStringID }}
                  </p> -->
                  <b-button
                    v-for="(transaction, key) in item.stockInTransactions"
                    :key="key"
                    variant="link"
                    style="font-weight: 600; font-size: 14px; color: #104D9D; padding: 0; display: flex; line-height: 1.358rem;"
                    :to="{ name: 'inventory-inventory-history', query: { search: transaction.transactionStringID } }"
                  >
                    {{ transaction.transactionStringID }}
                  </b-button>
                </div>
                <div v-else>
                  -
                </div>
              </td>
              <td>
                <div
                  v-if="item.deliveredQuantity.length"
                >
                  <p
                    v-for="(quantity, key) in item.deliveredQuantity"
                    :key="key"
                    class="mb-0"
                  >
                    {{ quantity }}
                  </p>
                </div>
                <div v-else>
                  -
                </div>
              </td>
              <td>
                <b-dropdown
                  variant="link"
                  no-caret
                  :right="$store.state.appConfig.isRTL"
                >
                  <template #button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="align-middle text-body"
                    />
                  </template>

                  <b-dropdown-item
                    v-if="canViewThisAction('update', 'PurchaseOrderItem')"
                    id="toggle-btn"
                    @click="updateSingleItemStatus(item)"
                  >
                    <feather-icon icon="EditIcon" />
                    <span class="align-middle ml-50">Update Status</span>
                  </b-dropdown-item>
                  <b-dropdown-item
                    v-if="canViewThisAction('update', 'PurchaseOrderItem')"
                    id="toggle-btn"
                    v-b-modal.po-all-modal-update-payment-remarks
                    @click="setItem(item)"
                  >
                    <feather-icon icon="EditIcon" />
                    <span class="align-middle ml-50">Update Remarks</span>
                  </b-dropdown-item>
                </b-dropdown>
              </td>
            </b-tr>
            <tr class="cal_row info-row subtotal_row">
              <td />
              <td colspan="13">
                <div class="d-flex justify-content-between">
                  <div>
                    <span class="semi-bold">Total Item(s)</span> {{ poItems.length }}
                    <span class="semi-bold ml-3">Total Requested Quantity</span> {{ getTotalRequestedQuantity() }}
                  </div>
                  <span class=""><span class="semi-bold">Total Received Quantity</span> {{ getTotalDeliveredQuantity() }}</span>
                </div>
              </td>
            </tr>
            <tr class="cal_row subtotal_row">
              <td />
              <td colspan="3">
                <span class="semi-bold">Subtotal</span>
              </td>
              <td>
                {{ pOrder.subTotal ? numberFormat(pOrder.subTotal) : 0.00 }}
              </td>
              <td colspan="9" />
            </tr>
            <tr class="cal_row">
              <td />
              <td colspan="3">
                <span class="semi-bold">GST {{ pOrder.gstPercentage }}%</span>
              </td>
              <td>
                {{ pOrder.gst == '-' ? '-' : (pOrder.gst ? numberFormat(pOrder.gst) : 0.00) }}
              </td>
              <td colspan="9" />
            </tr>
            <tr class="cal_row">
              <td />
              <td colspan="3">
                <span class="semi-bold">Grand Total</span>
              </td>
              <td>
                <span class="semi-bold">{{ pOrder.grandTotal ? numberFormat(pOrder.grandTotal) : 0.00 }}</span>
              </td>
              <td colspan="9" />
            </tr>
            <tr class="cal_row last__row">
              <td />
              <td colspan="3">
                <span class="semi-bold">Payment Terms</span>
              </td>
              <td>
                <span class="semi-bold">{{ pOrder.paymentTerms ? pOrder.paymentTerms : '-' }}</span>
              </td>
              <td colspan="9" />
            </tr>
          </b-tbody>
        </b-table-simple>

        <hr
          v-if="purchaseRequest.attachments && purchaseRequest.attachments.length"
          class="dividerHR"
        >
        <div
          v-if="purchaseRequest.attachments && purchaseRequest.attachments.length"
          class="attach__block"
        >
          <b-row
            class="content-header"
          >

            <!-- Content Left -->
            <b-col
              class="content-header-left mb-2"
              cols="12"
              md="9"
            >
              <h3>Attachments</h3>
            </b-col>
          </b-row>
          <b-row>
            <!-- Content Left -->
            <b-col
              v-for="(file, key) in purchaseRequest.attachments"
              :key="key"
              class="content-header-left mb-2"
              cols="12"
              md="4"
            >
              <div
                class="attachment-item"
              >
                <b-img
                  v-if="file.type.includes('image')"
                  left
                  height="40"
                  :src="require('@/assets/images/admin/doc_jpg.png')"
                  alt="Left image"
                />
                <b-img
                  v-if="file.type.includes('application')"
                  left
                  height="40"
                  :src="require('@/assets/images/admin/doc_file.png')"
                  alt="Left image"
                />
                <strong>
                  <b-link
                    :href="file ? file.data : ''"
                    target="_blank"
                  >
                    {{ file ? file.name : '' }}
                  </b-link>
                </strong>
                <span>{{ file ? `${file.size}mb` : '' }}</span>
                <span class="remove__doc">
                  <b-button
                    variant="flat-primary"
                    @click="removeAttachment(file.data)"
                  >
                    <feather-icon
                      icon="XIcon"
                      class="mr-50"
                      size="18"
                    />
                  </b-button>
                </span>
              </div>
              <div class="doc__desc">
                <span v-if="file.description">
                  {{ file.description }}
                </span>
                <span v-else>
                  <button @click="updateDescription(key)">Add a description</button>
                </span>
                <span
                  v-if="file.description"
                  class="edit__desc"
                >
                  <b-button
                    variant="flat-primary"
                    @click="updateDescription(key)"
                  >
                    <feather-icon
                      icon="EditIcon"
                      class="mr-50"
                      size="18"
                    />
                  </b-button>
                </span>
              </div>
            </b-col>
          </b-row>
        </div>
      </div>
    </b-card>

    <b-card
      header-tag="header"
      no-body
      class="budget-expense-card mt-2"
    >
      <template #header>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          type="button"
          variant="flat-primary"
          class="primary-button-with-background mr-3 header-icon"
        >
          <feather-icon
            icon="DollarSignIcon"
            class=""
            size="20"
          />
        </b-button>
        <h3 class="align-middle mr-2">
          Budget
        </h3>
        <b-badge
          v-if="purchaseRequest.budgetStatus"
          :variant="`light-${purchaseRequest.budgetStatus == 'allocated' ? 'success' : 'danger'}`"
          class="text-capitalize-first"
        >
          {{ purchaseRequest.budgetStatus }}
        </b-badge>
        <b-button
          v-if="purchaseRequest.status != 'cancelled' && expenseItems.length && (canViewThisAction('update', 'BudgetAllocation') || canViewThisAction('edit-budget-all-stages', 'BudgetAllocation'))"
          variant="flat-primary"
          class="ml-auto"
          :to="{ name: 'purchasing-purchase-request-update-allocated-budget', params: { parent: $route.params.id, id: budgetExpense._id }, query: { type: purchaseRequest.requestType } }"
        >
          <feather-icon
            icon="EditIcon"
            class="mr-50"
            size="16"
          />
          <span class="align-middle">Edit Budget</span>
        </b-button>
      </template>
      <div
        v-if="expenseItems.length"
      >
        <budget-expense />
      </div>
      <div
        v-else
        class="row"
      >
        <b-col>
          <div class="empty_block">
            <h4>Budget not allocated yet.</h4>
            <b-button
              v-if="purchaseRequest.status != 'cancelled' && canViewThisAction('allocate-budget-all-stages', 'BudgetAllocation')"
              type="button"
              variant="flat-primary"
              :disabled="!purchaseRequest.requestType"
              :to="{ name: 'purchasing-purchase-request-allocate-budget', params: { id: $route.params.id }, query: { type: purchaseRequest.requestType, department: (purchaseRequest.createdBy ? (purchaseRequest.createdBy.department.length ? purchaseRequest.createdBy.department[0]._id : '') : '') } }"
            >
              <span class="align-middle">Allocate Budget</span>
            </b-button>
          </div>
        </b-col>
      </div>
    </b-card>

    <b-card
      class="mb-3 mt-3 p-2 booking_calllog calllog-details"
    >
      <b-row
        class="content-header"
      >
        <!-- Content Left -->
        <b-col
          class="content-header-left mb-2"
          cols="12"
          md="9"
        >
          <h4>
            <feather-icon
              icon="ZapOffIcon"
              class="mr-50"
              size="16"
            />
            <span class="align-middle">Related Non-Conformance Forms</span>
          </h4>
        </b-col>
      </b-row>
      <all-ncf />
    </b-card>

    <b-card
      class="mb-3 mt-3 p-2 booking_calllog calllog-details"
    >
      <b-row
        class="content-header"
      >
        <!-- Content Left -->
        <b-col
          class="content-header-left mb-2"
          cols="12"
          md="9"
        >
          <h4>
            <feather-icon
              icon="FileTextIcon"
              class="mr-50"
              size="16"
            />
            <span class="align-middle">Related Delivery Receipts</span>
          </h4>
        </b-col>
      </b-row>
      <all-delivery-receipts />
    </b-card>

    <b-modal
      id="po-all-modal-update-status"
      ref="update-status-modal"
      cancel-variant="flat-primary"
      ok-variant="success"
      ok-title="Update"
      cancel-title="Cancel"
      modal-class="status-update-modal"
      centered
      no-close-on-backdrop
      title="Update Status"
      @ok="submitStatusForm"
    >
      <b-form @submit.prevent="submitStatusForm">
        <validation-observer
          ref="singleItemStatusEditForm"
        >
          <b-form-group
            label="Status*"
            label-for="h-event-status"
          >
            <validation-provider
              #default="{ errors }"
              name="Status"
              vid="status"
              rules="required"
            >
              <v-select
                id="h-event-status"
                v-model="status"
                label="title"
                :options="statusDataOptions"
                :reduce="title => title.code"
                :clearable="false"
              />
              <small class="text-danger">{{ errors[0] }}</small>
              <small
                v-if="statusValidation"
                class="text-danger"
              >
                {{ statusError }}
              </small>
            </validation-provider>
          </b-form-group>
        </validation-observer>
      </b-form>
    </b-modal>

    <b-modal
      id="po-all-modal-bulk-update-status"
      ref="all-update-bulk-status-modal"
      modal-class="status-update-modal"
      cancel-variant="flat-primary"
      ok-variant="success"
      ok-title="Update"
      cancel-title="Cancel"
      centered
      no-close-on-backdrop
      title="Update Status"
      @ok="updateBulkStatus"
    >
      <b-form @submit.prevent="updateBulkStatus">
        <validation-observer
          ref="poBulkStatusEditForm"
        >
          <b-form-group
            label="Status*"
            label-for="h-event-bulk-status"
          >
            <validation-provider
              #default="{ errors }"
              name="Status"
              vid="status"
              rules="required"
            >
              <v-select
                id="h-event-status"
                v-model="bulkStatus"
                label="title"
                :options="statusDataOptions"
                :reduce="title => title.code"
                :clearable="false"
              />
              <small class="text-danger">{{ errors[0] }}</small>
              <small
                v-if="bulkStatusValidation"
                class="text-danger"
              >
                {{ bulkStatusError }}
              </small>
            </validation-provider>
          </b-form-group>
        </validation-observer>
      </b-form>
    </b-modal>

    <b-modal
      id="po-all-modal-update-payment-remarks"
      ref="update-po-remarks-modal"
      cancel-variant="flat-primary"
      ok-variant="success"
      ok-title="Update"
      cancel-title="Cancel"
      modal-class="status-update-modal"
      centered
      no-close-on-backdrop
      title="Update Remarks"
      @ok="submitPORemarksForm"
    >
      <b-form @submit.prevent="submitPORemarksForm">
        <validation-observer
          ref="poRemarksEditForm"
        >
          <b-form-group
            label="Remarks*"
            label-for="h-event-remarks"
          >
            <validation-provider
              #default="{ errors }"
              name="Remarks"
              vid="remarks"
              rules="required"
            >
              <b-form-textarea
                id="event-remarks"
                v-model="remarks"
                placeholder="Remarks"
                rows="3"
                name="remarks"
                @input="remarksValidation == true ? remarksValidation = false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
              <small
                v-if="remarksValidation"
                class="text-danger"
              >
                {{ remarksError }}
              </small>
            </validation-provider>
          </b-form-group>
        </validation-observer>
      </b-form>
    </b-modal>

    <b-modal
      id="modal-attachment-description"
      size="lg"
      title="Attachment Description"
      modal-class="status-update-modal"
      centered
      no-stacking
      header-class="front-modal"
      @ok="saveDescription"
      @cancel="closeDescriptionModal"
    >
      <b-row>
        <b-col md="12">
          <b-form-textarea
            id="h-customer-phone-number"
            v-model="description"
            placeholder="E.g. Please remind to make payment"
          />
        </b-col>
      </b-row>
      <template #modal-footer="{ ok, cancel }">
        <b-button
          variant="flat-primary"
          @click="cancel()"
        >
          Cancel
        </b-button>
        <b-button
          variant="primary"
          :disabled="!description || attachmentKey === ''"
          @click="ok()"
        >
          <span class="align-middle">Save</span>
        </b-button>
      </template>
    </b-modal>

    <b-modal
      id="modal-update-status-with-stock-in"
      ref="modal-update-status-with-stock-in"
      modal-class="status-update-modal"
      size="xl"
      centered
      no-stacking
      no-close-on-backdrop
      @ok="submitStatusStockInForm"
      @cancel="closeStatusStockInItemsModal"
    >
      <div class="d-flex justify-content-between">
        <h4>
          Update Status
        </h4>
        <div class="full-return-checkbox">
          <b-form-checkbox
            :checked="selectAllStockInItemCheckbox"
            :indeterminate="isSelectedAllStockInItemCheckboxIndeterminate"
            @change="selectAllStockInCheckboxUpdate"
          >
            <span class="font_normal">Stock in All</span>
          </b-form-checkbox>
        </div>
      </div>
      <!-- <div class="divTable shadow-table">
        <div class="divTableBody">
          <div class="divTableRow headerRow">
            <div class="divTableCell">
              Item
            </div>
            <div class="divTableCell">
              Requested qty
            </div>
            <div class="divTableCell">
              Received qty
            </div>
            <div class="divTableCell">
              Received qty
            </div>
            <div class="divTableCell">
              Stock In
            </div>
          </div>
          <div
            v-for="(stockInItem, stockIndex) in toUpdateItems"
            :key="stockIndex"
            class="divTableRow"
          >
            <div class="divTableCell">
              <div
                v-if="stockInItem.inventoryItem"
                class="d-flex"
              >
                <b-img
                  class="mb-1 mb-sm-0 user__avatar img-preview-block"
                  :src="stockInItem.inventoryItem.image ? stockInItem.inventoryItem.image : defaultImage"
                />
                <div
                  class="ml-1"
                >
                  <span class="text-bold-black">{{ stockInItem.itemName || '-' }}</span>
                  <br>
                  <span class="text-muted">{{ stockInItem.sku || '-' }}</span>
                </div>
              </div>
              <div
                v-else
                class=""
              >
                <span class="text-bold-black">{{ stockInItem.itemName || '-' }}</span>
              </div>
            </div>
            <div class="divTableCell">
              {{ stockInItem.quantity }}
            </div>
            <div class="divTableCell">
              {{ stockInItem.totalDeliveredQuantity }}
            </div>
            <div class="divTableCell">
              <b-form-group
                label-for="h-event-bulk-status"
                style="min-width: 200px !important;"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Status"
                  vid="status"
                  rules="required"
                >
                  <v-select
                    id="h-event-status"
                    v-model="bulkStatusUpdate[stockIndex]"
                    label="title"
                    :options="statusDataOptions"
                    :reduce="title => title.code"
                    :clearable="false"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="bulkStatusValidation"
                    class="text-danger"
                  >
                    {{ bulkStatusError }}
                  </small>
                </validation-provider>
              </b-form-group>
            </div>
            <div class="divTableCell">
              <div class="text-nowrap">
                <b-form-checkbox
                  :checked="selectedStockInItems.includes(stockInItem._id)"
                  @change="toggleStockInSelectedItems(stockInItem._id)"
                  @click.native.stop
                />
              </div>
            </div>
          </div>
        </div>
      </div> -->
      <b-table
        :items="toUpdateItems"
        :fields="updateStatusTableFields"
        class="shadow-table padding-table min-width-810"
      >
        <template #row-details="row">
          <b-card class="no-shadow">
            <div v-if="!row.item.connectedInventory">
              <h5>Link to which Inventory Item?</h5>
              <p style="font-size: 14px;">
                "{{ row.item.itemName }}" is currently not linked to any inventory item. To perform a Stock In, select its related inventory item.
              </p>
              <div class="d-flex">
                <span class="mr-3 pt-75">{{ row.item.itemName }}</span>
                <feather-icon
                  icon="ArrowRightIcon"
                  class="mr-1 pt-75"
                  size="30"
                />
                <vue-autosuggest
                  style="min-width: 300px;"
                  :suggestions="filteredPurposeOptions"
                  :get-suggestion-value="getItemSuggestionValue"
                  :input-props="{id: `autosuggest__input${row.index}`, class: 'form-control', placeholder: 'Search item'}"
                  @selected="(...args) => setInventoryItem(...args, row.index)"
                  @input="suggestOnInputChangePurpose"
                >
                  <template slot-scope="{suggestion}">
                    <div class="d-flex">
                      <b-img
                        class="item-img-block"
                        :src="suggestion.item.image ? suggestion.item.image : defaultImage"
                      />
                      <div
                        class="search-item-text-block"
                      >
                        <h6>{{ suggestion.item.name }}</h6>
                        <small class="text-muted">{{ suggestion.item.sku }}</small>
                      </div>
                    </div>
                  </template>
                </vue-autosuggest>
              </div>
            </div>
            <div v-else>
              <p style="font-size: 14px;">
                Inventory item to stock in:
              </p>
              <b-row>
                <!-- Content Left -->
                <b-col
                  class="content-header-left mb-2"
                  cols="12"
                  md="6"
                >
                  <div
                    class="attachment-item-white"
                  >
                    <div class="d-flex">
                      <b-img
                        class="item-img-block"
                        :src="getInventoryItemDetail(row.item.connectedInventory, 'image')"
                      />
                      <div
                        class="search-item-text-block"
                      >
                        <h6>
                          {{ getInventoryItemDetail(row.item.connectedInventory, 'name') }}
                        </h6>
                        <small class="text-muted">{{ getInventoryItemDetail(row.item.connectedInventory, 'sku') }}</small>
                      </div>
                      <div class="remove__doc">
                        <feather-icon
                          icon="XIcon"
                          class="mr-50"
                          size="18"
                          @click="removeInventoryItem(row.index)"
                        />
                      </div>
                    </div>
                  </div>
                </b-col>
              </b-row>
            </div>
          </b-card>
        </template>

        <template #cell(item)="data">
          <div
            v-if="data.item.inventoryItem"
            class="d-flex"
          >
            <b-img
              class="mb-1 mb-sm-0 user__avatar img-preview-block"
              :src="data.item.inventoryItem.image ? data.item.inventoryItem.image : defaultImage"
            />
            <div
              class="ml-1"
            >
              <span class="text-bold-black">{{ data.item.itemName || '-' }}</span>
              <br>
              <span class="text-muted">{{ data.item.sku || '-' }}</span>
            </div>
          </div>
          <div
            v-else
            class=""
          >
            <span class="text-bold-black">{{ data.item.itemName || '-' }}</span>
          </div>
        </template>

        <template #cell(status)="data">
          <b-form-group
            label-for="h-event-bulk-status"
          >
            <validation-provider
              #default="{ errors }"
              name="Status"
              vid="status"
              rules="required"
            >
              <v-select
                id="h-event-status"
                v-model="bulkStatusUpdate[data.index]"
                label="title"
                :options="computedStatusDataOptions(data.index)"
                :reduce="title => title.code"
                :clearable="false"
              />
              <small class="text-danger">{{ errors[0] }}</small>
              <small
                v-if="bulkStatusValidation"
                class="text-danger"
              >
                {{ bulkStatusError }}
              </small>
            </validation-provider>
          </b-form-group>
        </template>

        <template #cell(stockIn)="data">
          <div
            v-if="data.item.totalDeliveredQuantity != data.item.quantity"
            class="text-nowrap"
          >
            <b-form-checkbox
              :checked="selectedStockInItems.includes(data.item._id)"
              @change="toggleStockInSelectedItems(data.item._id, data.index)"
              @click.native.stop
            />
          </div>
        </template>
        <!-- <template #cell(show_details)="row">
          <b-form-checkbox
            v-model="row.detailsShowing"
            plain
            class="vs-checkbox-con"
            @change="row.toggleDetails"
          >
            <span class="vs-checkbox">
              <span class="vs-checkbox--check">
                <i class="vs-icon feather icon-check" />
              </span>
            </span>
            <span class="vs-label">{{ row.detailsShowing ? 'Hide' : 'Show' }}</span>
          </b-form-checkbox>
        </template> -->
      </b-table>
      <!-- <b-table-simple
        responsive
        sticky-header
        class="shadow-table min-width-810"
      >
        <b-thead>
          <b-tr>
            <b-th style="height: 0px !important;">
              Item
            </b-th>
            <b-th style="height: 0px !important;">
              Requested qty
            </b-th>
            <b-th style="height: 0px !important;">
              Received qty
            </b-th>
            <b-th
              width="50%"
              style="height: 0px !important;"
            >
              Received qty
            </b-th>
            <b-th style="height: 0px !important;">
              Stock In
            </b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr
            v-for="(stockInItem, stockIndex) in toUpdateItems"
            :key="stockIndex"
          >
            <b-td>
              <div
                v-if="stockInItem.inventoryItem"
                class="d-flex"
              >
                <b-img
                  class="mb-1 mb-sm-0 user__avatar img-preview-block"
                  :src="stockInItem.inventoryItem.image ? stockInItem.inventoryItem.image : defaultImage"
                />
                <div
                  class="ml-1"
                >
                  <span class="text-bold-black">{{ stockInItem.itemName || '-' }}</span>
                  <br>
                  <span class="text-muted">{{ stockInItem.sku || '-' }}</span>
                </div>
              </div>
              <div
                v-else
                class=""
              >
                <span class="text-bold-black">{{ stockInItem.itemName || '-' }}</span>
              </div>
            </b-td>
            <b-td>
              {{ stockInItem.quantity }}
            </b-td>
            <b-td>
              {{ stockInItem.totalDeliveredQuantity }}
            </b-td>
            <b-td width="50%">
              <b-form-group
                label-for="h-event-bulk-status"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Status"
                  vid="status"
                  rules="required"
                >
                  <v-select
                    id="h-event-status"
                    v-model="bulkStatusUpdate[stockIndex]"
                    label="title"
                    :options="statusDataOptions"
                    :reduce="title => title.code"
                    :clearable="false"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="bulkStatusValidation"
                    class="text-danger"
                  >
                    {{ bulkStatusError }}
                  </small>
                </validation-provider>
              </b-form-group>
            </b-td>
            <b-td>
              <div class="text-nowrap">
                <b-form-checkbox
                  :checked="selectedStockInItems.includes(stockInItem._id)"
                  @change="toggleStockInSelectedItems(stockInItem._id)"
                  @click.native.stop
                />
              </div>
            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple> -->
      <template #modal-footer="{ ok, cancel }">
        <b-button
          variant="outline-primary"
          @click="cancel()"
        >
          Cancel
        </b-button>
        <b-button
          variant="primary"
          type="button"
          :disabled="catchStockInError"
          @click="ok()"
        >
          <span class="align-middle">{{ selectedStockInItems.length ? 'Next' : 'Confirm' }}</span>
        </b-button>
      </template>
    </b-modal>

    <b-modal
      id="modal-stock-in-quantity"
      ref="modal-stock-in-quantity"
      modal-class="status-update-modal"
      size="xl"
      centered
      no-stacking
      no-close-on-backdrop
      title="Stock In"
      @ok="submitStockInForm"
      @cancel="closeStockInItemsModal"
    >
      <b-table-simple
        sticky-header
        class="shadow-table vertical-align-top min-width-810"
      >
        <b-thead>
          <b-tr>
            <b-th style="height: 0px !important;">
              Item
            </b-th>
            <b-th style="height: 0px !important;">
              Requested Qty
            </b-th>
            <b-th style="height: 0px !important;">
              Store
            </b-th>
            <b-th style="height: 0px !important;">
              Quantity
            </b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr
            v-for="(stockInItem, stockIndex) in toStockInItems"
            :key="stockIndex"
          >
            <b-td width="40%">
              <div
                class="d-flex"
              >
                <b-img
                  class="mb-1 mb-sm-0 user__avatar img-preview-block"
                  :src="stockInItem.inventoryItem.image ? stockInItem.inventoryItem.image : defaultImage"
                />
                <div
                  class="ml-1"
                >
                  <span class="text-bold-black">{{ stockInItem.inventoryItem.name || '-' }}</span>
                  <br>
                  <span class="text-muted">{{ stockInItem.inventoryItem.sku || '-' }}</span>
                </div>
              </div>
            </b-td>
            <b-td>
              <span class="">{{ stockInItem.quantity || '-' }}</span>
            </b-td>
            <b-td width="35%">
              <b-row
                v-for="(storeData, storeIndex) in stockItems[stockIndex].stores"
                :key="storeIndex"
              >
                <b-col
                  lg="12"
                  md="12"
                  sm="12"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Store"
                    :vid="`store_${stockIndex}_${storeIndex}`"
                    rules="required"
                  >
                    <b-form-group
                      :label-for="`store_${stockIndex}_${storeIndex}`"
                      :class="{ 'is-invalid': errors.length }"
                    >
                      <v-select
                        :id="`store_${stockIndex}_${storeIndex}`"
                        v-model="storeData.store"
                        label="name"
                        placeholder="Select a store"
                        :options="permittedDataOptions"
                        :reduce="name => name._id"
                        :clearable="false"
                        @input="stockStoreValidation(stockIndex, storeIndex, storeData.store)"
                      >
                        <!-- eslint-disable-next-line vue/no-template-shadow -->
                        <template #option="{ name, department }">
                          <span style="font-size: 14px;font-weight: 400;"> {{ name }}</span>
                          <br>
                          <span
                            style="font-weight: 500;font-size: 12px;"
                            class="text-muted"
                          >{{ department.name }}</span>
                        </template>
                      </v-select>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="button"
                variant="none"
                class="text-primary add-more-store-btn"
                :class="{'mb-0':isMobile(), 'mb-2':!isMobile()}"
                @click="addMoreStore(stockIndex)"
              >
                Add store
              </b-button>
            </b-td>
            <b-td>
              <b-row
                v-for="(storeData, storeIndex) in stockItems[stockIndex].stores"
                :key="storeIndex"
              >
                <b-col
                  lg="11"
                  md="11"
                  sm="11"
                >
                  <div class="store-options">
                    <validation-provider
                      #default="{ errors }"
                      name="Quantity"
                      :vid="`quantity_${stockIndex}_${storeIndex}`"
                      rules="required|min_value:1|numeric"
                      style="width: 100%;"
                    >
                      <b-form-group
                        :label-for="`quantity_${stockIndex}_${storeIndex}`"
                        :class="{ 'is-invalid': errors.length }"
                      >
                        <b-form-input
                          :id="`quantity_${stockIndex}_${storeIndex}`"
                          v-model="storeData.quantity"
                          name="quantity"
                          placeholder="0"
                          class="no-padding-form-control"
                          @keypress="validateNumberInput"
                          @paste="validateNumberPaste"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                        <small
                          v-if="storeData.quantityError"
                          class="text-danger"
                        >
                          Exceeded requested qty
                        </small>
                      </b-form-group>
                    </validation-provider>
                  </div>
                </b-col>
                <b-col
                  lg="1"
                  md="1"
                  sm="1"
                  :class="{'p-0':!isMobile()}"
                >
                  <div
                    v-if="stockItems[stockIndex].stores.length > 1"
                  >
                    <b-button
                      variant="flat-primary"
                      class="pl-0 primary-button-with-background no-background"
                      type="button"
                      @click="removeStoreData(stockIndex,storeIndex)"
                    >
                      <feather-icon
                        icon="XIcon"
                        class="mr-25 trash-icon"
                      />
                    </b-button>
                  </div>
                </b-col>
              </b-row>
            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
      <div class="d-flex justify-content-between">
        <p><span class="text-bold-black mr-75">Inventory Item(s)</span>  {{ stockItems.length }}</p>
        <p><span class="text-bold-black">Total Quantity</span>
          <span
            class="ml-75"
            :class="{ 'text-success': !hasQuantityError, 'text-danger': hasQuantityError }"
          >+{{ stockInTotalQuantity }}
          </span>
        </p>
      </div>
      <hr class="mt-0">
      <b-row>
        <b-col md="12">
          <b-form-group
            label=""
            label-for="remarks"
          >
            <validation-provider
              #default="{ errors }"
              name="Remarks"
              vid="remarks"
              rules=""
            >
              <b-form-textarea
                id="remarks"
                v-model="stockInRemarks"
                :state="(errors.length > 0) ? false : null"
                name="remarks"
                rows="3"
                placeholder="Are there any remarks for this Stock In?"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
      <template #modal-footer="{ ok, cancel }">
        <b-button
          variant="outline-primary"
          @click="cancel()"
        >
          Back
        </b-button>
        <b-button
          variant="primary"
          type="button"
          :disabled="catchQuantityError"
          @click="ok()"
        >
          <span class="align-middle">Confirm</span>
        </b-button>
      </template>
    </b-modal>

    <b-modal
      id="modal-stock-in-items"
      ref="stock-in-items-modal"
      modal-class="status-update-modal"
      size="xl"
      centered
      no-stacking
      no-close-on-backdrop
      @ok="submitFinalStockInForm"
      @cancel="closeFinalStockInItemsModal"
    >
      <template #modal-header>
        <div class="stock-items-modal-header">
          <feather-icon
            icon="DownloadIcon"
            style="color: #21BB8B; margin-bottom: 7px;"
            size="60"
          />
          <div class="stock-in-item-modal-heading-block">
            <h4 style="font-weight: 500; font-size: 21px; color: #000000;">
              Proceed with Stock-In?
            </h4>
            <p style="color: #333333;">
              Please review the inventory item(s) below to be stocked-in.
            </p>
          </div>
        </div>
      </template>
      <b-table-simple
        responsive
        sticky-header
        class="shadow-table"
      >
        <b-thead>
          <b-tr>
            <b-th style="height: 0px !important;">
              Item
            </b-th>
            <b-th style="height: 0px !important;">
              Store
            </b-th>
            <b-th style="height: 0px !important;">
              Quantity
            </b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr
            v-for="(stockInItem, stockIndex) in stockInPreviewItems"
            :key="stockIndex"
          >
            <b-td>
              <div class="d-flex">
                <b-img
                  class="mb-1 mb-sm-0 user__avatar img-preview-block"
                  :src="stockInItem.image ? stockInItem.image : defaultImage"
                />
                <div
                  class="ml-1"
                >
                  <span class="text-bold-black">{{ stockInItem.name || '-' }}</span>
                  <br>
                  <span class="text-muted">{{ stockInItem.sku || '-' }}</span>
                </div>
              </div></b-td>
            <b-td>
              {{ resolveStoreName(stockInItem.store) }}
            </b-td>
            <b-td>
              <span
                class="text-success"
              >+{{ stockInItem.quantity }}</span>
            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
      <div class="d-flex justify-content-between">
        <p><span class="text-bold-black mr-75">Inventory Item(s)</span>  {{ stockItems.length }}</p>
        <p><span class="text-bold-black">Total Quantity</span> <span class="text-success ml-75">+{{ stockInTotalQuantity }}</span></p>
      </div>
      <hr class="mt-0">
      <b-row>
        <b-col md="12">
          <b-form-group
            label=""
            label-for="remarks"
          >
            <validation-provider
              #default="{ errors }"
              name="Remarks"
              vid="remarks"
              rules=""
            >
              <b-form-textarea
                id="remarks"
                v-model="stockInRemarks"
                :state="(errors.length > 0) ? false : null"
                name="remarks"
                rows="3"
                placeholder="Are there any remarks for this Stock In?"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
      <template #modal-footer="{ ok, cancel }">
        <b-button
          variant="outline-primary"
          @click="cancel()"
        >
          No, go back
        </b-button>
        <b-button
          variant="primary"
          type="button"
          @click="ok()"
        >
          <span class="align-middle">Yes, proceed</span>
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard, BRow, BButton, BCol, BForm, BFormGroup, BImg, BFormTextarea, BTableSimple, BThead, BTr, BTh, BTd, BTbody, BFormCheckbox,
  BLink, VBTooltip, BDropdown, BDropdownItem, BBadge, BFormInput, BTable,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { required } from '@validations'
import { VueAutosuggest } from 'vue-autosuggest'
import Ripple from 'vue-ripple-directive'
import { jsPDF } from 'jspdf'
import { useUtils as useAclUtils } from '@/libs/acl/custom'
import store from '@/store/index'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import AllDeliveryReceipts from '../delivery-receipts/all-delivery-receipts/AllDeliveryReceipts.vue'
import AllNcf from '../ncf/all-ncf/AllNCF.vue'
import BudgetExpense from '../purchase-request/BudgetExpense.vue'
import VendorDeliveryInformation from '../purchase-order/VendorDeliveryInformation.vue'

const { canViewThisAction } = useAclUtils()

export default {
  components: {
    BCard,
    BRow,
    BButton,
    BCol,
    BForm,
    BFormGroup,
    BImg,
    BFormTextarea,
    BLink,
    BTableSimple,
    BThead,
    BTr,
    BTh,
    BTd,
    BTbody,
    BDropdown,
    BDropdownItem,
    BFormCheckbox,
    BBadge,
    BFormInput,
    BTable,

    vSelect,
    VueAutosuggest,
    ValidationProvider,
    ValidationObserver,
    AllDeliveryReceipts,
    AllNcf,
    BudgetExpense,
    VendorDeliveryInformation,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props: {
    purchaseRequest: {
      type: Object,
      required: true,
    },
    prActivityLogs: {
      type: Array,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: [],
    },
  },
  data() {
    return {
      canViewThisAction,
      pOrder: {},
      vendorOptions: [],
      user: store.state.auth.userData,
      vendorName: '',
      vendor: '',
      vendors: localStorage.getItem('vendors') ?? [],
      tabType: '',
      gst: 'No GST',
      purchaseRequestSignatories: [],
      poItems: [],
      selectedPurchaseItems: [],
      selectedStockInItems: [],
      bulkStatusUpdate: [],
      storeDataOptions: [],
      permittedDataOptions: [],
      purchaseRequestID: '',
      stockInRemarks: '',
      stringID: '',
      statusDataOptions: [
        { title: 'Pending Delivery', code: 'pending' },
        { title: 'Partially Delivered', code: 'partial' },
        { title: 'Delivered', code: 'delivered' },
      ],
      hasQuantityError: false,
      description: '',
      attachmentKey: '',
      vendorType: 1,
      files: [],
      attachments: [],
      toUpdateItems: [],
      toStockInItems: [],
      allStockIn: false,
      required,
      gstOptions: [
        { text: 'No GST', value: 'No GST' },
        { text: 'GST Exclusive', value: 'GST Exclusive' },
        { text: 'GST Inclusive', value: 'GST Inclusive' },
      ],
      updateStatusTableFields: [
        { key: 'item', label: 'Item' },
        { key: 'quantity', label: 'Requested qty' },
        { key: 'totalDeliveredQuantity', label: 'Received qty' },
        { key: 'status', label: 'delivery status' },
        { key: 'stockIn', label: 'Stock In' },
      ],
      filteredPurposeOptions: [],
      inventoryItems: [],
      stockInPreviewItems: [],
      stockItems: [],
      emailName: '',
      emailTo: '',
      emailMessage: '',
      remarks: '',
      remarksError: '',
      remarksValidation: false,
      bulkStatus: 'delivered',
      bulkStatusError: '',
      bulkStatusValidation: false,
      status: 'pending',
      statusError: '',
      statusValidation: false,
      poItemID: '',
      // eslint-disable-next-line global-require
      defaultImage: require('@/assets/images/frontend/logo-nirvana-memorial-garden.86fe2ff5.svg'),
    }
  },
  computed: {
    selectAllPurchaseItemCheckbox() {
      return this.poItems.length && (this.poItems.length === this.selectedPurchaseItems.length)
    },
    isSelectedAllPurchaseItemCheckboxIndeterminate() {
      return Boolean(this.selectedPurchaseItems.length) && this.poItems.length !== this.selectedPurchaseItems.length
    },
    selectAllStockInItemCheckbox() {
      return this.toUpdateItems.length && (this.toUpdateItems.length === this.selectedStockInItems.length)
    },
    isSelectedAllStockInItemCheckboxIndeterminate() {
      return Boolean(this.selectedStockInItems.length) && this.toUpdateItems.length !== this.selectedStockInItems.length
    },
    stockInTotalQuantity() {
      if (this.stockItems.length) {
        let initStockQuantity = 0
        this.stockItems.forEach(item => {
          if (item.stores.length) {
            item.stores.forEach(storeData => {
              initStockQuantity -= -storeData.quantity
            })
          }
        })
        return initStockQuantity
      }
      return 0
    },
    catchStockInError() {
      const items = this.toUpdateItems.filter(o => this.selectedStockInItems.includes(o._id))
      let errorFound = 0
      items.forEach(i => {
        if (!i.connectedInventory) {
          errorFound += 1
        }
      })
      if (errorFound > 0) {
        return true
      }
      return false
    },
    catchQuantityError() {
      let errorFound = 0
      this.toStockInItems.forEach((item, key) => {
        const qty = item.quantity - item.totalDeliveredQuantity
        let count = 0
        this.stockItems[key].stores.forEach(storeData => {
          if (!storeData.store) {
            errorFound += 1
          }

          if (storeData.quantity) {
            count += Number(storeData.quantity)
            if (count > qty) {
              this.hasQuantityError = true
              errorFound += 1
              // eslint-disable-next-line no-param-reassign
              storeData.quantityError = true
            } else {
              this.hasQuantityError = false
              // eslint-disable-next-line no-param-reassign
              storeData.quantityError = false
            }
          } else {
            errorFound += 1
            // eslint-disable-next-line no-param-reassign
            storeData.quantityError = false
          }
        })
      })
      // if (!this.stockItems.filter(o => o.stores.filter(i => i.quantity > 0)).length) {
      //   errorFound += 1
      // }
      if (errorFound > 0) {
        return true
      }
      return false
    },
    expenseItems() {
      return store.state['pr-detail-store'].expenseItems
    },
    budgetExpense() {
      return store.state['pr-detail-store'].budgetExpense
    },
  },
  mounted() {
    this.$http.get(`purchase/purchase-order/purchase-request/${this.$route.params.id}/detail`)
      .then(response => {
        this.vendorOptions = response.data.vendorOptions ?? []
        this.pOrder = response.data.purchaseOrder || {}
        this.poItems = response.data.poItems || []
        this.emailName = this.pOrder.salesPersonName
        this.emailTo = this.pOrder.companyEmail
        this.storeDataOptions = response.data.stores
        this.inventoryItems = response.data.inventoryItems
        // this.setData()

        this.permittedDataOptions = []
        this.storeDataOptions.forEach(iStore => {
          const filteredArray = iStore.roles.filter(o1 => this.user.roles.some(o2 => o1._id === o2._id))
          const foundPermission = filteredArray.filter(o => o.permissions.find(i => i.action === 'stock-in'))
          if (foundPermission.length) {
            this.permittedDataOptions.push(iStore)
          }
        })
      })
      .catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  },
  methods: {
    computedStatusDataOptions(index) {
      if (this.toUpdateItems.length) {
        if (this.toUpdateItems[index].totalDeliveredQuantity === 0) {
          return [
            { title: 'Pending Delivery', code: 'pending' },
            { title: 'Partially Delivered', code: 'partial' },
            { title: 'Delivered', code: 'delivered' },
          ]
        }
        if (this.toUpdateItems[index].totalDeliveredQuantity < this.toUpdateItems[index].quantity) {
          return [
            { title: 'Partially Delivered', code: 'partial' },
            { title: 'Delivered', code: 'delivered' },
          ]
        }
        return [{ title: 'Delivered', code: 'delivered' }]
      }
      return []
    },
    getTotalRequestedQuantity() {
      return this.poItems.reduce((n, { quantity }) => n + quantity, 0)
    },
    getTotalDeliveredQuantity() {
      return this.poItems.reduce((n, { totalDeliveredQuantity }) => n + totalDeliveredQuantity, 0)
    },
    closeStatusStockInItemsModal() {
      this.$root.$emit('bv::hide::modal', 'modal-update-status-with-stock-in', '')
      this.toUpdateItems = []
    },
    closeStockInItemsModal() {
      this.$root.$emit('bv::hide::modal', 'modal-stock-in-quantity', '')
      this.$root.$emit('bv::show::modal', 'modal-update-status-with-stock-in', '')
    },
    closeFinalStockInItemsModal() {
      this.$root.$emit('bv::hide::modal', 'modal-stock-in-items', '')
      this.$root.$emit('bv::show::modal', 'modal-stock-in-quantity', '')
    },
    addMoreStore(index) {
      this.stockItems[index].stores.push({
        store: '',
        quantity: '',
        quantityError: false,
      })
    },
    removeStoreData(index, storeIndex) {
      this.stockItems[index].stores.splice(storeIndex, 1)
    },
    getItemSuggestionValue(suggestion) {
      return suggestion.item.name
    },
    setInventoryItem(suggestionItem, _suggestionIndex, index) {
      if (_suggestionIndex || _suggestionIndex === 0) {
        this.toUpdateItems[index].connectedInventory = suggestionItem.item._id
        this.toUpdateItems = this.toUpdateItems.map(i => i)
      }
    },
    removeInventoryItem(index) {
      this.toUpdateItems[index].connectedInventory = ''
      this.toUpdateItems = this.toUpdateItems.map(i => i)
    },
    getInventoryItemDetail(id, param) {
      const item = this.inventoryItems.find(i => i._id === id)
      if (item) {
        if (param === 'image') return item.image ? item.image : this.defaultImage
        if (param === 'name') return item.name
        if (param === 'sku') return item.sku
      }
      return ''
    },
    suggestOnInputChangePurpose(text) {
      if (text === '' || text === undefined) {
        return
      }
      const filteredData = this.inventoryItems.filter(item => item.name.toLowerCase().indexOf(text.toLowerCase()) > -1)

      this.filteredPurposeOptions = [{
        data: filteredData,
      }]
    },
    submitStatusStockInForm() {
      this.toStockInItems = this.toUpdateItems.filter(o => this.selectedStockInItems.includes(o._id))
      if (this.toStockInItems.length) {
        // const len = this.toStockInItems.length
        // // eslint-disable-next-line no-plusplus
        // for (let i = 0; i < len; i++) {
        //   this.stockItems.push({
        //     stores: [{
        //       store: '',
        //       quantity: '',
        //       quantityError: false,
        //     }],
        //   })
        // }
        this.stockItems = []
        this.toStockInItems.forEach(item => {
          if (!item.inventoryItem) {
            const inventoryItem = this.inventoryItems.find(i => i._id === item.connectedInventory)
            // eslint-disable-next-line no-param-reassign
            item.inventoryItem = inventoryItem
          }
          this.stockItems.push({
            stores: [{
              store: '',
              quantity: '',
              quantityError: false,
            }],
          })
        })
        this.$root.$emit('bv::show::modal', 'modal-stock-in-quantity', '')
      } else {
        const formData = new FormData()
        const toUpdateItemsArray = this.toUpdateItems
        formData.append('toUpdateItems', JSON.stringify(toUpdateItemsArray))
        formData.append('status', JSON.stringify(this.bulkStatusUpdate))
        formData.append('caseID', this.purchaseRequest.caseID)
        formData.append('prStatus', this.purchaseRequest.status)
        formData.append('purchaseRequest', this.$route.params.id)

        this.$http.patch('purchase/delivery/po-item/multiple/status-only/update', formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        })
          .then(response => {
            this.poItems = response.data.poItems
            this.$emit('update:purchaseRequest', response.data.pr)
            this.$emit('update:prActivityLogs', response.data.pr.activityLog.sort((a, b) => new Date(b.time) - new Date(a.time)))
            this.selectedPurchaseItems = []
            this.$swal({
              title: 'Status Updated!',
              html: 'The status for selected items has been updated',
              // eslint-disable-next-line global-require
              imageUrl: require('@/assets/images/icons/save.png'),
              imageWidth: 80,
              imageHeight: 80,
              imageAlt: 'Custom Icon',
              showCancelButton: false,
              confirmButtonText: 'Okay',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
          })
          .catch(error => {
            if (error.data.errors) {
              error.data.errors.forEach(validationError => {
                if (validationError.param === 'bulkStatus') {
                  this.bulkStatusError = validationError.msg
                  this.bulkStatusValidation = true
                }
              })
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
            }
          })
      }
    },
    submitStockInForm() {
      this.stockInPreviewItems = []
      this.toStockInItems.forEach((stockItem, key) => {
        this.stockItems[key].stores.forEach(storeData => {
          this.stockInPreviewItems.push({
            item: stockItem.inventoryItem._id,
            name: stockItem.itemName,
            image: stockItem.inventoryItem.image,
            sku: stockItem.sku,
            store: storeData.store,
            quantity: storeData.quantity,
          })
        })
      })
      this.$root.$emit('bv::show::modal', 'modal-stock-in-items', '')
    },
    submitFinalStockInForm() {
      const formData = new FormData()
      const toUpdateItemsArray = this.toUpdateItems
      formData.append('toUpdateItems', JSON.stringify(toUpdateItemsArray))
      formData.append('status', JSON.stringify(this.bulkStatusUpdate))
      formData.append('caseID', this.purchaseRequest.caseID)
      formData.append('prStatus', this.purchaseRequest.status)
      formData.append('purchaseRequest', this.$route.params.id)
      const itemsArray = []
      const storesArray = []
      this.toStockInItems.forEach((item, key) => {
        itemsArray.push(item.inventoryItem._id)
        this.stockItems[key].stores.forEach(storeData => {
          const exists = storesArray.find(obj => obj.store === storeData.store)
          if (exists) {
            const itemArray = {
              item: item.inventoryItem._id,
              name: item.inventoryItem.name,
              requestedQuantity: +item.quantity,
              deliveredQuantity: +item.totalDeliveredQuantity,
              quantity: +storeData.quantity,
            }
            exists.totalItems += 1
            exists.totalQuantity -= -storeData.quantity
            exists.items.push(itemArray)
          } else {
            const itemArray = {
              item: item.inventoryItem._id,
              name: item.inventoryItem.name,
              requestedQuantity: +item.quantity,
              deliveredQuantity: +item.totalDeliveredQuantity,
              quantity: +storeData.quantity,
            }
            const storeArr = {
              store: storeData.store,
              name: this.resolveStoreName(storeData.store),
              items: [itemArray],
              totalItems: 1,
              totalQuantity: +storeData.quantity,
            }

            storesArray.push(storeArr)
          }
        })
      })
      formData.append('items', JSON.stringify(itemsArray))
      formData.append('stores', JSON.stringify(storesArray))
      formData.append('remarks', this.stockInRemarks)

      this.$http.patch('purchase/delivery/po-item/multiple/status-with-stock-in/update', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
        .then(response => {
          this.poItems = response.data.poItems
          this.$emit('update:purchaseRequest', response.data.pr)
          this.$emit('update:prActivityLogs', response.data.pr.activityLog.sort((a, b) => new Date(b.time) - new Date(a.time)))
          this.selectedPurchaseItems = []
          this.$swal({
            title: 'Status Updated!',
            html: 'The status for selected items has been updated',
            // eslint-disable-next-line global-require
            imageUrl: require('@/assets/images/icons/save.png'),
            imageWidth: 80,
            imageHeight: 80,
            imageAlt: 'Custom Icon',
            showCancelButton: false,
            confirmButtonText: 'Okay',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        })
        .catch(error => {
          if (error.data.errors) {
            error.data.errors.forEach(validationError => {
              if (validationError.param === 'bulkStatus') {
                this.bulkStatusError = validationError.msg
                this.bulkStatusValidation = true
              }
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          }
        })
    },
    setItemsToUpdate() {
      this.selectedStockInItems = []
      this.toUpdateItems = this.poItems.filter(o => this.selectedPurchaseItems.includes(o._id))
      let hasReceived = 0
      this.bulkStatusUpdate = []
      this.toUpdateItems.forEach(item => {
        // eslint-disable-next-line no-param-reassign
        item.connectedInventory = item.inventoryItem ? item.inventoryItem._id : ''
        this.bulkStatusUpdate.push(item.status)
        if (item.totalDeliveredQuantity) {
          hasReceived += 1
        }
      })
      if (hasReceived > 0) {
        this.updateStatusTableFields = [
          { key: 'item', label: 'Item' },
          { key: 'quantity', label: 'Requested qty' },
          { key: 'totalDeliveredQuantity', label: 'Received qty' },
          { key: 'status', label: 'delivery status' },
          { key: 'stockIn', label: 'Stock In' },
        ]
      } else {
        this.updateStatusTableFields = [
          { key: 'item', label: 'Item' },
          { key: 'quantity', label: 'Requested qty' },
          { key: 'status', label: 'delivery status' },
          { key: 'stockIn', label: 'Stock In' },
        ]
      }
      // const len = this.toUpdateItems.length
      // // eslint-disable-next-line no-plusplus
      // for (let i = 0; i < len; i++) {
      //   this.bulkStatusUpdate.push('delivered')
      // }
      this.$root.$emit('bv::show::modal', 'modal-update-status-with-stock-in', '')
    },
    updateSingleItemStatus(item) {
      this.selectedStockInItems = []
      // eslint-disable-next-line no-param-reassign
      item.connectedInventory = item.inventoryItem ? item.inventoryItem._id : ''
      this.toUpdateItems = [item]
      this.bulkStatusUpdate = [item.status]
      if (item.totalDeliveredQuantity) {
        this.updateStatusTableFields = [
          { key: 'item', label: 'Item' },
          { key: 'quantity', label: 'Requested qty' },
          { key: 'totalDeliveredQuantity', label: 'Received qty' },
          { key: 'status', label: 'delivery status' },
          { key: 'stockIn', label: 'Stock In' },
        ]
      } else {
        this.updateStatusTableFields = [
          { key: 'item', label: 'Item' },
          { key: 'quantity', label: 'Requested qty' },
          { key: 'status', label: 'delivery status' },
          { key: 'stockIn', label: 'Stock In' },
        ]
      }
      this.$root.$emit('bv::show::modal', 'modal-update-status-with-stock-in', '')
    },
    stockStoreValidation(stockItemIndex, storeIndex, storeId) {
      const selectedStoreFound = this.stockItems[stockItemIndex].stores.filter(obj => obj.store === storeId)
      if (selectedStoreFound.length > 1) {
        this.stockItems[stockItemIndex].stores[storeIndex].store = ''
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'This store is already selected in this selected item, please select another store.',
            icon: 'AlertTriangleIcon',
            variant: 'warning',
          },
        })
      } else {
        this.stockItems[stockItemIndex].stores[storeIndex].store = storeId
      }
    },
    validateNumberInput(event) {
      if (event.key && !/^\d*[.]?\d*$/.test(event.key)) {
        event.preventDefault()
      }
    },
    validateNumberPaste(event) {
      const text = (event.originalEvent || event).clipboardData.getData('text/plain')
      if (text && !/^\d*[.]?\d*$/.test(text)) {
        event.preventDefault()
      }
    },
    resolveStoreName(storeId) {
      const storeData = this.storeDataOptions.find(s => s._id === storeId)
      if (storeData) {
        return storeData.name
      }
      return ''
    },
    setAttachment(e) {
      if (e.target.files.length) {
        // const reader = new FileReader()
        // reader.readAsDataURL(this.file)
        // reader.onload = () => {
        //   e.target.filesrc = reader.result
        // }
        const acceptedTypes = ['image/jpeg', 'image/png', 'application/pdf']
        e.target.files.forEach(element => {
          if (acceptedTypes.includes(element.type)) {
            if (element.size > 20 * 1024 * 1024) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Can't add files more than 20mb",
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
            } else {
              const reader = new FileReader()
              reader.readAsDataURL(element)

              reader.onload = event => {
                const fileObj = {}
                fileObj.name = element.name
                fileObj.data = event.target.result
                fileObj.size = (element.size / (1024 * 1024)).toFixed(2)
                fileObj.type = element.type
                this.attachments.push(fileObj)
              }
            }
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Unsupported file type',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          }
        })
      }
    },
    uploadFile() {
      const formData = new FormData()
      formData.append('attachments', JSON.stringify(this.attachments))
      formData.append('purchaseRequest', this.$route.params.id)
      formData.append('caseID', this.purchaseRequest.caseID)
      this.$http.post('purchase/purchase-request/upload/new-file', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
        .then(response => {
          this.attachments = []
          this.$emit('update:purchaseRequest', response.data.data)
          this.$emit('update:prActivityLogs', response.data.data.activityLog.sort((a, b) => new Date(b.time) - new Date(a.time)))
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.data.message || '',
              icon: 'AlertTriangleIcon',
              variant: 'success',
            },
          })
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.data.message || '',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    setItem(item) {
      this.poItemID = item._id
      this.status = item.status
      this.remarks = item.remarks
    },
    toggleSelectedItems(itemId) {
      const itemIndex = this.selectedPurchaseItems.indexOf(itemId)

      if (itemIndex === -1) this.selectedPurchaseItems.push(itemId)
      else this.selectedPurchaseItems.splice(itemIndex, 1)
    },
    toggleStockInSelectedItems(itemId, index) {
      const itemIndex = this.selectedStockInItems.indexOf(itemId)

      if (itemIndex === -1) {
        this.selectedStockInItems.push(itemId)
        if (!this.toUpdateItems[index].inventoryItem) {
          this.toUpdateItems[index]._showDetails = true
        }
      } else {
        this.selectedStockInItems.splice(itemIndex, 1)
        this.toUpdateItems[index]._showDetails = false
      }
    },
    submitPORemarksForm(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.$refs.poRemarksEditForm.validate().then(success => {
        if (success) {
          this.$refs['update-po-remarks-modal'].toggle('#toggle-btn')
          const formData = new FormData()
          formData.append('remarks', this.remarks)
          formData.append('caseID', this.purchaseRequest.caseID)
          formData.append('purchaseRequest', this.$route.params.id)

          this.$http.patch(`purchase/delivery/po-item/${this.poItemID}/remarks/update`, formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          })
            .then(response => {
              this.poItems = response.data.poItems
              this.$emit('update:prActivityLogs', response.data.pr.activityLog.sort((a, b) => new Date(b.time) - new Date(a.time)))
              this.$swal({
                title: 'Remarks Updated!',
                html: 'The Remarks has been updated',
                // eslint-disable-next-line global-require
                imageUrl: require('@/assets/images/icons/save.png'),
                imageWidth: 80,
                imageHeight: 80,
                imageAlt: 'Custom Icon',
                showCancelButton: false,
                confirmButtonText: 'Okay',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            })
            .catch(error => {
              if (error.data.errors) {
                error.data.errors.forEach(validationError => {
                  if (validationError.param === 'remarks') {
                    this.remarksError = validationError.msg
                    this.remarksValidation = true
                  }
                })
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              }
            })
        }
      })
    },
    submitStatusForm(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.$refs.singleItemStatusEditForm.validate().then(success => {
        if (success) {
          this.$refs['update-status-modal'].toggle('#toggle-btn')
          const formData = new FormData()
          formData.append('status', this.status)
          formData.append('caseID', this.purchaseRequest.caseID)
          formData.append('prStatus', this.purchaseRequest.status)
          formData.append('purchaseRequest', this.$route.params.id)

          this.$http.patch(`purchase/delivery/po-item/single/${this.poItemID}/update/status`, formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          })
            .then(response => {
              this.poItems = response.data.poItems
              this.$emit('update:purchaseRequest', response.data.pr)
              this.$swal({
                title: 'Status Updated!',
                html: 'The status for this item has been updated',
                // eslint-disable-next-line global-require
                imageUrl: require('@/assets/images/icons/save.png'),
                imageWidth: 80,
                imageHeight: 80,
                imageAlt: 'Custom Icon',
                showCancelButton: false,
                confirmButtonText: 'Okay',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            })
            .catch(error => {
              if (error.data.errors) {
                error.data.errors.forEach(validationError => {
                  if (validationError.param === 'status') {
                    this.statusError = validationError.msg
                    this.statusValidation = true
                  }
                })
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              }
            })
        }
      })
    },
    updateBulkStatus(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.$refs.poBulkStatusEditForm.validate().then(success => {
        if (success) {
          this.$refs['all-update-bulk-status-modal'].toggle('#toggle-btn')
          const formData = new FormData()
          formData.append('status', this.bulkStatus)
          formData.append('caseID', this.purchaseRequest.caseID)
          formData.append('prStatus', this.purchaseRequest.status)
          formData.append('purchaseRequest', this.$route.params.id)
          // eslint-disable-next-line no-plusplus
          for (let i = 0; i < this.selectedPurchaseItems.length; i++) {
            formData.append('items[]', this.selectedPurchaseItems[i])
          }

          this.$http.patch('purchase/delivery/po-item/bulk/status/update', formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          })
            .then(response => {
              this.poItems = response.data.poItems
              this.$emit('update:purchaseRequest', response.data.pr)
              this.$swal({
                title: 'Status Updated!',
                html: 'The status for selected items has been updated',
                // eslint-disable-next-line global-require
                imageUrl: require('@/assets/images/icons/save.png'),
                imageWidth: 80,
                imageHeight: 80,
                imageAlt: 'Custom Icon',
                showCancelButton: false,
                confirmButtonText: 'Okay',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            })
            .catch(error => {
              if (error.data.errors) {
                error.data.errors.forEach(validationError => {
                  if (validationError.param === 'bulkStatus') {
                    this.bulkStatusError = validationError.msg
                    this.bulkStatusValidation = true
                  }
                })
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              }
            })
        }
      })
    },
    generateGRN(id) {
      this.$swal({
        title: 'Generate Goods Received Number?',
        html: 'Are you sure you want to generate GRN for selected item(s)? This cannot be reversed.',
        // eslint-disable-next-line global-require
        imageUrl: require('@/assets/images/icons/warning.png'),
        imageWidth: 80,
        imageHeight: 80,
        imageAlt: 'Custom Icon',
        reverseButtons: true,
        showCancelButton: true,
        cancelButtonText: 'Cancel',
        confirmButtonText: 'Yes, Proceed',
        customClass: {
          confirmButton: 'btn btn-primary ml-1',
          cancelButton: 'btn btn-outline-primary',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            const formData = new FormData()
            formData.append('purchaseRequest', this.$route.params.id)
            formData.append('caseID', this.purchaseRequest.caseID)
            formData.append('prStatus', this.purchaseRequest.status)

            this.$http.patch(`purchase/delivery/po-item/single/${id}/generate/grn`, formData, {
              headers: { 'Content-Type': 'multipart/form-data' },
            })
              .then(response => {
                this.poItems = response.data.poItems
                this.$emit('update:prActivityLogs', response.data.pr.activityLog.sort((a, b) => new Date(b.time) - new Date(a.time)))
                this.$swal({
                  title: 'GRN Generated!',
                  html: 'A Goods Received Number (GRN) has been generated successfully for selected item(s).',
                  // eslint-disable-next-line global-require
                  imageUrl: require('@/assets/images/icons/success.png'),
                  imageWidth: 80,
                  imageHeight: 80,
                  imageAlt: 'Custom Icon',
                  showCancelButton: false,
                  confirmButtonText: 'Okay',
                  customClass: {
                    confirmButton: 'btn btn-primary',
                  },
                  buttonsStyling: false,
                })
              })
              .catch(error => {
                if (error.data.errors) {
                  error.data.errors.forEach(validationError => {
                    if (validationError.param === 'bulkStatus') {
                      this.bulkStatusError = validationError.msg
                      this.bulkStatusValidation = true
                    }
                  })
                } else {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                      icon: 'AlertTriangleIcon',
                      variant: 'danger',
                    },
                  })
                }
              })
          }
        })
    },
    changeVendorType(value) {
      this.vendorType = value
    },
    closeDescriptionModal() {
      this.$root.$emit('bv::hide::modal', 'modal-attachment-description', '')
    },
    resolveAcronymName(name) {
      return name.split(' ').map(item => item.charAt(0)).join('').toUpperCase()
    },
    selectAllCheckboxUpdate(val) {
      this.selectedPurchaseItems = val ? this.poItems.map(item => item._id) : []
    },
    selectAllStockInCheckboxUpdate(val) {
      if (val) {
        this.toUpdateItems.forEach(item => {
          if (!item.inventoryItem) {
            // eslint-disable-next-line no-param-reassign
            item._showDetails = true
          }
        })
      } else {
        // eslint-disable-next-line no-return-assign, no-param-reassign
        this.toUpdateItems.map(item => item._showDetails = false)
      }
      this.selectedStockInItems = val ? this.toUpdateItems.filter(i => i.totalDeliveredQuantity !== i.quantity).map(item => item._id) : []
    },
    getSubTotal(key) {
      const vendor = this.pOrder.comparedVendors[key]
      let total = 0
      if (vendor.code === 'GST Inclusive') {
        this.poItems.forEach(item => {
          total += item.unitCost[key] ? item.quantity * item.unitCost[key] : 0
        })
        total -= this.getGST(key)
      } else {
        this.poItems.forEach(item => {
          total += item.unitCost[key] ? item.quantity * item.unitCost[key] : 0
        })
      }

      const discount = this.pOrder.discountList[key] ? this.pOrder.discountList[key] : 0
      const dCost = this.pOrder.deliveryCost[key] ? this.pOrder.deliveryCost[key] : 0

      total = total - discount + parseFloat(dCost)
      return Number.isNaN(total) ? '0.00' : total.toFixed(2)
    },
    getGST(key) {
      const vendor = this.pOrder.comparedVendors[key]
      if (vendor.code === 'No GST') {
        return '-'
      }

      if (vendor.code === 'GST Inclusive') {
        let gstableAmount = 0
        this.poItems.forEach(item => {
          gstableAmount += (item.gst[key] && item.gst[key] === 'yes') ? item.quantity * (item.unitCost[key] ? item.unitCost[key] : 0) : 0
        })
        // eslint-disable-next-line no-mixed-operators
        const gstAmount = ((gstableAmount / (100 + parseFloat(this.pOrder.gstPercentage))) * parseFloat(this.pOrder.gstPercentage))
        console.log(parseFloat(this.pOrder.gstPercentage))
        return Number.isNaN(gstAmount) ? '0.00' : gstAmount.toFixed(2)
      }

      let total = 0

      this.poItems.forEach(item => {
        total += (item.gst[key] && item.gst[key] === 'yes') ? item.quantity * (item.unitCost[key] ? item.unitCost[key] : 0) : 0
      })

      // eslint-disable-next-line operator-assignment
      total = ((this.pOrder.gstPercentage / 100) * total)

      return Number.isNaN(total) ? '0.00' : total.toFixed(2)
    },
    getGrandTotal(key) {
      return ((this.getGST(key) === '-' ? 0 : parseFloat(this.getGST(key))) + parseFloat(this.getSubTotal(key))).toFixed(2)
    },
    updateDescription(key) {
      this.attachmentKey = key
      this.description = this.purchaseRequest.attachments[key].description
      this.$root.$emit('bv::show::modal', 'modal-attachment-description', '')
    },
    removeAttachment(url) {
      this.$http
        .post('purchase/purchase-request/remove-attachment', { url, purchaseRequest: this.$route.params.id })
        .then(response => {
          this.$emit('update:purchaseRequest', response.data.data)
          this.$emit('update:prActivityLogs', response.data.data.activityLog.sort((a, b) => new Date(b.time) - new Date(a.time)))
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.data.message || '',
              icon: 'AlertTriangleIcon',
              variant: 'success',
            },
          })
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.data.message || '',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    saveDescription() {
      const url = this.purchaseRequest.attachments[this.attachmentKey].data
      this.$http
        .post('purchase/purchase-request/save-description', { url, description: this.description, purchaseRequest: this.$route.params.id })
        .then(response => {
          this.$emit('update:purchaseRequest', response.data.data)
          this.closeDescriptionModal()
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.data.message || '',
              icon: 'AlertTriangleIcon',
              variant: 'success',
            },
          })
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.data.message || '',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    sendEmailToVendor(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.$refs.emialToVendorForm.validate().then(success => {
        if (success) {
          this.$refs['email-to-vendor-modal'].toggle('#toggle-btn')
          const vm = this
          const element = document.getElementById('pdfGenerateDiv')
          element.style.fontFamily = 'Montserrat-Regular'
          element.style.letterSpacing = '0.5px'
          // eslint-disable-next-line new-cap
          const pdf = new jsPDF('p', 'pt', 'a4', true)
          // console.log(pdf.getFontList())
          pdf.setFont('Montserrat-Regular')
          pdf.setProperties({
            title: `${vm.purchaseRequest.caseID}`,
          })
          pdf.html(element, {
            // margin: [40, 60, 40, 60],
            html2canvas: {
              scale: 0.48,
            },
            width: 1200,
            windowWidth: 1200,
            callback(pdfr) {
              // pdfr.save(`${vm.purchaseRequest.caseID}.pdf`)
              const formData = new FormData()

              formData.append('po', vm.pOrder._id)
              formData.append('pr', vm.$route.params.id)
              formData.append('emailTo', vm.emailTo)
              formData.append('emailName', vm.emailName)
              formData.append('emailMessage', vm.emailMessage)
              formData.append('fileName', `${vm.purchaseRequest.caseID}.pdf`)
              formData.append('file', pdfr.output('datauristring'))
              vm.$http.post('purchase/purchase-request/send-email/po', formData, {
                headers: { 'Content-Type': 'multipart/form-data' },
              })
                .then(response => {
                  vm.$swal({
                    title: 'Email Sent',
                    html: response.data.message || '',
                    // eslint-disable-next-line global-require
                    imageUrl: require('@/assets/images/icons/success.png'),
                    imageWidth: 80,
                    imageHeight: 80,
                    imageAlt: 'Custom Icon',
                    showCancelButton: false,
                    cancelButtonText: '',
                    allowOutsideClick: false,
                    confirmButtonText: 'Ok',
                    customClass: {
                      confirmButton: 'btn btn-primary mr-1',
                      cancelButton: 'btn btn-primary',
                    },
                    buttonsStyling: false,
                  })
                })
                .catch(error => {
                  vm.$toast({
                    component: ToastificationContent,
                    props: {
                      title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                      icon: 'AlertTriangleIcon',
                      variant: 'danger',
                    },
                  })
                })
            },
            x: 10,
            y: 10,
            autoPaging: 'text',
            // fontFaces: [{
            //   family: 'Montserrat-Regular', weight: 400, stretch: 'normal', src: ['assets/fonts/Montserrat-Regular.ttf'],
            // }],
          })
        }
      })
    },
    markPrAsCompleted() {
      this.$swal({
        title: 'Complete this PR?',
        html: 'You are proceeding to mark this PR as completed.',
        // eslint-disable-next-line global-require
        imageUrl: require('@/assets/images/icons/fly.png'),
        reverseButtons: true,
        showCancelButton: true,
        cancelButtonText: 'No, Cancel',
        confirmButtonText: 'Yes, Proceed!',
        customClass: {
          confirmButton: 'btn btn-primary ml-1',
          cancelButton: 'btn btn-outline-primary',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            const formData = new FormData()
            formData.append('url', window.location.origin)
            formData.append('caseID', this.purchaseRequest.caseID)
            formData.append('prStatus', this.purchaseRequest.status)

            this.$http.post(`purchase/purchase-request/${this.$route.params.id}/mark/complete`, formData, {
              headers: { 'Content-Type': 'multipart/form-data' },
            })
              .then(response => {
                this.$emit('update:purchaseRequest', response.data.data)
                this.$swal({
                  title: 'Status Updated!',
                  html: `${this.purchaseRequest.caseID} has been marked as completed.`,
                  // eslint-disable-next-line global-require
                  imageUrl: require('@/assets/images/icons/save.png'),
                  imageWidth: 80,
                  imageHeight: 80,
                  imageAlt: 'Custom Icon',
                  showCancelButton: false,
                  confirmButtonText: 'Okay',
                  customClass: {
                    confirmButton: 'btn btn-primary',
                  },
                  buttonsStyling: false,
                })
              })
              .catch(error => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              })
          }
        })
    },
    approvePO(id, index) {
      this.$swal({
        title: 'Sign this PO?',
        html: 'You are proceeding to sign on this PO. The Purchase Request will enter next step.',
        // eslint-disable-next-line global-require
        imageUrl: require('@/assets/images/icons/fly.png'),
        reverseButtons: true,
        showCancelButton: true,
        cancelButtonText: 'No, Cancel',
        confirmButtonText: 'Yes, Proceed!',
        customClass: {
          confirmButton: 'btn btn-primary ml-1',
          cancelButton: 'btn btn-outline-primary',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            const formData = new FormData()
            formData.append('id', id)
            formData.append('index', index)
            formData.append('signatoryRequired', this.pOrder.signatoryRequired)

            this.$http.post(`purchase/purchase-request/${this.$route.params.id}/approve-po/${this.pOrder._id}`, formData, {
              headers: { 'Content-Type': 'multipart/form-data' },
            })
              .then(response => {
                this.$emit('update:purchaseRequest', response.data.data)
                this.pOrder = response.data.po
                this.$swal({
                  title: 'PO Signed',
                  html: response.data.message,
                  // eslint-disable-next-line global-require
                  imageUrl: require('@/assets/images/icons/success.png'),
                  imageWidth: 80,
                  imageHeight: 80,
                  imageAlt: 'Custom Icon',
                  showCancelButton: false,
                  confirmButtonText: 'Okay',
                  customClass: {
                    confirmButton: 'btn btn-primary',
                  },
                  buttonsStyling: false,
                })
              })
              .catch(error => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              })
          }
        })
    },
    submitForApproval() {
      this.$swal({
        title: 'Submit this PO?',
        html: `You are proceeding to sign this PO. The PO will then be sent to ${this.pOrder.signatories[1] ? this.pOrder.signatories[1].user.name : ''} for signing.`,
        // eslint-disable-next-line global-require
        imageUrl: require('@/assets/images/icons/warning.png'),
        reverseButtons: true,
        showCancelButton: true,
        cancelButtonText: 'No, Cancel',
        confirmButtonText: 'Yes, Proceed!',
        customClass: {
          confirmButton: 'btn btn-primary ml-1',
          cancelButton: 'btn btn-outline-primary',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            this.$http.post(`purchase/purchase-request/${this.$route.params.id}/purchase-order/${this.pOrder._id}/requester/submit`, {
              headers: { 'Content-Type': 'multipart/form-data' },
            })
              .then(response => {
                this.$emit('update:purchaseRequest', response.data.data)
                this.pOrder = response.data.po
                this.$swal({
                  title: 'Submitted for Approval',
                  html: 'This po will be sent for approval. All relevant parties will receive a notification.',
                  // eslint-disable-next-line global-require
                  imageUrl: require('@/assets/images/icons/success.png'),
                  imageWidth: 80,
                  imageHeight: 80,
                  imageAlt: 'Custom Icon',
                  showCancelButton: false,
                  confirmButtonText: 'Okay',
                  customClass: {
                    confirmButton: 'btn btn-primary',
                  },
                  buttonsStyling: false,
                })
              })
              .catch(error => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              })
          }
        })
    },
    setData() {
      this.purchaseRequestID = this.purchaseRequest._id
      this.status = this.purchaseRequest.status
      this.stringID = this.purchaseRequest.caseID

      this.$http.get(`directory/users/${this.purchaseRequest.createdBy._id}/show`)
        .then(response => {
          this.user = response.data ?? {}
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    resolveItemStatusVariant(status) {
      if (status === 'delivered') return 'success'
      if (status === 'ncf') return 'danger'
      if (status === 'na') return 'secondary'
      if (status === 'pending') return 'warning'
      if (status === 'partial') return 'warning'
      return 'primary'
    },
    resolveItemStatus(status) {
      if (status === 'delivered') return 'Delivered'
      if (status === 'ncf') return 'Non-Conformance'
      if (status === 'na') return 'N/A'
      if (status === 'pending') return 'Pending Delivery'
      if (status === 'partial') return 'Partially Delivered'
      return 'Pending Delivery'
    },

    emailPOToVendor() {
      console.log('ok')

      // const vendor = this.pOrder.comparedVendors.find(o => o.id._id === this.pOrder.recommendedProvider)
      // if (vendor.id.status !== 'approved') {
      //   this.$swal({
      //     title: 'Vendor Not In Vendor List',
      //     html: 'A PO can only be generated for Approved vendors. Complete the Vendor Approval process for this vendor before generating this PO.',
      //     // eslint-disable-next-line global-require
      //     imageUrl: require('@/assets/images/icons/warning.png'),
      //     reverseButtons: true,
      //     showCancelButton: true,
      //     width: '520px',
      //     cancelButtonText: 'Back to Summary',
      //     confirmButtonText: 'Proceed to Vendor Approval',
      //     customClass: {
      //       confirmButton: 'btn btn-primary ml-1',
      //       cancelButton: 'btn btn-outline-primary',
      //     },
      //     buttonsStyling: false,
      //   })
      //     .then(result => {
      //       if (result.value) {
      //         this.$router.push({ name: 'purchasing-vendors-show', params: { id: this.pOrder.recommendedProvider } })
      //       }
      //     })
      // } else {
      //   this.$router.push({ name: 'purchasing-purchase-order-create', params: { id: this.$route.params.id } })
      // }
    },

    convertToFile(data) {
      const arr = data.split(',')
      const mime = arr[0].match(/:(.*?);/)[1]
      const bstr = atob(arr[1])
      let n = bstr.length
      const u8arr = new Uint8Array(n)

      // eslint-disable-next-line no-plusplus
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
      }

      return new File([u8arr], 'avatar', { type: mime })
    },
  },
}
</script>

<style lang="scss">
.per-page-selector {
  width: 90px;
}
.po__details_page .table tbody .cal_row.info-row.subtotal_row td {
  padding-top: 15px !important;
  padding-bottom: 15px !important;
  background: transparent !important;
  color: #104D9D !important;
}
.po__details_page .table tbody .cal_row.info-row.subtotal_row {
  padding-top: 15px !important;
  padding-bottom: 15px !important;
  background: #D2DCEA !important;
}
.min-width-810 .table {
  min-width: 810px !important;
}

.vertical-align-top .table td {
  vertical-align: top;
}

.shadow-table.vertical-align-top {
  overflow-y: visible !important;
  max-height: 100% !important;
}

.modal .form-control.no-padding-form-control {
    margin: 0px 0 0px;
}
.divTable{
  display: block;
  width: 100%;
  border-collapse: collapse;
  overflow: auto;
  max-width: 100%;
  max-height: 300px;
}
.divTableRow {
  display: table-row;
}
.divTableRow.headerRow {
  background-color: #EEE;
  .divTableCell {
    background: #F5F8FB !important;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    border-bottom: 1px solid #e7edf5;
    padding: 11px 21px;
    text-transform: uppercase;
    vertical-align: top;
    letter-spacing: 0.5px;
    color: #000 !important;
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
  }
}
.divTableHeading {
  background-color: #EEE;
  display: table-header-group;
}
.divTableCell, .divTableHead {
  border-top: 1px solid #D2DCEA;
  display: table-cell;
  padding: 12px 21px !important;
  border-color: #e7edf5;
  color: #333333 !important;
  font-size: 14px;
  line-height: 20px;
  vertical-align: middle;
}
.divTableHeading {
  background-color: #EEE;
  display: table-header-group;
  font-weight: bold;
}
.divTableFoot {
  background-color: #EEE;
  display: table-footer-group;
  font-weight: bold;
}
.divTableBody {
  display: table-row-group;
}
.shadow-table.padding-table {
  // min-height: 210px;
  .b-table-details td:first-child {
    padding-left: 21px !important;
  }
  .v-select {
    min-width: 200px !important;
  }
  .no-shadow {
    background: #F5F8FB;
    box-shadow: none !important;
  }
}
.attachment-item-white {
  background: #FFFFFF;
  padding: 10px;
  .remove__doc {
    color: #104D9D;;
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-left: auto;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
      margin-left: 10%;
      margin-right: 10%;
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
