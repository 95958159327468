import { render, staticRenderFns } from "./PurchaseRequestDetail.vue?vue&type=template&id=aacaf096&scoped=true&"
import script from "./PurchaseRequestDetail.vue?vue&type=script&lang=js&"
export * from "./PurchaseRequestDetail.vue?vue&type=script&lang=js&"
import style0 from "./PurchaseRequestDetail.vue?vue&type=style&index=0&id=aacaf096&prod&lang=scss&scoped=true&"
import style1 from "./PurchaseRequestDetail.vue?vue&type=style&index=1&id=aacaf096&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "aacaf096",
  null
  
)

export default component.exports